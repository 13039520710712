export class CambioClaveModel {

  public username: string;
  public password: string;
  public newpassword: string;
  public newpasswordconfirm: string;


  constructor() {
    this.username = '';
    this.password = '';
    this.newpassword = '';
    this.newpasswordconfirm = '';
  }
}
