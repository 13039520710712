import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crud-permisos',
  templateUrl: './crud-permisos.component.html',
  styleUrls: ['./crud-permisos.component.css']
})
export class CrudPermisosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
