import { OnInit } from '@angular/core';
import { UsuarioRecuperaModel } from '../../models/usuario-recupera.model';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from '../../services/usuarios.service';
import { Router } from '@angular/router';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(toastr, spinner, usuariosService, router) {
        this.toastr = toastr;
        this.spinner = spinner;
        this.usuariosService = usuariosService;
        this.router = router;
        this.reseted = false;
        this.usuarioReset = new UsuarioRecuperaModel();
    }
    Object.defineProperty(ResetPasswordComponent.prototype, "hiddenEmail", {
        get: function () {
            var _a = this.usuarioReset.email.split('@'), email = _a[0], domain = _a[1];
            return "" + email.slice(0, 3) + '*'.repeat(email.slice(3).length) + "@" + domain.slice(0, 1) + domain.slice(1).replace(/[^.]/g, '*');
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.urlParams = new URLSearchParams(this.router.url
            .replace('/reset-clave?', ''));
    };
    ResetPasswordComponent.prototype.onResetSubmit = function () {
        var _this = this;
        this.spinner.show();
        if (!this.usuarioReset.username.includes('-')) {
            var username = this.usuarioReset.username;
            this.usuarioReset.username = username.substr(0, username.length - 1) + "-" + username.substr(username.length - 1).toUpperCase();
        }
        this.usuarioReset.email = this.usuarioReset.email.toLowerCase();
        this.usuariosService.resetPass(this.usuarioReset)
            .subscribe(function () {
            _this.spinner.hide();
            _this.toastr.success('Se ha enviado la recuperación de contraseña');
            _this.reseted = true;
        }, function (error) {
            console.log('err', error);
            console.log('ResetComponent: error al reiniciarContraseña ');
            _this.spinner.hide();
            _this.toastr.error(error.error);
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
