import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from 'angular-web-storage';

import {UserpoolService} from '../../services/userpool.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private  router: Router,
              private spinner: NgxSpinnerService,
              private localStorageService: LocalStorageService,
              private userpoolService: UserpoolService) { }

  ngOnInit() {
    const params = new URLSearchParams(this.router.url.split('#')[1]);
    const access_token = params.get('access_token');
    const id_token = params.get('id_token');
    this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
      .subscribe((result) => {
        console.log(result);
        this.setCookies(id_token, access_token, result);
        this.router.navigate(['/crud-sistemas']);
      }, error1 => {
        console.log(error1);
        this.router.navigate(['/loginOtic']);
      });

  }

  setCookies(idToken, accessToken, infoUser) {
    const keyPrefix = `CognitoIdentityServiceProvider.${infoUser.aud}`;

    const idTokenKey = `${keyPrefix}.${infoUser['cognito:username']}.idToken`;
    const accessTokenKey = `${keyPrefix}.${infoUser['cognito:username']}.accessToken`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;

    localStorage.setItem(idTokenKey, idToken);
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(lastUserKey, infoUser['cognito:username']);
    // this.localStorageService.set('userData', {
    //   nombre: infoUser.name,
    //   email: infoUser.email
    // });
    this.localStorageService.set('idToken', idToken);
    this.localStorageService.set('accessToken', accessToken);
  }
}
