import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UsuariosService} from '../../services/usuarios.service';
import {LocalStorageService} from "angular-web-storage";
import {NgxSpinnerService} from "ngx-spinner";
import {query} from "@angular/animations";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  urlParams: any;

  constructor(private router: Router,
              private usuariosService: UsuariosService,
              private localStorageService: LocalStorageService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.urlParams = new URLSearchParams(this.router.url.replace('/logout?', ''));
    console.log(this.urlParams);
    console.log(this.urlParams.get('callback'));
    console.log(this.urlParams.get('code'));
    this.usuariosService.logoutUsuario(this.urlParams.get('code'));
    this.localStorageService.remove(`${this.urlParams.get('code')}.idToken`);
    this.localStorageService.remove(`${this.urlParams.get('code')}.accessToken`);
    this.localStorageService.remove(`${this.urlParams.get('code')}.userData`);
    this.router.navigate(['/login'], {
      queryParams: {
        redirect_uri: this.urlParams.get('callback'),
        response_type: 'token',
        client_id: this.urlParams.get('code')
      }
    });
    this.spinner.hide();
  }

}
