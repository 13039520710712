<section class="my-auto">
  <div class="container-fluid">
    <!--<div class="row">-->
    <!--<div class="col-xl-12 col-12">-->
    <!--<img src="assets/img/otic-sofofa-2018.png" alt="Logo OTIC Sofofa" class="float-left margin-top-10"-->
    <!--style="height:50px;width:auto;">-->
    <!--</div>-->
    <!--</div>-->

    <div class="row margin-top-50">
      <div id="container" class="col-xl-4 col-md-8 mx-auto col-12 card px-sm-5 px-3">
        <div class="row margin-top-10">
          <div class="col-xl-8 col-12 mx-auto text-center">
            <img src="assets/img/logo-otic-2018.png" style="width: 200px;" class="mt-3" alt="Sistema de Gestión de Capacitación.">
          </div>
        </div>

        <div class="row margin-top-10">
          <div class="col-12 text-center">
            <span class="text-blue-monse-20">Gestor de Capital Humano</span>
          </div>
          <div class="col-12 margin-top-10">
              <p class="text-16-nunito">La plataforma que te permite administrar los procesos de formación dentro de tu empresa.</p>
          </div>
      </div>
      <ng-container *ngIf="!reseted;else success">
        <div class="row">
          <div class="col-12">
            <p class="text-16-nunito-grey">Ingrese sus datos para recuperar su contraseña:</p>
          </div>
        </div>
        <form #formLogin="ngForm" (ngSubmit)="onResetSubmit();" novalidate>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <span class="text-16-nunito">RUT:</span>
                <input mask="0*-A" [dropSpecialCharacters]="false"  type="text" id="rut" name="rut" class="form-control" placeholder="Ej. 1122333-K" #rut="ngModel" [(ngModel)]="usuarioReset.username" required>
                <span class="text-14-nunito">Ingresar sin puntos y con guión.</span>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <span class="text-16-nunito">Correo Electronico:</span>
                <input type="email" id="email" name="email" class="form-control" placeholder="usuario@dominio.cl" #email="ngModel" [(ngModel)]="usuarioReset.email" required>
              </div>
            </div>
          </div>

          <div class="row-fluid margin-top-10 mb-5">
            <div class="col-xl-12 mx-auto col-12 text-center">
              <input type="submit" value="Recuperar" class="btn text-center rounded btn-recup">
            </div>
          </div>

        </form>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<ng-template #success>
  <div class="row mt-4 mb-sm-5 pb-5">
    <div class="col-12">
      <p class="text-16-nunito mb-2">Hemos enviado una clave provisoria al correo:</p>
      <strong class="text-20-nunito">{{hiddenEmail}}</strong>
      <p class="text-16-nunito mt-2">Comunicate con tu ejecutivo comercial asignado si no has podido ingresar.</p>
    </div>
  </div>
</ng-template>
