import { RolesModel } from '../models/roles.model';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RolesService = /** @class */ (function () {
    function RolesService(http) {
        this.http = http;
    }
    RolesService.prototype.crearRol = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.ROLES + "/rol", body, { headers: headers });
    };
    RolesService.prototype.editarRol = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.put(environment.API_DOMAINS.ROLES + "/rol", body, { headers: headers });
    };
    RolesService.prototype.listarRoles = function (appClientId) {
        return this.http.get(environment.API_DOMAINS.ROLES + "/rol/" + appClientId)
            .pipe(map(function (result) {
            console.log(result);
            return result.map(function (actividad) {
                return new RolesModel(actividad);
            });
        }));
    };
    RolesService.prototype.eliminarRol = function (rol) {
        return this.http.delete(environment.API_DOMAINS.ROLES + "/rol/delete/" + rol.idRol);
    };
    RolesService.prototype.obtenerRolesPorUsuario = function (username, appclientid) {
        return this.http.get(environment.API_DOMAINS.ROLES + "/rol/usuario/" + username + "/" + appclientid)
            .pipe(map(function (result) {
            return result.map(function (item) {
                return new RolesModel(item);
            });
        }));
    };
    RolesService.prototype.guardarRolesPorUsuario = function (input) {
        var body = JSON.stringify(input);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.ROLES + "/rol/usuario", body, { headers: headers });
    };
    RolesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesService_Factory() { return new RolesService(i0.ɵɵinject(i1.HttpClient)); }, token: RolesService, providedIn: "root" });
    return RolesService;
}());
export { RolesService };
