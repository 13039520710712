// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    SW: true,
  
    API_DOMAINS: {
      SISTEMAS: 'https://serv.sistemasotic.0s.cl/servicio/sistemas',
      PROVIDERS: 'https://serv.sistemasotic.0s.cl/servicio/providers',
      USERPOOL: 'https://serv.sistemasotic.0s.cl/servicio/userpool',
      USUARIOS: 'https://serv.sistemasotic.0s.cl/servicio/usuarios',
      ROLES: 'https://serv.sistemasotic.0s.cl/servicio/roles',
  
      CLIENTES: 'https://serv.sistemasotic.0s.cl/servicio2/clientes',
      USRPAS: 'https://serv.sistemasotic.0s.cl/servicio2/user/pas'
    },
  
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_FKBbyRPjB',
    CLIENT_ID: '7ttc95dsnjpgtcur8kb3u34e23',
    URL_AUTH: 'https://sistemasotic.0s.cl/auth'
  };
  
  /*
   * In development mode, to ignore zone related error stack frames such as
   * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
   * import the following file, but please comment it out in production mode
   * because it will have performance impact when throw error
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  