<ngx-datatable
  class="bootstrap table table-hover table-striped"
  [rows]="array"
  [groupExpansionDefault]="false" [rowHeight]="'auto'"
  [sortType]="'multi'" [limit]="10" [columnMode]="'force'" [headerHeight]="50" [messages]="messages">
  <ngx-datatable-column name="providerAttribute">
    <ng-template let-column="column" ngx-datatable-header-template>
      <strong>Atributo Provider</strong>
    </ng-template>
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="userPoolAttribute">
    <ng-template let-column="column" ngx-datatable-header-template>
      <strong>Atributo User Pool</strong>
    </ng-template>
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{value}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column>
    <ng-template let-column="column"  ngx-datatable-header-template>
      <a href="javascript:;">
        <img src="assets/img/agregar.png" data-toggle="tooltip" title="Editar"
             data-placement="bottom" class="small" (click)="showModalAgregarAtributo(refModalAtributos)">
      </a>
    </ng-template>
    <ng-template let-value="value" let-row='row' let-rowIndex='rowIndex' ngx-datatable-cell-template>
      <div>
        <a href="javascript:;">
          <img src="assets/img/editar.png" data-toggle="tooltip" title="Editar"
               data-placement="bottom" (click)="showModalEditarAtributo(refModalAtributos, row, rowIndex)">
        </a>
        <a href="javascript:;">
          <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Editar"
               data-placement="bottom" (click)="quitarAtributo(rowIndex)">
          <span class="oi oi-pencil"></span>
        </a>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template #refModalAtributos let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="agregarModalLabela">Agregar Atributo</h5>
      <h5 class="modal-title" id="editarModalLabela" *ngIf="false">Editar Atributo</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" (click)="hideModal()">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">

      <div class="row">
        <div class="form-group col-xl-6 col-12">
          <label>
            Atributo Provider:
          </label>
          <ng-select id="nameProvider" name="nameProvider" [items]="atributosProviders" bindLabel="nombreCurso" bindValue="nombreCurso"  [(ngModel)]="config.provider">
          </ng-select>
        </div>
        <div class="form-group col-xl-6 col-12">
          <label>
            Atributo User Pool:
          </label>
          <ng-select id="nameUserpool" name="nameUserpool" [items]="atributosUserpool" bindLabel="name" bindValue="name"  [(ngModel)]="config.userPool" >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="guardarConfiguracionProvider()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
              name="buttonCancelar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

