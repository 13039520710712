import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";


import {CognitoCallback, CognitoUtilService, LoggedInCallback} from './cognito.service';
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';

import {UsuariosModel} from "../models/usuarios.model";
import {UsuarioEmpresasModel} from "../models/usuario-empresas.model";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {EmpresaModel} from "../models/empresa.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient, public cognitoUtil: CognitoUtilService,
              private router: Router) {
  }

  listarUsuarios(): Observable<UsuariosModel[]> {
    return this.http.get(`${environment.API_DOMAINS.USUARIOS}/usuario`)
      .pipe(
        map((result: any[]) => {
          return result.map((item) => {
            return new UsuariosModel(item);
          })
        })
      );
  }

  crearUsuarios(model: UsuariosModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USUARIOS}/usuario`, body, {headers: headers});
  }

  editarUsuarios(model: UsuariosModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.USUARIOS}/usuario`, body, {headers: headers});
  }

  eliminarSistema(input: UsuariosModel): Observable<any> {
    return this.http.delete(`${environment.API_DOMAINS.USUARIOS}/usuario/${input.rut}-${input.dv}`);
  }

  obtenerUsuario(usename: number): Observable<UsuarioEmpresasModel> {
    return this.http.get<UsuarioEmpresasModel>(`${environment.API_DOMAINS.USUARIOS}/usuario/${usename}`)
      .pipe(
        map((result: any) => {
          const empresas: EmpresaModel[] = [];
          result.empresas.map((item) => {
            empresas.push(new EmpresaModel(item));
          });
          console.log(empresas);
          result.empresas = empresas;

          return new UsuarioEmpresasModel(result);
        })
      )
  }

  obtenerUsuariosSistemas(appClientId: string): Observable<UsuariosModel[]> {
    return this.http.get<UsuariosModel[]>(`${environment.API_DOMAINS.USUARIOS}/usuario/sistema/${appClientId}`)
      .pipe(
        map((result: any[]) => {
          return result.map((item) => {
            return new UsuariosModel(item);
          })
        })
      )
  }

  loginUsuario(userData: any): Observable<any> {
    const body = JSON.stringify(userData);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USUARIOS}/login`, body, {headers: headers});
  }

  resetPass(userData: any): Observable<any> {
    const body = JSON.stringify(userData);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USRPAS}/rest`, body, {headers: headers});
  }

  activarUsuario(userData: any): Observable<any> {
    const body = JSON.stringify(userData);
    console.log(body);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USUARIOS}/activar`, body, {headers: headers});
  }

  activarChangePassUsuario(userData: any): Observable<any> {
    const body = JSON.stringify(userData);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USRPAS}/activarpassrecovery`, body, {headers: headers});
  }

  logoutUsuario(clientId) {
    this.cognitoUtil.agregarAppClient(clientId);
    let cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser !== null)
      cognitoUser.signOut();
  }

  isAuthenticated(callback: LoggedInCallback, clientId) {
    // const thisLocalStorage = this.localStorageService;
    if (callback == null)
      throw('UserLoginService: Callback en isAuthenticated() no puede ser null');

    this.cognitoUtil.agregarAppClient(clientId);

    let cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log('UserLoginService: No se pudo recuperar la sesion: ' + err, err.stack);
          callback.isLoggedIn(err, false);
        }
        else {
          callback.isLoggedIn(err, session.isValid());
          /*cognitoUser.refreshSession(session.getRefreshToken(), function (err, data) {
            if (err) {
              console.log('UserLoginService: Resultado de validacion de sesion es: ' + false);
              callback.isLoggedIn(err, false);
            } else {
              thisLocalStorage.set('idToken', session.getIdToken().getJwtToken());
              console.log('UserLoginService: Resultado de validacion de sesion es: ' + session.isValid());
              callback.isLoggedIn(err, session.isValid());
            }
          });*/
        }
      });
    } else {
      console.log('UserLoginService: no se pudo recuperar el CurrentUser');
      callback.isLoggedIn('No se pudo recuperar el CurrentUser', false);
    }
  }

  logout() {
    console.log('UsuarioService: Cerrando sesion');
    this.cognitoUtil.getCurrentUser().signOut();
    this.router.navigate(['/login'], {
      queryParams: {
        redirect_uri: environment.URL_AUTH,
        response_type: 'token',
        client_id: environment.CLIENT_ID
      }
    });
  }
}
