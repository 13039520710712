<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3 *ngIf="!flagEditar">Crear Empresa</h3>
            <h3 *ngIf="flagEditar">Editar Empresa</h3>
          </div>
          <div class="col-lg-4 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/crud-clientes']">Clientes</a></li>
                <li class="breadcrumb-item active"><a>Empresa</a></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-12 mx-auto padding-5">
            <form #formCrearEmpresa="ngForm" (ngSubmit)="guardaEmpresa(formCrearEmpresa);"
                  novalidate>
              <div class="row">
                <div class="form-group col-xl-4 col-12">
                  <label> RUT Empresa: </label>
                  <input type="text" class="form-control" placeholder="Ingrese" [(ngModel)]="rutEmpresa"
                         #rutVal="ngModel" id="rutVal" name="rutVal" rutvalidator required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || rutVal.touched) && rutVal.invalid }"
                         [disabled]="flagEditar">
                  <span *ngIf="rutVal.invalid && (formCrearEmpresa.submitted || rutVal.touched)" class="obligatorio-texto">
												<span *ngIf="formCrearEmpresa.hasError('required', 'rutVal')">Debe ingresar un RUT.</span>
                                            <span *ngIf="formCrearEmpresa.hasError('rutvalidator', 'rutVal')">El RUT ingresado es incorrecto.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-8 col-12">
                  <label> Raz&oacute;n Social Empresa: </label>
                  <input type="text" class="form-control" placeholder="Ingrese"
                         [(ngModel)]="empresaActual.razonSocial" #razonSocialValEmpresa="ngModel"
                         id="razonSocialValEmpresa" name="razonSocialValEmpresa" autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || razonSocialValEmpresa.touched) && razonSocialValEmpresa.invalid }">
                  <span
                    *ngIf="razonSocialValEmpresa.invalid && (formCrearEmpresa.submitted || razonSocialValEmpresa.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearEmpresa.hasError('required', 'razonSocialValEmpresa')">Debe ingresar Razon Social.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-12 col-12">
                  <label> Nombre de Fantasía: </label>
                  <input type="text" class="form-control" placeholder="Ingrese"
                         [(ngModel)]="empresaActual.nombreFantasia" #nombreFantasiaoValEmpresa="ngModel"
                         id="nombreFantasiaoValEmpresa" name="nombreFantasiaoValEmpresa" autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || nombreFantasiaoValEmpresa.touched) && nombreFantasiaoValEmpresa.invalid }">
                  <span
                    *ngIf="nombreFantasiaoValEmpresa.invalid && (formCrearEmpresa.submitted || nombreFantasiaoValEmpresa.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearEmpresa.hasError('required', 'nombreFantasiaoValEmpresa')">Debe ingresar Nombre de Fantasia.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-8 col-12">
                  <label> Casa Matriz: </label>
                  <input type="text" class="form-control" placeholder="Ingrese"
                         [(ngModel)]="empresaActual.direccion" #casaMatrizValEmpresa="ngModel"
                         id="casaMatrizValEmpresa" name="casaMatrizValEmpresa" autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched) && casaMatrizValEmpresa.invalid }">
                  <span
                    *ngIf="casaMatrizValEmpresa.invalid && (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearEmpresa.hasError('required', 'casaMatrizValEmpresa')">Debe ingresar Casa Matriz.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-4 col-12">
                  <label> Teléfono: </label>
                  <input type="text" class="form-control" placeholder="Ingrese" [(ngModel)]="empresaActual.telefono"
                         #telefonoValEmpresa="ngModel" id="telefonoValEmpresa" name="telefonoValEmpresa" onlyNumber
                         autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || telefonoValEmpresa.touched) && telefonoValEmpresa.invalid }">
                  <span
                    *ngIf="telefonoValEmpresa.invalid && (formCrearEmpresa.submitted || telefonoValEmpresa.touched)"
                    class="obligatorio-texto"> <span
                    *ngIf="formCrearEmpresa.hasError('required', 'telefonoValEmpresa')">Debe ingresar el n&uacute;mero de tel&eacute;fono.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-12 col-12">
                  <label> Giro: </label>
                  <input type="text" class="form-control" placeholder="Ingrese" [(ngModel)]="empresaActual.giro"
                         #giroValEmpresa="ngModel" id="giroValEmpresa" name="giroValEmpresa" autocomplete="off"
                         required
                         [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || giroValEmpresa.touched) && giroValEmpresa.invalid }">
                  <span *ngIf="giroValEmpresa.invalid && (formCrearEmpresa.submitted || giroValEmpresa.touched)"
                        class="obligatorio-texto">
												<span *ngIf="formCrearEmpresa.hasError('required', 'giroValEmpresa')">Debe ingresar nombre del Giro de la empresa.</span>
                                            </span>
                </div>
              </div>
              <div class="row-fluid text-right">
                <a href="javascript:;" class="btn btn-gris float-right" [routerLink]="['/crud-clientes']">Volver a
                  Listado Clientes</a>
                <button type="submit" class="btn btn-primary text-right float-right">Guardar Empresa</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #mdlConfirmarAgregarEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Confirmación</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Desea agregar empresas?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="irCrearEmpresa()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
              name="buttonCancelarEliminar" (click)="hideModalReturn()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #mdlFormEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form #formCrearEmpresa="ngForm" (ngSubmit)="guardaAsociacionEmpresaAlHolding(formCrearEmpresa);"
          autocomplete="off" novalidate>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="!editarEmpresaFlg">Crear Empresa</h5>
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="editarEmpresaFlg">Editar Empresa</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div class="row">
          <div class="form-group col-xl-4 col-12">
            <label> RUT Empresa: </label>
            <input type="text" class="form-control" placeholder="Ingrese rut de la empresa"
                   [(ngModel)]="rutEmpresa" #rutVal="ngModel" id="rutVal" name="rutVal" rutvalidator
                   autocomplete="off" required [readonly]="editarEmpresaFlg"
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || rutVal.touched) && rutVal.invalid }">
            <span *ngIf="rutVal.invalid && (formCrearEmpresa.submitted || rutVal.touched)" class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'rutVal')">Debe ingresar un RUT.</span>
                                            <span *ngIf="formCrearEmpresa.hasError('rutvalidator', 'rutVal')">El RUT ingresado es incorrecto.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-8 col-12">
            <label> Raz&oacute;n Social Empresa: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombe de la raz&oacute;n social"
                   [(ngModel)]="empresaActual.razonSocial" #razonSocialValEmpresa="ngModel"
                   id="razonSocialValEmpresa" name="razonSocialValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || razonSocialValEmpresa.touched) && razonSocialValEmpresa.invalid }">
            <span
              *ngIf="razonSocialValEmpresa.invalid && (formCrearEmpresa.submitted || razonSocialValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'razonSocialValEmpresa')">Debe ingresar Razon Social.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-12 col-12">
            <label> Nombre de Fantasía: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombre de fantasia de la empresa"
                   [(ngModel)]="empresaActual.nombreFantasia" #nombreFantasiaValCrearEmpresa="ngModel"
                   id="nombreFantasiaValCrearEmpresa" name="nombreFantasiaValCrearEmpresa"
                   autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || nombreFantasiaValCrearEmpresa.touched) && nombreFantasiaValCrearEmpresa.invalid }">
            <span
              *ngIf="nombreFantasiaValCrearEmpresa.invalid && (formCrearEmpresa.submitted || nombreFantasiaValCrearEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'nombreFantasiaValCrearEmpresa')">Debe ingresar Nombre de Fantasia.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-8 col-12">
            <label> Casa Matriz: </label>
            <input type="text" class="form-control" placeholder="Ingrese direcci&oacute;n de la casa Matriz"
                   [(ngModel)]="empresaActual.direccion" #casaMatrizValEmpresa="ngModel"
                   id="casaMatrizValEmpresa" name="casaMatrizValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched) && casaMatrizValEmpresa.invalid }">
            <span
              *ngIf="casaMatrizValEmpresa.invalid && (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'casaMatrizValEmpresa')">Debe ingresar Casa Matriz.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-4 col-12">
            <label> Teléfono: </label>
            <input type="text" class="form-control" placeholder="Ingrese n&uacute;mero de tel&eacute;fono"
                   [(ngModel)]="empresaActual.telefono" #telefonoValEmpresa="ngModel" id="telefonoValEmpresa"
                   name="telefonoValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || telefonoValEmpresa.touched) && telefonoValEmpresa.invalid }">
            <span
              *ngIf="telefonoValEmpresa.invalid && (formCrearEmpresa.submitted || telefonoValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'telefonoValEmpresa')">Debe ingresar el n&uacute;mero de tel&eacute;fono.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-12 col-12">
            <label> Giro: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombre del Giro de la empresa"
                   [(ngModel)]="empresaActual.giro" #giroValEmpresa="ngModel" id="giroValEmpresa"
                   name="giroValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || giroValEmpresa.touched) && giroValEmpresa.invalid }">
            <span *ngIf="giroValEmpresa.invalid && (formCrearEmpresa.submitted || giroValEmpresa.touched)"
                  class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'giroValEmpresa')">Debe ingresar nombre del Giro de la empresa.</span>
                                            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Aceptar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
                name="buttonCancelarEliminar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalConfirmDeleteEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Eliminar Empresa</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Está seguro de eliminar?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="eliminarEmpresa()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
              name="buttonCancelarEliminar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

