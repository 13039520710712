import { OnInit, TemplateRef } from '@angular/core';
import { Router } from "@angular/router";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "angular-web-storage";
import { SistemasModel } from "../../models/sistemas.model";
import { SistemasService } from "../../services/sistemas.service";
import { ProvidersService } from "../../services/providers.service";
import { UsuariosService } from '../../services/usuarios.service';
import { UserpoolService } from "../../services/userpool.service";
import { ValidationService } from "../../services/validation.service";
import { environment } from "../../../environments/environment";
var CrudSistemasComponent = /** @class */ (function () {
    function CrudSistemasComponent(modalService, formBuilder, sistemasService, providersService, spinner, toastr, router, usuariosService, userpoolService, localstorageService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.sistemasService = sistemasService;
        this.providersService = providersService;
        this.spinner = spinner;
        this.toastr = toastr;
        this.router = router;
        this.usuariosService = usuariosService;
        this.userpoolService = userpoolService;
        this.localstorageService = localstorageService;
        this.objetoSistemas = new SistemasModel();
        this.objSistemaDelete = new SistemasModel();
        this.messages = {
            emptyMessage: "No hay registros disponibles",
            totalMessage: 'Total'
        };
        this.columns = [];
        this.flagModalSistema = false;
        this.providersDisponibles = [];
        this.submitted = false;
        this.filtersModel = {
            appClienteID: '',
            nombre: ''
        };
    }
    CrudSistemasComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        var _this = this;
        console.log('CrudEncuestaComponent: verificacion sesion');
        if (!isLoggedIn) {
            this.validarPermisos();
        }
        else {
            this.columns = [
                { name: 'ID', prop: 'appClientId' },
                { name: 'Nombre Sistema', prop: 'nombre' },
                { name: 'Alias', prop: 'alias' },
                { name: 'CallbackURL', prop: 'callbackUrl' },
                { name: 'LogoutURL', prop: 'logoutUrl' },
                { name: 'Opciones', cellTemplate: this.optionTemplate }
            ];
            this.listSistemas();
            this.listProviders().subscribe(function (result) {
                result.map(function (item) {
                    _this.providersDisponibles.push({ selected: false, name: item });
                });
                if (!_this.providersDisponibles.includes('COGNITO')) {
                    _this.providersDisponibles.push({ selected: false, name: 'COGNITO' });
                }
                var controls = _this.providersDisponibles.map(function (c) { return new FormControl(false); });
                _this.registerForm = _this.formBuilder.group({
                    nombre: ['', Validators.required],
                    alias: ['', Validators.required],
                    callbackUrl: ['', [Validators.required, ValidationService.httpsValidator]],
                    logoutUrl: ['', [Validators.required, ValidationService.httpsValidator]],
                    providers: new FormArray(controls, ValidationService.checkValidator)
                });
            }, function (error) {
            });
        }
    };
    CrudSistemasComponent.prototype.ngOnInit = function () {
        this.usuariosService.isAuthenticated(this, null);
    };
    Object.defineProperty(CrudSistemasComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CrudSistemasComponent.prototype.validarPermisos = function () {
        var _this = this;
        console.log(this.localstorageService.get('accessToken'));
        var access_token = this.localstorageService.get('accessToken');
        var id_token = this.localstorageService.get('idToken');
        if (access_token !== null && id_token !== null) {
            this.userpoolService.validateToken({ access_token: access_token, id_token: id_token })
                .subscribe(function (result) {
                console.log(result);
            }, function (error1) {
                if (error1.error === 'token denied') {
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
                else if (error1.error === 'Token is expired') {
                    _this.toastr.info('La sessión ha caducado.');
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
            });
        }
        else {
            this.router.navigate(['/login'], {
                queryParams: {
                    redirect_uri: environment.URL_AUTH,
                    response_type: 'token',
                    client_id: environment.CLIENT_ID
                }
            });
        }
    };
    /**
     * Lista todos los sistemas que se encuentran creados.
     */
    CrudSistemasComponent.prototype.listSistemas = function () {
        var _this = this;
        this.spinner.show();
        this.sistemasService.listarSistemas()
            .subscribe(function (result) {
            _this.listadoSistemas = result;
            _this.listadoSistemasTemp = result;
            _this.spinner.hide();
        });
    };
    /**
     * Obtiene los providers disponibles
     */
    CrudSistemasComponent.prototype.listProviders = function () {
        return this.providersService.listarProviders();
        /*  .subscribe((result: any[]) => {
            result.map((item) => {
              this.providersDisponibles.push({selected: false, name: item})
            });
            if (!this.providersDisponibles.includes('COGNITO')) {
              this.providersDisponibles.push({selected: false, name: 'COGNITO'});
            }
          }, error1 => {
            console.log(error1);
          })*/
    };
    /**
     * Muestra el modal para ingresar los datos del nuevo sistema.
     * @param refModal
     */
    CrudSistemasComponent.prototype.showModalCrearSistema = function (refModal) {
        this.submitted = false;
        this.registerForm.reset();
        this.providersDisponibles.map(function (item) {
            item.selected = false;
        });
        this.flagModalSistema = false;
        this.objetoSistemas = new SistemasModel();
        this.modalRef = this.modalService.open(refModal);
    };
    /**
     * Oculta el modal
     */
    CrudSistemasComponent.prototype.hideModal = function () {
        this.modalRef.close();
    };
    /**
     * Guarda un nuevo sistema
     */
    CrudSistemasComponent.prototype.saveSistema = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.objetoSistemas.nombre = this.registerForm.controls.nombre.value;
        this.objetoSistemas.alias = this.registerForm.controls.alias.value;
        this.objetoSistemas.callbackUrl = this.registerForm.controls.callbackUrl.value;
        this.objetoSistemas.logoutUrl = this.registerForm.controls.logoutUrl.value;
        this.spinner.show();
        if (!this.flagModalSistema) {
            this.sistemasService.crearSistema(this.objetoSistemas)
                .subscribe(function (result) {
                _this.spinner.hide();
                _this.modalRef.close();
                _this.listSistemas();
                _this.toastr.success(result);
            }, function (error1) {
                _this.spinner.hide();
            });
        }
        else {
            // tslint:disable-next-line:max-line-length
            this.objetoSistemas.callbackUrl = typeof this.objetoSistemas.callbackUrl === 'string' ? [this.objetoSistemas.callbackUrl] : this.objetoSistemas.callbackUrl;
            // tslint:disable-next-line:max-line-length
            this.objetoSistemas.logoutUrl = typeof this.objetoSistemas.logoutUrl === 'string' ? [this.objetoSistemas.logoutUrl] : this.objetoSistemas.logoutUrl;
            this.sistemasService.editarSistema(this.objetoSistemas)
                .subscribe(function (result) {
                _this.spinner.hide();
                _this.modalRef.close();
                _this.listSistemas();
                _this.toastr.success(result);
            }, function (error1) {
                _this.spinner.hide();
            });
        }
    };
    CrudSistemasComponent.prototype.eliminarSistema = function () {
        var _this = this;
        this.spinner.show();
        this.sistemasService.eliminarSistema(this.objSistemaDelete)
            .subscribe(function () {
            _this.modalRef.close();
            _this.spinner.hide();
            _this.listSistemas();
            _this.toastr.success('Sistema eliminado con éxito.');
        }, function (error) {
            _this.spinner.hide();
        });
    };
    CrudSistemasComponent.prototype.showModalConfirmDeleteSistema = function (refModalConfirmDeleteSistema, row) {
        this.objSistemaDelete = row;
        this.modalRef = this.modalService.open(refModalConfirmDeleteSistema);
    };
    CrudSistemasComponent.prototype.showModalEditSistema = function (refModalEditSistema, row) {
        console.log('row', row);
        this.flagModalSistema = true;
        this.submitted = false;
        this.registerForm.controls.nombre.setValue(row.nombre);
        this.registerForm.controls.alias.setValue(row.alias);
        this.registerForm.controls.callbackUrl.setValue(row.callbackUrl[0]);
        this.registerForm.controls.logoutUrl.setValue(row.logoutUrl[0]);
        this.objetoSistemas = row;
        this.objetoSistemas['nombreAnterior'] = row.nombre;
        this.objetoSistemas['aliasAnterior'] = row.alias;
        this.validarProvoderSeleccionados();
        this.modalRef = this.modalService.open(refModalEditSistema);
    };
    /**
     * Selecciona el providers con los que se desea autenti car
     * @param $event
     */
    CrudSistemasComponent.prototype.selectedProvider = function ($event) {
        if (!this.objetoSistemas.supportedIdentityProviders.includes($event.target.value) && $event.target.checked) {
            this.objetoSistemas.supportedIdentityProviders.push($event.target.value);
        }
        else {
            var indexItem = this.objetoSistemas.supportedIdentityProviders.indexOf($event.target.value);
            this.objetoSistemas.supportedIdentityProviders.splice(indexItem, 1);
        }
    };
    CrudSistemasComponent.prototype.validarProvoderSeleccionados = function () {
        var _this = this;
        this.providersDisponibles.map(function (item, index) {
            _this.registerForm.controls.providers['controls'][index].setValue(_this.objetoSistemas.supportedIdentityProviders.includes(item.name));
            item.selected = _this.objetoSistemas.supportedIdentityProviders.includes(item.name);
        });
    };
    CrudSistemasComponent.prototype.sistemaUsuariosAsociar = function (row) {
        this.router.navigate(["/asociar-usuarios-sistema/" + row.appClientId]);
    };
    CrudSistemasComponent.prototype.filterList = function ($event, name) {
        var _this = this;
        switch (name) {
            case 'ID': {
                if ($event !== undefined) {
                    this.filtersModel.appClienteID = $event.target.value.toString();
                    break;
                }
                else {
                    this.filtersModel.appClienteID = '';
                }
            }
            case 'NOMBRE': {
                if ($event !== undefined) {
                    this.filtersModel.nombre = $event.target.value.toString().toLowerCase();
                    break;
                }
                else {
                    this.filtersModel.nombre = '';
                }
            }
        }
        if (this.filtersModel.nombre !== null ||
            this.filtersModel.appClienteID !== null) {
            var temp = this.listadoSistemasTemp.filter(function (d) {
                return (d.nombre.toLowerCase().indexOf(_this.filtersModel.nombre) !== -1)
                    &&
                        d.appClientId.toLowerCase().indexOf(_this.filtersModel.appClienteID) !== -1;
            });
            this.listadoSistemas = temp;
            if (this.listadoSistemas.length == 0) {
                this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
            }
        }
        else {
            this.listadoSistemas = this.listadoSistemasTemp;
        }
    };
    return CrudSistemasComponent;
}());
export { CrudSistemasComponent };
