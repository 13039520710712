import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SistemasModel } from "../models/sistemas.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SistemasService = /** @class */ (function () {
    function SistemasService(http) {
        this.http = http;
    }
    SistemasService.prototype.obtenerSistema = function (appClientId) {
        return this.http
            .get(environment.API_DOMAINS.SISTEMAS + "/sistema/" + appClientId)
            .pipe(map(function (result) {
            return result;
        }));
    };
    SistemasService.prototype.crearSistema = function (input) {
        var body = JSON.stringify(input);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.SISTEMAS + "/sistema", body, { headers: headers });
    };
    SistemasService.prototype.editarSistema = function (input) {
        var body = JSON.stringify(input);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.put(environment.API_DOMAINS.SISTEMAS + "/sistema", body, { headers: headers });
    };
    SistemasService.prototype.listarSistemas = function () {
        return this.http
            .get(environment.API_DOMAINS.SISTEMAS + "/sistema")
            .pipe(map(function (result) {
            return result.map(function (actividad) {
                return new SistemasModel(actividad);
            });
        }));
    };
    // obtenerBloqueo(): Observable<any[]> {
    //   return this.http.get(`${environment.API_DOMAINS.SISTEMAS}/bloqueo`)
    //     .pipe(
    //       map((result: any[]) => {
    //         return result;
    //       })
    //     );
    // }
    SistemasService.prototype.eliminarSistema = function (input) {
        return this.http.delete(environment.API_DOMAINS.SISTEMAS + "/sistema/" + input.appClientId);
    };
    SistemasService.prototype.obtenerSistemasPorUsuario = function (username) {
        return this.http.get(environment.API_DOMAINS.SISTEMAS + "/sistema/usuario/" + username)
            .pipe(map(function (result) {
            return result.map(function (item) {
                return new SistemasModel(item);
            });
        }));
    };
    SistemasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SistemasService_Factory() { return new SistemasService(i0.ɵɵinject(i1.HttpClient)); }, token: SistemasService, providedIn: "root" });
    return SistemasService;
}());
export { SistemasService };
