import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

import { DomSanitizer } from '@angular/platform-browser';

import {UsuarioLoginModel} from '../../models/usuario-login.model';
import {UsuarioSesionModel} from '../../models/usuario-sesion.model';

import {SistemasService} from '../../services/sistemas.service';
import {UsuariosService} from '../../services/usuarios.service';
import {LocalStorageService} from "angular-web-storage";
import {CognitoUtilService, LoggedInCallback, CognitoCallback} from '../../services/cognito.service';
import { BloqueoModel } from '../../models/bloqueo.model';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, LoggedInCallback {

  usuarioLogin: UsuarioLoginModel;
  imagenBloqueo;
  mensajeBloqueo;
  bloqueo = false;
  parametros: any = {
    supportedIdentity: [],
    redirectUri: null,
    responseType: null,
    scope: ''
  };

  showPass: boolean = false;

  urlParams: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer,
              private sistemasService: SistemasService,
              private usuariosService: UsuariosService,
              private localStorageService: LocalStorageService,
              private swUpdate: SwUpdate) {
    this.usuarioLogin = new UsuarioLoginModel();
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      //this.domainPrefix = environment.DOMAIN_PREFIX;

      if (this.urlParams.has('client_id') && this.urlParams.has('redirect_uri') && this.urlParams.has('response_type')) {

        this.usuarioLogin.client_id = this.urlParams.get('client_id');

        this.parametros.redirectUri = this.urlParams.get('redirect_uri');
        this.parametros.responseType = this.urlParams.get('response_type');

        this.sistemasService.obtenerSistema(this.usuarioLogin.client_id).subscribe(
          next => {
            this.usuarioLogin.auth_flow = this.parametros.responseType == 'token' ? 'implicit' : 'code';

            if (((this.parametros.responseType == 'token' && this.usuarioLogin.auth_flow.includes('implicit'))
                /*|| (this.parametros.responseType == 'code' && this.usuarioLogin.auth_flow.includes('code'))*/)
              && next.callbackUrl && next.callbackUrl.includes(this.parametros.redirectUri)) {

              if (next.supportedIdentityProviders && next.supportedIdentityProviders.length > 0) {
                this.parametros.supportedIdentity = next.supportedIdentityProviders;

              }

              if (next.allowedOAuthScopes && next.allowedOAuthScopes.length > 0) {
                this.parametros.scope = next.allowedOAuthScopes.join(' ');
              }

              // this.spinner.hide();
            } else {
              console.log('La URL de callback no esta configurada para el cliente o no la entrega no coincide con las entregadas.');
              this.redirectError('Parámetros entregados no son válidos.');
            }
            this.spinner.hide();
          }, error => {
            this.spinner.hide();
            this.redirectError(error);
          });
      } else {
        this.spinner.hide();
        this.redirectError('Parámetros entregados no son válidos.');
      }
    } else {
      this.spinner.hide();
      window.location.href = `${this.urlParams.get('redirect_uri')}#access_token=${this.localStorageService.get(`${this.urlParams.get('client_id')}.idToken`)}&id_token=${this.localStorageService.get(`${this.urlParams.get('client_id')}.accessToken`)}&rt=${this.localStorageService.get(`${this.urlParams.get('client_id')}.refreshToken`)}`;
    }

  }

  ngOnInit() {
    this.spinner.show();
    this.urlParams = new URLSearchParams(this.router.url
      .replace('/login?', '')
      .replace('/oauth2/authorize?', ''));
    if (this.urlParams.has('client_id') && this.urlParams.has('redirect_uri') && this.urlParams.has('response_type')) {
      this.usuariosService.isAuthenticated(this, this.urlParams.get('client_id'));
      if (this.swUpdate.isEnabled) {
        this.swUpdate.available.subscribe(() => window.location.reload());
      }
    } else {
      this.spinner.hide();
      this.redirectError('Parámetros entregados no son válidos.');
    }
  }

  /**
   * Redirecciona a la pagina de error cuando corresponde.
   *
   * @param msj
   */
  redirectError(msj: string): void {
    this.spinner.hide();

    if (this.parametros.redirectUri) {
      // window.location.href = `${this.parametros.redirectUri}#error=${msj}&client_id=${this.usuarioLogin.client_id}`;
    } else {
      this.router.navigate(['/error']);
      this.toastr.error(msj, '');
    }
  }

  /**
   * Funcion obtiene el link respectivo para login con un provider.
   *
   * @param provider
   */
  obtenerLinkProvider(provider: string): string {
    return `https://usuarios-catena.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=${provider}`
      + `&redirect_uri=${this.parametros.redirectUri}&response_type=${this.parametros.responseType}&client_id=${this.usuarioLogin.client_id}`
      + `&scope=${this.parametros.scope}`;
  }

  async onLoginSubmit() {
    this.spinner.show();
    const hoy = new Date();
    if (!this.usuarioLogin.username.includes('-')) {
      const username = this.usuarioLogin.username;
      this.usuarioLogin.username = `${username.substr(0, username.length - 1)}-${username.substr(username.length - 1)}`;
    }
    await this.usuariosService.loginUsuario(this.usuarioLogin)
      .subscribe((usuario: UsuarioSesionModel) => {
        console.log('usuario: ', usuario);
        console.log('userData: ', usuario.userData);


        // if ( usuario.bloqueo !== null) {
        //   if ( usuario.bloqueo.length > 0 ) {
        //     this.spinner.hide();
        //     this.mensajeBloqueo = usuario.bloqueo[0].mensaje;
        //     this.imagenBloqueo = this.sanitizer.bypassSecurityTrustResourceUrl(usuario.bloqueo[0].img);
        //     this.bloqueo = true;
        //   } else {
        //     this.spinner.hide();
        //     this.toastr.error('Error bloqueo!');
        //   }
        // } else {
        //   this.spinner.hide();
        //   const user_data = JSON.stringify(usuario.userData);
        //   this.setCookies(usuario.idToken, usuario.accessToken, this.usuarioLogin.username, this.usuarioLogin.client_id, usuario.userData, usuario.refreshToken);
        //   window.location.href = `${this.parametros.redirectUri}#access_token=${usuario.accessToken}&id_token=${usuario.idToken}&ud=${btoa(user_data)}&rt=${usuario.refreshToken}`;
        // }
        this.spinner.hide();
        const user_data = JSON.stringify(usuario.userData);
        this.setCookies(usuario.idToken, usuario.accessToken, this.usuarioLogin.username, this.usuarioLogin.client_id, usuario.userData, usuario.refreshToken);
        window.location.href = `${this.parametros.redirectUri}#access_token=${usuario.accessToken}&id_token=${usuario.idToken}&ud=${btoa(user_data)}&rt=${usuario.refreshToken}`;
      
      },
      error => {
        this.spinner.hide();
        console.log('LoginComponent: error al iniciar sesion ');
        console.log(error);
        if (error.error === 'PreAuthentication failed with error does not have permissions.') {
          this.toastr.error('No tiene permisos para ingresar a este sistema.');
        } else if (error.status === 409) {
          this.router.navigate(['/activar-password-reset']);
        } else {
          this.toastr.error('RUT o contraseña incorrecta');
        }

      });
  }

  setCookies(idToken, accessToken, username, appClientId, infoUser, refreshToken) {
    const keyPrefix = `CognitoIdentityServiceProvider.${appClientId}`;
    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${username}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.${username}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.${username}.clockDrift`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;
    const userDataKey = `${appClientId}.userData`;
    this.localStorageService.set(`${appClientId}.idToken`, idToken);
    this.localStorageService.set(`${appClientId}.accessToken`, accessToken);
    this.localStorageService.set(userDataKey, {
        rut: infoUser.rut + '-' + infoUser.dv,
        nombre: infoUser.nombre,
        empresas: infoUser.empresas,
        rutEmpresa: null,
        roles: infoUser.roles,
        email: infoUser.email,
        rutHolding: infoUser.rutHolding,
        now: +new Date,
        realUser: null
      }
      , 0, 's');
    // this.localStorageService.setItem(refreshTokenKey, this.signInUserSession.getRefreshToken().getToken());
    // this.localStorageService.set(clockDriftKey, `${this.signInUserSession.getClockDrift()}`);
    localStorage.setItem(idTokenKey, idToken);
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(refreshTokenKey, refreshToken);
    localStorage.setItem(lastUserKey, username);
  }

  sendReset() {
    this.router.navigate(['/reset-clave'], {
      queryParams: {
        redirect_uri: this.urlParams.get('redirect_uri'),
        response_type: 'token',
        client_id: this.urlParams.get('client_id')
      }
    });
  }

}
