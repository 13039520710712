import { environment } from '../../environments/environment';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as i0 from "@angular/core";
var CognitoUtilService = /** @class */ (function () {
    function CognitoUtilService() {
    }
    CognitoUtilService.prototype.agregarAppClient = function (appClient) {
        CognitoUtilService.CLIENT_ID = appClient || environment.CLIENT_ID;
    };
    CognitoUtilService.prototype.getUserPool = function () {
        /*if (environment.IDENTITY_POOL_ID) {
            CognitoUtilService.POOL_DATA.endpoint = environment.IDENTITY_POOL_ID;
        }*/
        return new CognitoUserPool({
            UserPoolId: CognitoUtilService.USER_POOL_ID,
            ClientId: CognitoUtilService.CLIENT_ID
        });
    };
    CognitoUtilService.prototype.getCurrentUser = function () {
        return this.getUserPool().getCurrentUser();
    };
    CognitoUtilService.prototype.getAccessToken = function (callback) {
        if (callback == null) {
            throw ("CognitoUtil: callback in getAccessToken is null...returning");
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    };
    CognitoUtilService.prototype.getIdToken = function (callback) {
        if (callback == null) {
            throw ('CognitoUtil: callback en getIdToken es null...retornando');
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log('CognitoUtil: No se pudo obtener las credenciales:' + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    }
                    else {
                        console.log('CognitoUtil: Se obtuvo el token sin embargo la sesion es inválida');
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    };
    CognitoUtilService.REGION = environment.REGION;
    // public static IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
    CognitoUtilService.USER_POOL_ID = environment.USER_POOL_ID;
    CognitoUtilService.POOL_DATA = {
        UserPoolId: CognitoUtilService.USER_POOL_ID,
        ClientId: CognitoUtilService.CLIENT_ID
    };
    CognitoUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CognitoUtilService_Factory() { return new CognitoUtilService(); }, token: CognitoUtilService, providedIn: "root" });
    return CognitoUtilService;
}());
export { CognitoUtilService };
