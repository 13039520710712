<section>
    <div class="container-fluid">
        <!--<div class="row">-->
        <!--<div class="col-xl-12 col-12">-->
        <!--<img src="assets/img/otic-sofofa-2018.png" alt="Logo OTIC Sofofa" class="float-left margin-top-10"-->
        <!--style="height:50px;width:auto;">-->
        <!--</div>-->
        <!--</div>-->

        <div class="row margin-top-50">
            <div id="container" class="col-xl-4 col-md-8 mx-auto col-12 card px-sm-5 px-3">

                <div class="row margin-top-10">
                    <div class="col-12 col-xl-8 mx-auto text-center">
                        <img src="assets/img/logo-otic-2018.png" style="width: 200px;" class="mt-3" alt="Sistema de Gestión de Capacitación.">
                    </div>
                </div>
                <div class="row margin-top-10">
                    <div class="col-12 text-center">
                        <span class="text-blue-monse-20">Gestor de Capital Humano</span>
                    </div>
                    <div class="col-12 margin-top-10">
                        <p class="text-16-nunito">
                            La plataforma que te permite administrar los procesos de formación dentro de tu empresa.
                        </p>
                    </div>
                </div>

                <div class="row pt-5 pt-sm-0">
                    <div class="col-12 col-xl-8">
                        <p class="text-16-nunito-grey">Ingrese sus datos para Iniciar Sesión:</p>
                    </div>
                </div>
                <form #formLogin="ngForm" (ngSubmit)="onLoginSubmit();" *ngIf="parametros.supportedIdentity.includes('COGNITO')" novalidate>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <span class="text-16-nunito">RUT:</span>
                                <input mask="0*-A" [dropSpecialCharacters]="false" type="text" id="rut" name="rut" class="form-control" placeholder="Ej. 1122333-K" #rut="ngModel" [(ngModel)]="usuarioLogin.username" required>
                                <span class="text-14-nunito">Ingresar sin puntos y con guión.</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <span class="text-16-nunito">Contraseña:</span>
                                <div class="input-group">
                                    <input type="{{ showPass ? 'text' : 'password' }}" id="password" name="password" class="form-control  border-top border-left border-bottom" placeholder="********" #password="ngModel" [(ngModel)]="usuarioLogin.password" required>
                                    <span class="input-group-text" (click)="showPass = !showPass">
                                        <img src="assets/img/ojo.svg" alt="" *ngIf="!showPass">
                                        <img src="assets/img/ojo-no.svg" alt="" *ngIf="showPass">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row-fluid margin-top-10">
                        <div class="col-xl-12 mx-auto col-12 text-center">
                            <!-- <input type="submit" value="Iniciar Sesión" class="btn text-center rounded" [disabled]="!formLogin.form.valid"> -->
                            <input type="submit" value="Iniciar Sesión" class="btn text-center rounded btn-ini-ses">
                        </div>
                    </div>

                    <div class="row-fluid margin-top-30 margin-bottom-20">
                        <div class="col-xl-12 mx-auto col-12 text-center">
                            <a [routerLink]="" queryParamsHandling="preserve" (click)="sendReset()" class=" text-center">
                                <span class="text-olvid-pass">¿Olvid&oacute; su contrase&ntilde;a?</span>
                            </a>
                        </div>
                    </div>
                </form>
                <div class="form-row margin-top-20 text-center padding-5" *ngIf="parametros.supportedIdentity.includes('COGNITO') && parametros.supportedIdentity.length > 1">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-4 border-1 margin-bottom-20">
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-4 margin-top--10 margin-bottom-20">
                        <span class="text-16-nunito">O también puede</span>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-4 border-1 margin-bottom-20">
                    </div>
                </div>

                <div class="form-row margin-top-20 text-center padding-" *ngIf="parametros.supportedIdentity.includes('Facebook')">
                    <div class="col-xl-12 col-12 mx-auto text-center5 margin-bottom-20">
                        <a href="{{obtenerLinkProvider('Facebook')}}">
                            <img src="assets/img/facebook.png" style="height:40px; width:auto;" class="text-center mx-auto img-fluid">
                        </a>
                    </div>
                </div>

                <div class="form-row margin-top-20 text-center padding-" *ngIf="parametros.supportedIdentity.includes('Google')">
                    <div class="col-xl-12 col-12 mx-auto text-center5 margin-bottom-20">
                        <a href="{{obtenerLinkProvider('Google')}}">
                            <img src="assets/img/google.png" style="height:40px; width:auto;" class="text-center mx-auto img-fluid">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>