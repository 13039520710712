import {NgModule} from "@angular/core";

@NgModule({})

export class CognitoExceptionsModule {

  getCognitoError(errorException) {
    switch (errorException) {
      case 'UsernameExistsException':
        return 'El usuario que intenta registrar ya existe.';
      case "InvalidPasswordException":
        return "La contraseña ingresada es incorrecta.";
      case "UserNotFoundException":
        return "El usuario ingresado no ha sido encontrado.";
      case "UserNotConfirmedException":
        return "El usuario con el que intenta acceder, no ha sido confirmado. Revise la casilla del email con el que se registró y siga las instrucciones para activar su cuenta.";
      case "CodeMismatchException":
        return "El código de verificación del usuario es incorrecto. Revise la casilla del email con el que se registró y siga las instrucciones para activar su cuenta.";
      case "NotAuthorizedException":
        return "La contraseña ingresada es incorrecta.";
      case "LimitExceededException":
        return "Ha superado en numero máximo de intentos para realizar esta acción. Espere un momento e intente nuevamente.";
      case "NetworkingError":
        return "Se produjo un error de conexión.";
      case "InvalidParameterException":
        return "Parámetros incorrectos. Si está ingresando alguna contraseña, ésta debe tener mínimo 8 caracteres de largo además de incluir números y letras en mayúsculas y minúsculas.";
      case "ExpiredCodeException":
        return "Su código de activación ha caducado, hemos enviado uno nuevo a su correo electrónico";
      case "PasswordResetRequiredException":
        return "Debe modificar su contraseña";
      case "NewPasswordRequiredException":
        return "Debe modificar su contraseña para activar su registro";
      default:
        return "Se produjo un error de inesperado.";
    }
  }
}






