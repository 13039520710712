import {Injectable} from '@angular/core';
import {ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() {
  }

  static httpsValidator(control) {
    if(control.value !== null) {
      if (control.value.match('^(https)://.*$')) {
        return null;
      } else {
        return {'invalidHttps': true};
      }
    } else {
      return null
    }

  }

  static checkValidator(control) {
    console.log(control);
    const total = control.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);
    return total > 0 ? null : {required: true};
  }
}
