// Angular
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

// Util
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";

// Models
import {UsuariosModel} from "../../models/usuarios.model";

//Service
import {UsuariosService} from "../../services/usuarios.service";
import {UserpoolService} from "../../services/userpool.service";
import {LocalStorageService} from "angular-web-storage";
import {LoggedInCallback} from '../../services/cognito.service';

//Modules
import {CognitoExceptionsModule} from "../../shared/cognitoExceptions.module";

import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-crud-usuarios',
  templateUrl: './crud-usuarios.component.html',
  styleUrls: ['./crud-usuarios.component.css']
})
export class CrudUsuariosComponent implements OnInit, LoggedInCallback {
  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<any>;
  @ViewChild('rut', { static: false }) public templateRut: TemplateRef<any>;

  modalRef: NgbModalRef;
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  listaUsuarios: UsuariosModel[] = [];
  listaUsuariosTemp: UsuariosModel[] = [];
  usuariosModel: UsuariosModel = new UsuariosModel();
  usuariosModelEliminar: UsuariosModel = new UsuariosModel();
  columns = [];

  submitted = false;
  registerForm: FormGroup;
  flagModalUsuario = false;

  filtersModel: any = {
    username: '',
    nombre: '',
    correo: ''
  };


  constructor(private modalService: NgbModal,
              private usuariosService: UsuariosService,
              private toastr: ToastrService,
              private cognitoExceptions: CognitoExceptionsModule,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private router: Router,
              private userpoolService: UserpoolService,
              private localstorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    isLoggedIn = true;
    if (!isLoggedIn) {
      this.validarPermisos();
    } else {
      this.columns = [
        {name: 'Rut', cellTemplate: this.templateRut},
        {name: 'Nombre', prop: 'nombre'},
        {name: 'Correo', prop: 'email'},
        {name: 'Opciones', cellTemplate: this.optionTemplate}
      ];
      this.listarUsuarios();
      this.registerForm = this.formBuilder.group({
        rut: ['', [Validators.required,]],
        nombre: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]]
      });
    }
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  listarUsuarios() {
    this.spinner.show();
    this.usuariosService.listarUsuarios()
      .subscribe((result: UsuariosModel[]) => {
        this.listaUsuarios = result;
        this.listaUsuariosTemp = result;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.log(error);
      })

  }


  hideModal() {
    this.modalRef.close();
  }

  /**
   * Muestra el modal para ingresar los datos del nuevo usuario.
   * @param refModal
   */
  showModalCrearUsuario(refModal) {
    this.submitted = false;
    this.flagModalUsuario = false;
    this.registerForm.reset();
    this.usuariosModel = new UsuariosModel();
    this.registerForm.controls.password.enable();
    //this.registerForm.controls.username.enable();
    this.modalRef = this.modalService.open(refModal);
  }

  showModalEditUsuario(refModalSistema: any, row: UsuariosModel) {
    this.router.navigate(['/crud-usuario/' + row.rut]);
  }

  showModalConfirmDeleteUsuario(refModalConfirmDeleteUsuario, row) {
    this.modalRef = this.modalService.open(refModalConfirmDeleteUsuario);
    this.usuariosModelEliminar = row;
  }

  eliminarUsuario() {
    this.spinner.show();
    this.usuariosService.eliminarSistema(this.usuariosModelEliminar)
      .subscribe((result) => {
        this.spinner.hide();
        this.modalRef.close();
        this.toastr.success(result);
        this.listarUsuarios();
      }, error => {
        this.spinner.hide();
        this.toastr.warning('Ha ocurrido un error.');
      })
  }

  rolesUsuarioAsignar(row: UsuariosModel) {
    this.router.navigate([`/asignar-roles/${row.rut}`])
  }

  filterList($event, name) {

    switch (name) {
      case 'USERNAME': {
        if ($event !== undefined) {
          this.filtersModel.username = $event.target.value.toString();
          break;
        }
        else {
          this.filtersModel.username = '';
        }
      }
      case 'NOMBRE': {
        if ($event !== undefined) {
          this.filtersModel.nombre = $event.target.value.toString().toLowerCase();
          break;
        } else {
          this.filtersModel.nombre = '';
        }
      }
      case 'EMAIL': {
        if ($event !== undefined) {
          this.filtersModel.correo = $event.target.value.toString().toLowerCase();
          break;
        } else {
          this.filtersModel.correo = '';
        }
      }
    }

    if (this.filtersModel.nombre !== null ||
      this.filtersModel.username !== null ||
      this.filtersModel.email !== null) {

      const temp = this.listaUsuariosTemp.filter(d => {
        return (d.nombre.toLowerCase().indexOf(this.filtersModel.nombre) !== -1)
          && d.rut.toString().indexOf(this.filtersModel.username) !== -1
          && d.email.toLowerCase().indexOf(this.filtersModel.correo) !== -1;
      });

      this.listaUsuarios = temp;
      if (this.listaUsuarios.length == 0) {
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    }
    else {
      this.listaUsuarios = this.listaUsuariosTemp;
    }

  }
}
