import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {appRoutingProvider, routing} from './app.routing';
import { AngularWebStorageModule } from 'angular-web-storage';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxSpinnerModule} from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';

import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {CrudSistemasComponent} from './components/crud-sistemas/crud-sistemas.component';
import {CrudUsuariosComponent} from './components/crud-usuarios/crud-usuarios.component';
import {CrudRolesComponent} from './components/crud-roles/crud-roles.component';
import {CrudPermisosComponent} from './components/crud-permisos/crud-permisos.component';
import {CrudProvidersComponent} from './components/crud-providers/crud-providers.component';
import {CrudAttributesProviderListComponent} from './components/crud-attributes-provider-list/crud-attributes-provider-list.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {CognitoExceptionsModule} from './shared/cognitoExceptions.module';
import { RutValidator } from './directives/rutvalidator.directive';
import {OnlyNumberDirective, OnlyNumberNotZeroDirective} from './directives/onlynumber.directive';
import {EqualValidator} from './directives/equal-validator.directive';
import { AsociarUsuariosSistemasComponent } from './components/asociar-usuarios-sistemas/asociar-usuarios-sistemas.component';
import { AsignarRolesComponent } from './components/asignar-roles/asignar-roles.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthComponent } from './components/auth/auth.component';
import { CrudClientesComponent } from './components/crud-clientes/crud-clientes.component';
import { CrudClientesEmpresaComponent } from './components/crud-clientes-empresa/crud-clientes-empresa.component';
import { CrudClientesHoldingComponent } from './components/crud-clientes-holding/crud-clientes-holding.component';
import { CrudUsuarioComponent } from './components/crud-usuario/crud-usuario.component';
import { ActivarUsuarioComponent } from './components/activar-usuario/activar-usuario.component';
import { ListadoSistemasComponent } from './components/listado-sistemas/listado-sistemas.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ActivarResetPasswordComponent } from './components/activar-reset-password/activar-reset-password.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { environment } from 'src/environments/environment';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CrudSistemasComponent,
    CrudUsuariosComponent,
    CrudRolesComponent,
    CrudPermisosComponent,
    CrudProvidersComponent,
    CrudAttributesProviderListComponent,
    RutValidator,
    OnlyNumberDirective,
    OnlyNumberNotZeroDirective,
    EqualValidator,
    AsociarUsuariosSistemasComponent,
    AsignarRolesComponent,
    LoginComponent,
    LogoutComponent,
    AuthComponent,
    CrudClientesComponent,
    CrudClientesEmpresaComponent,
    CrudClientesHoldingComponent,
    CrudUsuarioComponent,
    ActivarUsuarioComponent,
    ListadoSistemasComponent,
    ResetPasswordComponent,
    ActivarResetPasswordComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.SW }),
    CognitoExceptionsModule,
    AngularWebStorageModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [appRoutingProvider],
  bootstrap: [AppComponent]
})
export class AppModule {
}
