<app-header></app-header>

<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-6 col-12">
            <h3 *ngIf="!isEdit"> Crear Usuario</h3>
            <h3 *ngIf="isEdit"> Editar Usuario</h3>
          </div>
          <div class="col-md-6 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/crud-usuarios']">Usuarios</a></li>
                <li class="breadcrumb-item active"><a>Usuario</a></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 col-12 mx-auto">
          <form [formGroup]="registerForm" (ngSubmit)="crearUsuarios()">
            <div class="col-xl-6 mx-auto">
              <div class="row row-fluid mx-auto">
                <div class="form-group col-xl-6 col-12">
                  <label> Rut: </label>
                  <input type="text" formControlName="rut" class="form-control" placeholder="Ingrese" id="rutValModal"
                         name="rutValModal" rutvalidator
                         required>
                  <div *ngIf="submitted && f.rut.errors" class="obligatorio-texto">
                    <div *ngIf="f.rut.errors.required">Rut es requerido</div>
                    <div *ngIf="!f.rut.errors.rutvalidator.valid">El RUT ingresado es incorrecto.</div>
                  </div>
                </div>
                <div class="form-group col-xl-6 col-12">
                  <label> Nombre: </label>
                  <input type="text" formControlName="nombre" class="form-control" placeholder="Ingrese"
                         id="nombreValModal"
                         name="nombreValModal"
                         required>
                  <div *ngIf="submitted && f.nombre.errors" class="obligatorio-texto">
                    <div *ngIf="f.nombre.errors.required">Nombre es requerido</div>
                  </div>
                </div>
                <div class="form-group col-xl-6 col-12">
                  <label><span class="obligatorio">*</span>Email: </label>
                  <input type="text" formControlName="email" class="form-control" placeholder="Ingrese" id="email"
                         name="email"
                         required>
                  <div *ngIf="submitted && f.email.errors" class="obligatorio-texto">
                    <div *ngIf="f.email.errors.required">Email es requerido</div>
                    <div *ngIf="f.email.errors.email">Debe contener una dirección de email válida.</div>
                  </div>
                </div>
                <div class="form-group col-xl-6 col-12">
                  <label>Seleccione un tipo cliente:</label>
                  <ng-select id="sistemas" name="sistemas" [items]="itemsTipoCliente"
                             bindLabel="text" bindValue="value" formControlName="tipoCliente"
                             (change)="filtrarClientes($event)">
                  </ng-select>
                  <div *ngIf="submitted && f.tipoCliente.errors" class="obligatorio-texto">
                    <div *ngIf="f.tipoCliente.errors.required">Debe seleccionar un tipo cliente</div>
                  </div>
                </div>
                <div class="form-group col-xl-6 col-12">
                  <label>Selecciones un cliente:</label>
                  <ng-select id="sistemas" name="sistemas" [items]="itemsClientes"
                             bindLabel="razonSocial" bindValue="rut" formControlName="holding"
                             (change)="buscarEmpresas($event)">
                  </ng-select>
                  <div *ngIf="submitted && f.holding.errors" class="obligatorio-texto">
                    <div *ngIf="f.holding.errors.required">Debe seleccionar un cliente</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <ngx-datatable
                  class="bootstrap table table-hover table-striped"
                  [rows]="listaEmpresas"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [rowHeight]="'auto'"
                  [limit]="5"
                  [selected]="selected"
                  [messages]="messages"
                  [selectionType]="'checkbox'"
                  (select)='onSelect($event)'>
                  <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                                 let-selectFn="selectFn">
                      <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                 let-onCheckboxChangeFn="onCheckboxChangeFn">
                      <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Rut">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                      {{row.rut}}-{{row.dv}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Razón Social">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                      {{row.razonSocial}}
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
            <div class="row float-right">
              <div class="col-xl-12 ">
                <button type="submit" class="btn btn-primary">Guardar</button>
                <button type="button" class="btn btn-default" name="buttonCancelar" [routerLink]="['/crud-usuarios']">
                  Volver
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>


