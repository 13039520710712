import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SistemasModel} from "../models/sistemas.model";
import {UsuariosModel} from "../models/usuarios.model";
import {BloqueoModel} from "../models/bloqueo.model";

@Injectable({
  providedIn: 'root'
})
export class SistemasService {

  constructor(private http: HttpClient) {
  }

  obtenerSistema(appClientId: string): Observable<any> {
    return this.http
      .get<SistemasModel>(`${environment.API_DOMAINS.SISTEMAS}/sistema/${appClientId}`)
      .pipe(
        map((result: SistemasModel) => {
          return result;
        })
      );
  }

  crearSistema(input: SistemasModel): Observable<any> {
    const body = JSON.stringify(input);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.SISTEMAS}/sistema`, body, {headers: headers});
  }

  editarSistema(input: SistemasModel): Observable<any> {
    const body = JSON.stringify(input);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.SISTEMAS}/sistema`, body, {headers: headers});
  }

  listarSistemas(): Observable<SistemasModel[]> {
    return this.http
      .get<SistemasModel[]>(`${environment.API_DOMAINS.SISTEMAS}/sistema`)
      .pipe(
        map((result: any) => {
          return result.map((actividad: any) => {
            return new SistemasModel(actividad);
          });
        })
      );
  }

  // obtenerBloqueo(): Observable<any[]> {
  //   return this.http.get(`${environment.API_DOMAINS.SISTEMAS}/bloqueo`)
  //     .pipe(
  //       map((result: any[]) => {
  //         return result;
  //       })
  //     );
  // }

  eliminarSistema(input: SistemasModel): Observable<any> {
    return this.http.delete(`${environment.API_DOMAINS.SISTEMAS}/sistema/${input.appClientId}`);
  }

  obtenerSistemasPorUsuario(username: string): Observable<SistemasModel[]> {
    return this.http.get<SistemasModel[]>(`${environment.API_DOMAINS.SISTEMAS}/sistema/usuario/${username}`)
      .pipe(
        map((result: any[]) => {
          return result.map((item) => {
            return new SistemasModel(item);
          });
        })
      );
  }
}
