export class HoldingModel {

  public rut: number;
  public dv: string;
  public razonSocial: string;
  public giro: string;
  public domicilio: string;
  public telefono: string;
  public estado: boolean;
  public tipoCliente: number;

  constructor(input?: any) {
    this.rut = input && input.rut || '';
    this.dv = input && input.dv || '';
    this.razonSocial = input && input.razonSocial || '';
    this.giro = input && input.giro || '';
    this.domicilio = input && input.domicilio || '';
    this.telefono = input && input.telefono || '';
    this.estado = input && input.estado || false;
    this.tipoCliente = input && input.tipoCliente || '';
  }

}
