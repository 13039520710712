//Angular
import { FormBuilder, Validators } from "@angular/forms";
import { OnInit, TemplateRef } from '@angular/core';
import { Router } from "@angular/router";
//Utils
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import * as cloneDeep from 'lodash/cloneDeep';
//Services
import { SistemasService } from '../../services/sistemas.service';
import { RolesService } from '../../services/roles.service';
import { UsuariosService } from "../../services/usuarios.service";
import { UserpoolService } from "../../services/userpool.service";
import { LocalStorageService } from "angular-web-storage";
import { RolesModel } from '../../models/roles.model';
import { environment } from "../../../environments/environment";
var CrudRolesComponent = /** @class */ (function () {
    function CrudRolesComponent(sistemaService, modalService, formBuilder, rolesService, toastr, spinner, router, usuariosService, userpoolService, localstorageService) {
        this.sistemaService = sistemaService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.rolesService = rolesService;
        this.toastr = toastr;
        this.spinner = spinner;
        this.router = router;
        this.usuariosService = usuariosService;
        this.userpoolService = userpoolService;
        this.localstorageService = localstorageService;
        this.lstSistemas = [];
        this.messages = {
            emptyMessage: "No hay registros disponibles",
            totalMessage: 'Total'
        };
        this.columns = [];
        this.listadoRoles = [];
        this.flgShowView = false;
        this.flagModalRoles = false;
        this.submitted = false;
        this.rolModel = new RolesModel();
        this.rolModelEliminar = new RolesModel();
    }
    CrudRolesComponent.prototype.ngOnInit = function () {
        this.usuariosService.isAuthenticated(this, null);
    };
    CrudRolesComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        console.log('CrudEncuestaComponent: verificacion sesion');
        if (!isLoggedIn) {
            this.validarPermisos();
        }
        else {
            this.columns = [
                { name: 'ID', prop: 'idRol' },
                { name: 'Nombre Rol', prop: 'nombre' },
                { name: 'Opciones', cellTemplate: this.optionTemplate },
            ];
            this.registerForm = this.formBuilder.group({
                nombre: ['', Validators.required]
            });
            this.listarSistemas();
        }
    };
    CrudRolesComponent.prototype.validarPermisos = function () {
        var _this = this;
        console.log(this.localstorageService.get('accessToken'));
        var access_token = this.localstorageService.get('accessToken');
        var id_token = this.localstorageService.get('idToken');
        if (access_token !== null && id_token !== null) {
            this.userpoolService.validateToken({ access_token: access_token, id_token: id_token })
                .subscribe(function (result) {
                console.log(result);
            }, function (error1) {
                if (error1.error === 'token denied') {
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
                else if (error1.error === 'Token is expired') {
                    _this.toastr.info('La sessión ha caducado.');
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
            });
        }
        else {
            this.router.navigate(['/login'], {
                queryParams: {
                    redirect_uri: environment.URL_AUTH,
                    response_type: 'token',
                    client_id: environment.CLIENT_ID
                }
            });
        }
    };
    Object.defineProperty(CrudRolesComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CrudRolesComponent.prototype.listarSistemas = function () {
        var _this = this;
        this.spinner.show();
        this.sistemaService.listarSistemas()
            .subscribe(function (result) {
            _this.lstSistemas = result;
            _this.spinner.hide();
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
        });
    };
    CrudRolesComponent.prototype.listarRoles = function (appClientId) {
        var _this = this;
        this.spinner.show();
        this.rolesService.listarRoles(appClientId)
            .subscribe(function (result) {
            _this.listadoRoles = result;
            _this.spinner.hide();
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
        });
    };
    CrudRolesComponent.prototype.selectSistema = function ($event) {
        this.flgShowView = true;
        this.listarRoles(this.sistemaSeleccionado);
    };
    CrudRolesComponent.prototype.modalFormRol = function (refModal) {
        this.flagModalRoles = false;
        this.submitted = false;
        this.rolModel = new RolesModel();
        this.registerForm.reset();
        this.modalRef = this.modalService.open(refModal);
    };
    CrudRolesComponent.prototype.hideModal = function () {
        this.modalRef.close();
    };
    CrudRolesComponent.prototype.saveRol = function () {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.spinner.show();
        this.rolModel.nombre = this.registerForm.controls.nombre.value;
        if (!this.flagModalRoles) {
            this.rolModel.appClientId = this.sistemaSeleccionado;
            this.rolesService.crearRol(this.rolModel)
                .subscribe(function (result) {
                _this.modalRef.close();
                _this.listarRoles(_this.sistemaSeleccionado);
                _this.toastr.success(result);
            }, function (error) {
                _this.spinner.hide();
                if (error.status === 400) {
                    console.log(error);
                    _this.toastr.warning(error.error);
                }
            });
        }
        else {
            this.rolesService.editarRol(this.rolModel)
                .subscribe(function (result) {
                console.log(result);
                _this.modalRef.close();
                _this.listarRoles(_this.sistemaSeleccionado);
                _this.toastr.success(result);
            }, function (error) {
                _this.spinner.hide();
                if (error.status === 400) {
                    console.log(error);
                    _this.toastr.warning(error.error);
                }
            });
        }
    };
    CrudRolesComponent.prototype.showModalEditRol = function (refModalRoles, row) {
        this.flagModalRoles = true;
        this.rolModel = new RolesModel();
        this.rolModel = cloneDeep(row);
        this.modalRef = this.modalService.open(refModalRoles);
        this.registerForm.controls.nombre.setValue(row.nombre);
    };
    CrudRolesComponent.prototype.showModalConfirmDeleteRol = function (refModalConfirmDeleteRoles, row) {
        this.rolModelEliminar = cloneDeep(row);
        this.modalRef = this.modalService.open(refModalConfirmDeleteRoles);
    };
    CrudRolesComponent.prototype.eliminarRol = function () {
        var _this = this;
        this.spinner.show();
        this.rolesService.eliminarRol(this.rolModelEliminar)
            .subscribe(function (result) {
            console.log(result);
            _this.modalRef.close();
            _this.listarRoles(_this.sistemaSeleccionado);
            _this.spinner.hide();
            _this.rolModelEliminar = new RolesModel();
            _this.toastr.success(result);
        }, function (error) {
            _this.spinner.hide();
            console.log(error);
        });
    };
    return CrudRolesComponent;
}());
export { CrudRolesComponent };
