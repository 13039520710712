
export class UsuarioRecuperaModel {

    public username: string;
    public email: string;

    constructor() {
        this.username = '';
        this.email = '';
    }

}
