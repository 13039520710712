import {
  ReactiveFormsModule,
  NG_VALIDATORS,
  FormsModule,
  FormGroup,
  FormControl,
  ValidatorFn,
  Validator
} from '@angular/forms';

import {Directive} from '@angular/core';

@Directive({
  selector: '[rutvalidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RutValidator,
    multi: true
  }]
})
export class RutValidator implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = this.rutValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  rutValidator(): ValidatorFn {
    return (c: FormControl) => {
      let isValid = this.isValid(c.value);

      if (isValid) {
        return null;
      } else {
        return {
          rutvalidator: {
            valid: isValid
          }
        };
      }
    };
  }

  rutValidatorWithValue(): ValidatorFn {
    return (c: FormControl) => {
      if (c.value != '') {
        let isValid = this.isValid(c.value);
        if (isValid) {
          return null;
        } else {
          return {
            rutvalidator: {
              valid: isValid
            }
          };
        }
      } else {
        return {
          rutvalidator: {
            valid: true
          }
        };
      }
    };
  }

  isValid(rut: string): boolean {
    if (!/^[0-9]+-[0-9kK]{1}$/.test(rut)) {
      return false;
    }

    const tmp = rut.split('-');

    return this.checkDV(tmp[0]) == tmp[1].toLowerCase();
  }

  checkDV(rutPartNumber: string): any {
    let secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
    let s = 0;

    for (let i: number = rutPartNumber.length - 1; i >= 0; i--) {
      let d = rutPartNumber.charAt(i);
      s += parseInt(d, 10) * secuencia[rutPartNumber.length - (i + 1)];
    }

    let rest = 11 - (s % 11);

    return rest === 11 ? 0 : rest === 10 ? 'k' : rest;
  }
}
