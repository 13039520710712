<app-header></app-header>
<div class="container-fluid margin-bottom-30">
  <section>
    <div class="fondo-blanco">
      <div class="row padding-5">
        <div class="col-lg-6 col-12">
          <h1>Configuración Providers</h1>
        </div>
        <div class="col-lg-6 col-12 text-right">
          <div class="text-right float-right" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
              <li class="breadcrumb-item active"><a>Providers</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div class="col-xl-10 col-12 mx-auto">
        <div class="row row-fluid mx-auto">
          <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" (panelChange)="beforeChange($event)" class="col-xl-12">
            <ngb-panel id="Google" title="Google">
              <ng-template ngbPanelContent>
                <div class="col-xl-10 col-lg-12 mx-auto col-12 card-ok margin-top-10">
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>App ID:</label>
                      <input type="text" class="form-control"
                             id="googleClientId" name="clientId" [(ngModel)]="googleModel.clientId" required>
                    </div>
                  </div>
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>App secret:</label>
                      <input type="text" class="form-control"
                             id="googleClientSecret" name="clientSecret" [(ngModel)]="googleModel.clientSecret"  required>
                    </div>
                  </div>
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>Authorize scope:</label>
                      <input type="text" class="form-control"
                             id="googleAuthorizeScope" name="authorizeScope" [(ngModel)]="googleModel.authorizeScope" required>
                    </div>
                  </div>
                  <div class="form-row padding-5" *ngIf="false">
                    <div class="form-group col-xl-6 col-md-6 col-12">
                      <label>Imagen:</label>
                      <input type="file" class="form-control">
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 margin-top-30">
                    </div>
                  </div>
                  <div class="form-row padding-5">
                    <div class="col-xl-12 col-12">
                      <h3>Configuraci&oacute;n Provider</h3>
                    </div>
                  </div>
                  <div class="form-row-padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <app-crud-attributes-provider-list
                        (listAttributesChange)="actualizarAtributos($event, 'Google')">
                      </app-crud-attributes-provider-list>
                    </div>
                  </div>
                  <div class="form-row padding-5 float-right">
                    <button class="btn btn-dark right" (click)="guardarProvider('Google')">Guardar</button>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="Facebook" title="Facebook">
              <ng-template ngbPanelContent>
                <div class="col-xl-10 col-lg-12 mx-auto col-12 card-ok margin-top-10">
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>App ID:</label>
                      <input type="text" class="form-control"
                             id="clientId" name="clientId" [(ngModel)]="facebookModel.clientId" required>
                    </div>
                  </div>
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>App secret:</label>
                      <input type="text" class="form-control"
                             id="clientSecret" [(ngModel)]="facebookModel.clientSecret" name="clientSecret"  required>
                    </div>
                  </div>
                  <div class="form-row padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <label>Authorize scope:</label>
                      <input type="text" class="form-control"
                             id="authorizeScope" [(ngModel)]="facebookModel.authorizeScope" name="authorizeScope" required>
                    </div>
                  </div>
                  <div class="form-row padding-5" *ngIf="false">
                    <div class="form-group col-xl-6 col-md-6 col-12">
                      <label>Imagen:</label>
                      <input type="file" class="form-control">
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 margin-top-30">
                    </div>
                  </div>
                  <div class="form-row-padding-5">
                    <div class="form-group col-xl-12 col-12">
                      <app-crud-attributes-provider-list
                        (listAttributesChange)="actualizarAtributos($event, 'Facebook')">>
                      </app-crud-attributes-provider-list>
                    </div>
                  </div>
                  <div class="form-row padding-5 float-right">
                    <button class="btn btn-dark right" (click)="guardarProvider('Facebook')">Guardar</button>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

      </div>
    </div>
  </section>
</div>
