// Angular
import {Component, OnInit, TemplateRef, Output, ViewChild, EventEmitter} from '@angular/core';

// Util
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash/cloneDeep';

// Services
import {UserpoolService} from "../../services/userpool.service";
import {ProvidersService} from "../../services/providers.service";
import {ProviderElementContext} from "@angular/compiler/src/provider_analyzer";

@Component({
  selector: 'app-crud-attributes-provider-list',
  templateUrl: './crud-attributes-provider-list.component.html',
  styleUrls: ['./crud-attributes-provider-list.component.css']
})
export class CrudAttributesProviderListComponent implements OnInit {
  @ViewChild('optionTemplate', { static: true }) public optionTemplate: TemplateRef<any>;
  @Output() listAttributesChange = new EventEmitter<any[]>();
  array: any[] = [];
  providerName: string = null;
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  modalRef: NgbModalRef;
  atributosUserpool: any[] = [];
  atributosUserpoolTemp: any[] = [];

  atributosProviders: any[] = [];
  atributosProvidersTemp: any[] = [];
  config = {
    provider: null,
    userPool: null
  };
  indexEdit: number;

  constructor(private modalService: NgbModal,
              private userPoolService: UserpoolService,
              private providersService: ProvidersService) {
  }

  ngOnInit() {
    this.listarAtributosUserpool();
    //this.listarAtributosProviders();
  }

  listarAtributosUserpool() {
    this.userPoolService.listarAtributosUserpool()
      .subscribe((result: any[]) => {
        this.atributosUserpoolTemp = result;
      }, error1 => {
        console.log(error1);
      })
  }

  listarAtributosProviders(providerName) {
    this.providersService.listarProvidersAtributos(providerName)
      .subscribe((result) => {
        console.log(result);
        this.atributosProvidersTemp = result;
      }, error1 => {
        console.log(error1);
      })
  }


  showModalEditarAtributo(refModalAtributos, row, index) {
    this.indexEdit = index;
    this.atributosUserpool = cloneDeep(this.atributosUserpoolTemp);
    this.atributosProviders = cloneDeep(this.atributosProvidersTemp);
    const tempExistentesUserPool = cloneDeep(this.array.filter(x => x.userPoolAttribute !== row.userPoolAttribute));
    const tempExistentesProvider = cloneDeep(this.array.filter(x => x.providerAttribute !== row.providerAttribute));
    tempExistentesUserPool.map((item) => {
      const indexOf = this.atributosUserpool.map((item2) => {
        return item2.name;
      }).indexOf(item.userPoolAttribute);
      this.atributosUserpool.splice(indexOf, 1);
    });
    tempExistentesProvider.map((item) => {
      const indexOf = this.atributosProviders.map((item2) => {
        return item2.name;
      }).indexOf(item.userPoolAttribute);
      this.atributosProviders.splice(indexOf, 1);
    });
    this.config = {
      userPool:row.userPoolAttribute,
      provider: row.providerAttribute
    };
    this.modalRef = this.modalService.open(refModalAtributos);
  }

  showModalAgregarAtributo(refModalAtributos) {
    this.indexEdit = null;
    this.config = {
      provider: null,
      userPool: null,
    };
    this.atributosUserpool = cloneDeep(this.atributosUserpoolTemp);
    this.atributosProviders = cloneDeep(this.atributosProvidersTemp);
    this.array.map((item) => {
      console.log(item);
      const indexOfUserPool = this.atributosUserpool.map((item2) => {
        return item2.name;
      }).indexOf(item.userPoolAttribute);

      const indexOfProvider = this.atributosProviders.map((item2) => {
        return item2;
      }).indexOf(item.providerAttribute);
      this.atributosUserpool.splice(indexOfUserPool, 1);
      this.atributosProviders.splice(indexOfProvider, 1);
    });
    this.modalRef = this.modalService.open(refModalAtributos);
  }

  hideModal() {
    this.modalRef.close();
  }

  guardarConfiguracionProvider() {
    console.log(this.config);
    if(this.indexEdit !== null){
      this.array[this.indexEdit] = {
        providerAttribute: this.config.provider,
        userPoolAttribute: this.config.userPool,
      }
    } else {
      this.array.push({
        providerAttribute: this.config.provider,
        userPoolAttribute: this.config.userPool,
      });
    }
    const temp = cloneDeep(this.array);
    this.array = [];
    this.array = temp;
    this.listAttributesChange.emit(this.array);
    this.hideModal();
  }

  quitarAtributo(rowIndex) {
    this.array.splice(rowIndex, 1);
    const temp = cloneDeep(this.array);
    this.array = [];
    this.array = temp;
    this.listAttributesChange.emit(this.array);
  }
}
