<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-12">
        <img src="assets/img/otic-sofofa-2018.png" alt="Logo OTIC Sofofa" class="float-left margin-top-10"
             style="height:50px;width:auto;">
      </div>
    </div>

    <div class="row margin-top-50">
      <div class="col-xl-8 col-md-8 mx-auto col-12 card">

        <div class="row">
          <div class="col-xl-8 mx-auto col-12 mx-auto text-center pt-3 pb-3">
            <h3>Bienvenido</h3>
            <p>Por favor seleccione una aplicación:</p>
          </div>
        </div>
        <div class="row p-4" *ngFor="let items of listadoSistemas;">
          <div class="col-lg-4 col-sm-6 col-12 text-center card card-border" *ngFor="let item of items">
            <a href="javascript:;" (click)="goPage(item)">
              <img src="assets/img/logo-otic-2018.png" class="mt-2" alt="Gestor Capital Humano."
                   style="height:50px;width:auto;">
              <p class="mt-3 font-weight-bold">{{item.alias}}</p>
            </a>
            <a href="javascript:;" (click)="goPage(item)" class="btn btn-primary">Ingresar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

