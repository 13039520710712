export class SistemasModel {
  public idSistema: number;
  public nombre: string;
  public appClientId: string;
  public callbackUrl: string [];
  public logoutUrl: string[];
  public alias: string;
  public supportedIdentityProviders: string[];
  public eliminado: boolean;

  constructor(obj?: any) {
    this.idSistema = obj && obj.idSistema || null;
    this.nombre = obj && obj.nombre || '';
    this.alias = obj && obj.alias || '';
    this.appClientId = obj && obj.appClientId || '';
    this.callbackUrl = obj && obj.callbackUrl || '';
    this.logoutUrl = obj && obj.logoutUrl || '';
    this.supportedIdentityProviders = obj && obj.supportedIdentityProviders || [];
    this.eliminado = obj && obj.eliminado || false;
  }

}
