/**
 * Modelo con datos de inicio de sesion.
 *
 * @author pumanar
 */
export class UsuarioLoginModel {

    public username: string;
    public password: string;
    public client_id: string;
    public auth_flow: string;

    constructor() {
        this.username = '';
        this.password = '';
        this.client_id = '';
        this.auth_flow = '';
    }

}
