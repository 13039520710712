import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";

import {NgxSpinnerService} from "ngx-spinner";

import {SistemasModel} from "../../models/sistemas.model";

import {SistemasService} from "../../services/sistemas.service";
import {LocalStorageService} from "angular-web-storage";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-listado-sistemas',
  templateUrl: './listado-sistemas.component.html',
  styleUrls: ['./listado-sistemas.component.css']
})
export class ListadoSistemasComponent implements OnInit {

  listadoSistemas: any[] = [];
  username: any;

  constructor(private sistemasService: SistemasService,
              private router: Router,
              private localstorageService: LocalStorageService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute) {
    this.route.params.subscribe((params: any) => {
      this.username = params.unser;
    });
  }

  ngOnInit() {
    this.spinner.show();
    var index = 0, count = 0;
    var arrayPar = [];
    this.sistemasService.obtenerSistemasPorUsuario(this.username.toUpperCase())
      .subscribe((result: SistemasModel[]) => {
        if(result.length > 1){
          console.log('result', result);
          result.map((item) => {
            index++;
            count++;
            arrayPar.push(item);
            if (index === 3) {
              this.listadoSistemas.push(arrayPar);
              index = 0;
              arrayPar = [];
            } else if (result.length === count) {
              this.listadoSistemas.push(arrayPar);
            }
          });
          this.spinner.hide();
        } else {
          const item = {
            appClientId: result[0].appClientId,
            callbackUrl: result[0].callbackUrl
            };
          this.goPage(item);
        }
        console.log('redireccionar')
        for (const sist of this.listadoSistemas[0]) {
          if(sist.alias === "Gestor de Capital Humano"){
            this.goPage(sist);
          }

        }
      }, error => {
        this.spinner.hide();
        console.log(error);
      });
  }

  goPage(item) {
    this.router.navigate(['/login'], {
      queryParams: {
        redirect_uri: item.callbackUrl,
        response_type: 'token',
        client_id: item.appClientId
      }
    });
  }
}
