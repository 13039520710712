import { map } from 'rxjs/operators';
import { EmpresaModel } from "../models/empresa.model";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientesService = /** @class */ (function () {
    function ClientesService(http) {
        this.http = http;
    }
    ClientesService.prototype.listarClientes = function () {
        return this.http.get(environment.API_DOMAINS.CLIENTES + "/cliente")
            .pipe(map(function (item) {
            return item.map(function (result) {
                return result;
            });
        }));
    };
    ClientesService.prototype.crearCliente = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.CLIENTES + "/cliente", body, { headers: headers });
    };
    ClientesService.prototype.editarCliente = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.put(environment.API_DOMAINS.CLIENTES + "/cliente", body, { headers: headers });
    };
    ClientesService.prototype.crearEmpresa = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.CLIENTES + "/cliente/empresa", body, { headers: headers });
    };
    ClientesService.prototype.editarEmpresa = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.put(environment.API_DOMAINS.CLIENTES + "/cliente/empresa", body, { headers: headers });
    };
    ClientesService.prototype.listarEmpresasHolding = function (input) {
        return this.http.get(environment.API_DOMAINS.CLIENTES + "/cliente/empresa/lista/" + input)
            .pipe(map(function (item) {
            return item.map(function (result) {
                return new EmpresaModel(result);
            });
        }));
    };
    ClientesService.prototype.obtenerHolding = function (input) {
        return this.http.get(environment.API_DOMAINS.CLIENTES + "/cliente/holding/" + input)
            .pipe(map(function (result) {
            return result;
        }));
    };
    ClientesService.prototype.obtenerEmpresa = function (input) {
        return this.http.get(environment.API_DOMAINS.CLIENTES + "/cliente/empresa/" + input)
            .pipe(map(function (result) {
            return result;
        }));
    };
    ClientesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientesService_Factory() { return new ClientesService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientesService, providedIn: "root" });
    return ClientesService;
}());
export { ClientesService };
