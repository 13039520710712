import * as i0 from "@angular/core";
var ValidationService = /** @class */ (function () {
    function ValidationService() {
    }
    ValidationService.httpsValidator = function (control) {
        if (control.value !== null) {
            if (control.value.match('^(https)://.*$')) {
                return null;
            }
            else {
                return { 'invalidHttps': true };
            }
        }
        else {
            return null;
        }
    };
    ValidationService.checkValidator = function (control) {
        console.log(control);
        var total = control.controls
            .map(function (control) { return control.value; })
            .reduce(function (prev, next) { return next ? prev + next : prev; }, 0);
        return total > 0 ? null : { required: true };
    };
    ValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
