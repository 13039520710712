import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// Util
import {ToastrService} from "ngx-toastr";
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from "ngx-spinner";

// Models
import {HoldingModel} from '../../models/holding.model';
import {EmpresaModel} from "../../models/empresa.model";

//Services
import {ClientesService} from "../../services/clientes.service";

@Component({
  selector: 'app-crud-clientes-holding',
  templateUrl: './crud-clientes-holding.component.html',
  styleUrls: ['./crud-clientes-holding.component.css']
})
export class CrudClientesHoldingComponent implements OnInit {
  @ViewChild('mdlConfirmarAgregarEmpresa', { static: false }) mdlConfirmarAgregarEmpresa;
  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<any>;
  @ViewChild('rutTemplate', { static: false }) public rutTemplate: TemplateRef<any>;
  private modalRef: NgbModalRef;

  modelHolding: HoldingModel = new HoldingModel();
  rutHolding: string;
  rutEmpresa: string;
  empresaActual: EmpresaModel = new EmpresaModel();
  empresaEliminar: EmpresaModel = new EmpresaModel();
  globalRutHolding: number;
  crearEmpresaFlg: boolean = false;
  editarEmpresaFlg: boolean = false;

  columns = [];
  listadoEmpresas: EmpresaModel[] = [];
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };

  pathParams: any;

  constructor(private clientesService: ClientesService,
              private tostr: ToastrService,
              private modalService: NgbModal,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    console.log('CrudClientesHoldingComponent: ngOnInit');
    this.columns = [
      {name: 'Rut', cellTemplate: this.rutTemplate},
      {name: 'Razón Social', prop: 'razonSocial'},
      {name: 'Opciones', cellTemplate: this.optionTemplate},

    ];
    this.route.params.subscribe(params => this.pathParams = params);
    if (this.pathParams.rutHolding !== undefined) {
      if (isNaN(this.pathParams.rutHolding)) {
        this.router.navigateByUrl('/crud-clientes');
      } else {
        this.globalRutHolding = this.pathParams.rutHolding;
        this.obtenerHolding();
        this.crearEmpresaFlg = true;
      }
    } else {
      this.crearEmpresaFlg = false;
    }
  }

  /**
   * Obtiene información del holding por su rut
   */
  obtenerHolding() {
    this.spinner.show();
    this.clientesService.obtenerHolding(this.pathParams.rutHolding)
      .subscribe((result: HoldingModel) => {
        this.spinner.hide();
        this.rutHolding = result.rut + "-" + result.dv;
        this.modelHolding = result;
        console.log(result);
        this.listarEmpresasHolding();
      }, error => {
        this.spinner.hide();
        console.log(error);
      })
  }

  /**
   * Guarda el holding
   * @param formCrearHolding formulario de holding
   */
  guardarHolding(formCrearHolding) {
    if (!formCrearHolding.valid) {
      return;
    }
    this.spinner.show();
    // const rutCompleto = this.rutHolding.split('-');
    // this.modelHolding.rut = parseInt(rutCompleto[0], 0);
    // this.modelHolding.dv = rutCompleto[1];
    this.modelHolding.tipoCliente = 0;
    if (!this.crearEmpresaFlg) {
      this.clientesService.crearCliente(this.modelHolding).subscribe(
        (result) => {
          console.log(result);
          this.spinner.hide();
          this.globalRutHolding = parseInt(result.rut, 0);
          this.modalRef = this.modalService.open(this.mdlConfirmarAgregarEmpresa);
        }, error => {
          this.spinner.hide();
          this.tostr.warning(error.error);
          console.log(error);
        })
    } else if (this.crearEmpresaFlg) {
      this.clientesService.editarCliente(this.modelHolding).subscribe(
        (result) => {
          this.spinner.hide();
          this.tostr.success('Holding editado con éxito');
          console.log(result);
        }, error => {
          this.spinner.hide();
          this.tostr.warning(error.error);
          console.log(error);
        })
    }


  }

  /**
   * Lista empresas de holding
   */
  listarEmpresasHolding() {
    this.spinner.show();
    this.clientesService.listarEmpresasHolding(this.globalRutHolding)
      .subscribe((result: EmpresaModel[]) => {
        console.log(result);
        this.listadoEmpresas = result;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      })
  }

  /**
   * Cierra modal y vuelve al listado
   */
  hideModalReturn() {
    this.modalRef.close();
    this.router.navigate(['/crud-clientes'])
  }

  /**
   * cierra modal
   */

  hideModal() {
    this.modalRef.close();
  }

  /**
   * Muetra los campos y listado de las empresas
   */
  irCrearEmpresa() {
    this.crearEmpresaFlg = true;
    this.modalRef.close();
  }

  /**
   * Guardar empresas asociada a holding
   * @param formCrearEmpresa
   */
  guardaAsociacionEmpresaAlHolding(formCrearEmpresa) {
    if (!formCrearEmpresa.valid) {
      return;
    }
    const rutCompleto = this.rutEmpresa.split('-');
    this.empresaActual.holdRut = this.globalRutHolding;
    this.empresaActual.rut = parseInt(rutCompleto[0], 0);
    this.empresaActual.dv = rutCompleto[1];
    console.log(this.empresaActual);
    this.spinner.show();
    if (!this.editarEmpresaFlg) {
      this.clientesService.crearEmpresa(this.empresaActual)
        .subscribe((result) => {
          console.log(result);
          this.modalRef.close();
          this.listarEmpresasHolding();
          this.tostr.success(result);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          this.tostr.error(error.error);
          console.log(error);
        })
    } else {
      this.clientesService.editarEmpresa(this.empresaActual)
        .subscribe((result) => {
          console.log(result);
          this.modalRef.close();
          this.listarEmpresasHolding();
          this.tostr.success(result);
          this.spinner.hide();
        }, error => {
          this.tostr.error(error.error);
          this.spinner.hide();
          console.log(error);
        })
    }
  }

  /**
   * Mostrar popup para crear empresa
   * @param mdlFormEmpresa
   */
  showFormEmpresa(mdlFormEmpresa) {
    this.rutEmpresa = null;
    this.editarEmpresaFlg = false;
    this.empresaActual = new EmpresaModel();
    this.modalRef = this.modalService.open(mdlFormEmpresa, {size: 'lg'});
  }

  /**
   * Mostrar formulario empresa para editar
   */
  showformEmpresaEdit(mdlFormEmpresa, row) {
    this.editarEmpresaFlg = true;
    this.rutEmpresa = row.rut + '-' + row.dv;
    this.empresaActual = row;
    this.modalRef = this.modalService.open(mdlFormEmpresa, {size: 'lg'});
  }

  /**
   * Muestra el modal para la confirmación de eliminación de Empresa
   * @param refModalConfirmDeleteEmpresa
   * @param row
   */
  showDelete(refModalConfirmDeleteEmpresa, row: EmpresaModel) {
    this.modalRef = this.modalService.open(refModalConfirmDeleteEmpresa);
    this.empresaEliminar = row;
    this.empresaEliminar.estado = 1;

  }

  /**
   * Se elimina la empresa (Cambio de estado)
   */
  eliminarEmpresa() {
    this.spinner.show();
    this.clientesService.editarEmpresa(this.empresaEliminar)
      .subscribe((result) => {
        console.log(result);
        this.modalRef.close();
        this.spinner.hide();
        this.listarEmpresasHolding();
        this.tostr.success('Empresa eliminada con éxito.');
      }, error => {
        this.spinner.hide();
        this.tostr.error(error.error);
        console.log(error);
      })
  }
}
