import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "angular-web-storage";
import {UsuariosService} from "../../services/usuarios.service";
import {UserpoolService} from "../../services/userpool.service";
import {LoggedInCallback} from "../../services/cognito.service";
import {Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

import {GlobalService} from "../../shared/global.shared";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [GlobalService]
})
export class HeaderComponent implements OnInit, LoggedInCallback {
  userData: any = {
    rut: null,
    nombre: null,
    empresa: null,
    rutEmpresa: null,
    roles: [],
    email: null,
    rutHolding: null,
    now: null,
    realUser: null
  };

  constructor(private usuariosService: UsuariosService,
              private userpoolService: UserpoolService,
              private router: Router,
              private toastr: ToastrService,
              private localstorageService: LocalStorageService,
              private globalService: GlobalService) {
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  validateRoles(roles) {
    return this.globalService.validateRoles(roles);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    // if (!isLoggedIn) {
    //   this.validarPermisos();
    // }
    this.userData = this.localstorageService.get(`${environment.CLIENT_ID}.userData`);
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }


  doLogout() {
    console.log('HeaderComponent: cerrando sesion');

    this.localstorageService.remove('userData');
    this.localstorageService.remove('idToken');
    this.usuariosService.logout();
  }
}
