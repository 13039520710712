import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';

import {CognitoUserPool} from 'amazon-cognito-identity-js';


export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoUtilService {
  public static REGION = environment.REGION;

  // public static IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
  public static USER_POOL_ID = environment.USER_POOL_ID;
  public static CLIENT_ID;

  public static POOL_DATA: any = {
    UserPoolId: CognitoUtilService.USER_POOL_ID,
    ClientId: CognitoUtilService.CLIENT_ID
  };

  public cognitoCreds: any;

  agregarAppClient(appClient) {
    CognitoUtilService.CLIENT_ID = appClient || environment.CLIENT_ID;
  }

  getUserPool() {
    /*if (environment.IDENTITY_POOL_ID) {
        CognitoUtilService.POOL_DATA.endpoint = environment.IDENTITY_POOL_ID;
    }*/

    return new CognitoUserPool({
      UserPoolId: CognitoUtilService.USER_POOL_ID,
      ClientId: CognitoUtilService.CLIENT_ID
    });
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getAccessToken(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getAccessToken is null...returning");
    }

    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    }
    else {
      callback.callbackWithParam(null);
    }
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback en getIdToken es null...retornando');
    }

    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession((err, session) => {
        if (err) {
          console.log('CognitoUtil: No se pudo obtener las credenciales:' + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            console.log('CognitoUtil: Se obtuvo el token sin embargo la sesion es inválida');
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }
}
