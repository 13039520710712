import {Injectable} from '@angular/core';
import {RolesModel} from '../models/roles.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UsuariosModel} from "../models/usuarios.model";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) {
  }

  crearRol(model: RolesModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.ROLES}/rol`, body, {headers: headers});
  }

  editarRol(model: RolesModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.ROLES}/rol`, body, {headers: headers});
  }

  listarRoles(appClientId: string): Observable<RolesModel[]> {
    return this.http.get<RolesModel[]>(`${environment.API_DOMAINS.ROLES}/rol/${appClientId}`)
      .pipe(
        map((result: any[]) => {
          console.log(result);
          return result.map((actividad: any) => {
            return new RolesModel(actividad);
          });
        })
      )
  }

  eliminarRol(rol: RolesModel): Observable<any> {
    return this.http.delete(`${environment.API_DOMAINS.ROLES}/rol/delete/${rol.idRol}`);
  }

  obtenerRolesPorUsuario(username: string, appclientid: string): Observable<RolesModel[]> {
    return this.http.get<RolesModel[]>(`${environment.API_DOMAINS.ROLES}/rol/usuario/${username}/${appclientid}`)
      .pipe(map((result:any[])=> {
        return result.map((item) => {
          return new RolesModel(item);
        })}))
  }

  guardarRolesPorUsuario(input: any): Observable<any>{
    const body = JSON.stringify(input);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.ROLES}/rol/usuario`, body, {headers: headers});
  }
}
