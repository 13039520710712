import {AttributeMappingModel} from "./attribute-mapping.model";

export class ProviderModel {

  public idpIdentifiers: string;
  public providerName: string;
  public providerType: string;
  public clientId: string;
  public clientSecret: string;
  public authorizeScope: string;
  public attributeMapping: AttributeMappingModel[];
  public requestMethod: string;
  public issuer: string;
  public imagen: string;
  public urlPreview: string;
  public providerCheck: boolean;
  public estandar: boolean;
  public multiple: boolean;

  /**
   * Constructor.
   */
  constructor(objJson?: any) {
    this.idpIdentifiers = objJson && objJson.idpIdentifiers || null;
    this.providerName = objJson && objJson.idpIdentifiers || null;
    this.providerType = objJson && objJson.providerType || null;
    this.clientId = objJson && objJson.clientId || null;
    this.clientSecret = objJson && objJson.clientSecret || null;
    this.authorizeScope = objJson && objJson.authorizeScope || null;
    this.attributeMapping = objJson && objJson.attributeMapping || null;
    this.requestMethod = objJson && objJson.requestMethod || null;
    this.issuer = objJson && objJson.issuer || null;
    this.imagen = objJson && objJson.imagen || null;
    this.urlPreview = objJson && objJson.urlPreview || null;
    this.providerCheck = objJson && objJson.providerCheck == true || false;
    this.estandar = objJson && objJson.estandar || false;
    this.multiple = objJson && objJson.multiple || false;
  }

}
