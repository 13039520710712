import { Component, OnInit } from '@angular/core';
import {CambioClaveModel} from '../../models/cambio-clave.model';
import {UsuariosService} from '../../services/usuarios.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CognitoExceptionsModule} from '../../shared/cognitoExceptions.module';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-activar-reset-password',
  templateUrl: './activar-reset-password.component.html',
  styleUrls: ['./activar-reset-password.component.css']
})
export class ActivarResetPasswordComponent implements OnInit {

  datosCambioClave: CambioClaveModel = new CambioClaveModel();
  verPassTemporal: boolean = false;
  verPassNueva: boolean = false;
  verPassNuevaConf: boolean = false;

  constructor(private usuariosService: UsuariosService,
              private spinner: NgxSpinnerService,
              private cognitoExceptions: CognitoExceptionsModule,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
  }
  
  onFormSubmit(): void {
    this.spinner.show();
    if (!this.datosCambioClave.username.includes('-')) {
      const username = this.datosCambioClave.username;
      this.datosCambioClave.username = `${username.substr(0, username.length - 1)}-${username.substr(username.length - 1)}`;
    }
    this.usuariosService.activarChangePassUsuario(this.datosCambioClave)
      .subscribe((result) => {
        this.spinner.hide();
        this.toastr.success('Usuarios activado con éxito');
        this.router.navigate(['/listado-sistemas/' + this.datosCambioClave.username.toUpperCase()]);
      }, error => {
        this.spinner.hide();
        this.toastr.warning(this.cognitoExceptions.getCognitoError(error.error));
      });
  }

}
