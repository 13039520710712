import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ProviderModel} from "../models/provider.model";

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(private http: HttpClient) {
  }


  obtenerProvider(providerName: string): Observable<ProviderModel> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return this.http.get<ProviderModel>(`${environment.API_DOMAINS.PROVIDERS}/provider/${providerName}`,
      {headers: headers});
  }

  guardarProvider(input: ProviderModel): Observable<any> {
    const body = JSON.stringify(input);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.PROVIDERS}/provider`, body, {headers: headers});
  }

  listarProviders(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.API_DOMAINS.PROVIDERS}/provider`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  listarProvidersAtributos(providerName: string): Observable<string[]> {
    return this.http
      .get<string[]>(`${environment.API_DOMAINS.PROVIDERS}/provider/atributos/${providerName}`)
      .pipe(
        map((result: string[]) => {
          return result;
        })
      );
  }

}
