<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3 *ngIf="!crearEmpresaFlg">Crear Holding</h3>
            <h3 *ngIf="crearEmpresaFlg">Editar Holding</h3>
          </div>
          <div class="col-lg-4 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/crud-clientes']">Clientes</a></li>
                <li class="breadcrumb-item active"><a>Holdings</a></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-12 mx-auto padding-5">
            <form #formCrearHolding="ngForm" (ngSubmit)="guardarHolding(formCrearHolding);" novalidate>
              <div class="row">
                <!--<div class="form-group col-xl-4 col-12">
                  <label> RUT Holding: </label>
                  <input type="text" class="form-control" placeholder="Ingrese" id="rutHoldingVal"
                         name="rutHoldingVal" #rutHoldingVal="ngModel" rutvalidator [(ngModel)]="rutHolding" required
                         [readonly]="crearEmpresaFlg"
                         [ngClass]="{ 'is-invalid': (formCrearHolding.submitted || rutHoldingVal.touched) && rutHoldingVal.invalid }">
                  <span
                    *ngIf="rutHoldingVal.invalid && (formCrearHolding.submitted || rutHoldingVal.dirty || rutHoldingVal.touched)"
                    class="obligatorio-texto">
												<span
                          *ngIf="formCrearHolding.hasError('required', 'rutHoldingVal')">Debe ingresar un RUT.</span>
                                            <span *ngIf="formCrearHolding.hasError('rutvalidator', 'rutHoldingVal')">El RUT ingresado es incorrecto.</span>
                                            </span>
                </div>-->
                <div class="form-group col-xl-12 col-12">
                  <label> Razón Social Holding: </label>
                  <input type="text" class="form-control" placeholder="Ingrese Raz&oacute;n Social"
                         id="razonSocialValHolding" name="razonSocialValHolding" #razonSocialValHolding="ngModel"
                         [(ngModel)]="modelHolding.razonSocial" autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearHolding.submitted || razonSocialValHolding.touched) && razonSocialValHolding.invalid }">
                  <span
                    *ngIf="razonSocialValHolding.invalid && (formCrearHolding.submitted || razonSocialValHolding.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearHolding.hasError('required', 'razonSocialValHolding')">Debe ingresar la razon social.</span>
                                            </span>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-8 col-12">
                  <label> Domicilio Legal: </label>
                  <input type="text" class="form-control" placeholder="Ingrese Domicilio Legal"
                         id="domicilioLegalValHolding" name="domicilioLegalValHolding"
                         #domicilioLegalValHolding="ngModel" [(ngModel)]="modelHolding.domicilio"
                         autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearHolding.submitted || domicilioLegalValHolding.touched) && domicilioLegalValHolding.invalid }">
                  <span
                    *ngIf="domicilioLegalValHolding.invalid && (formCrearHolding.submitted || domicilioLegalValHolding.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearHolding.hasError('required', 'domicilioLegalValHolding')">Debe ingresar Domicilio Legal.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-4 col-12">
                  <label> Teléfono: </label>
                  <input type="text" class="form-control" placeholder="Ingrese el n&uacute;mero de tel&eacute;fono"
                         id="telefonoValHolding" name="telefonoValHolding" #telefonoValHolding="ngModel"
                         [(ngModel)]="modelHolding.telefono" autocomplete="off" required onlyNumber
                         [ngClass]="{ 'is-invalid': (formCrearHolding.submitted || telefonoValHolding.touched) && telefonoValHolding.invalid }">
                  <span
                    *ngIf="telefonoValHolding.invalid && (formCrearHolding.submitted || telefonoValHolding.touched)"
                    class="obligatorio-texto">
												<span *ngIf="formCrearHolding.hasError('required', 'telefonoValHolding')">Debe ingresar n&uacute;mero de tel&eacute;fono.</span>
                                            </span>
                </div>
                <div class="form-group col-xl-12 col-12">
                  <label> Giro: </label>
                  <input type="text" class="form-control" placeholder="Ingrese el nombre del Giro del Holding"
                         id="giroValHolding" name="giroValHolding" #giroValHolding="ngModel"
                         [(ngModel)]="modelHolding.giro" autocomplete="off" required
                         [ngClass]="{ 'is-invalid': (formCrearHolding.submitted || giroValHolding.touched) && giroValHolding.invalid }">
                  <span *ngIf="giroValHolding.invalid && (formCrearHolding.submitted || giroValHolding.touched)"
                        class="obligatorio-texto">
												<span *ngIf="formCrearHolding.hasError('required', 'giroValHolding')">Debe ingresar nombre del Giro del Holding.</span>
                                            </span>
                </div>
              </div>
              <div class="row-fluid text-right">
                <!--
                <a href="#" class="btn btn-primary text-right float-right" (click)="guardarHolding()" > Guardar Holding </a>
            -->
                <button class="btn btn-primary text-right float-right padding-5" type="button" *ngIf="crearEmpresaFlg"
                        (click)="showFormEmpresa(mdlFormEmpresa)">Agregar Empresa
                </button>
                <button class="btn btn-primary text-right float-right" type="submit">Guardar Holding
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-8 col-lg-10 col-12 mx-auto padding-5">
            <div class="row row-fluid mx-auto">
              <ngx-datatable
                class="bootstrap table table-hover table-striped"
                [rows]="listadoEmpresas"
                [columns]="columns"
                [groupExpansionDefault]="false" [rowHeight]="'auto'"
                [sortType]="'multi'" [limit]="10" [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="50" [messages]="messages"
                *ngIf="crearEmpresaFlg">
              </ngx-datatable>
              <ng-template #rutTemplate let-row="row" let-value="value" let-rowIndex="rowIndex"
                           ngx-datatable-cell-template>
                <div>
                  {{row.rut}}-{{row.dv}}
                </div>
              </ng-template>
              <ng-template #optionTemplate let-row="row" let-value="value" let-rowIndex="rowIndex"
                           ngx-datatable-cell-template>
                <div>
                  <a href="javascript:;" (click)="showformEmpresaEdit(mdlFormEmpresa, row)">
                    <img src="assets/img/editar.png" data-toggle="tooltip" title="Editar"
                         data-placement="bottom">
                  </a>
                  <a href="javascript:;" (click)="showDelete(refModalConfirmDeleteEmpresa, row)">
                    <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Editar"
                         data-placement="bottom">
                    <span class="oi oi-pencil"></span>
                  </a>
                </div>
              </ng-template>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <div class="row-fluid text-right">
              <a href="javascript:;" class="btn btn-gris float-right" [routerLink]="['/crud-clientes']">Volver a
                Listado Clientes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #mdlConfirmarAgregarEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Confirmación</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Desea agregar empresas?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="irCrearEmpresa()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
              name="buttonCancelarEliminar" (click)="hideModalReturn()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #mdlFormEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form #formCrearEmpresa="ngForm" (ngSubmit)="guardaAsociacionEmpresaAlHolding(formCrearEmpresa);"
          autocomplete="off" novalidate>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="!editarEmpresaFlg">Crear Empresa</h5>
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="editarEmpresaFlg">Editar Empresa</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div class="row">
          <div class="form-group col-xl-4 col-12">
            <label> RUT Empresa: </label>
            <input type="text" class="form-control" placeholder="Ingrese rut de la empresa"
                   [(ngModel)]="rutEmpresa" #rutVal="ngModel" id="rutVal" name="rutVal" rutvalidator
                   autocomplete="off" required [readonly]="editarEmpresaFlg"
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || rutVal.touched) && rutVal.invalid }">
            <span *ngIf="rutVal.invalid && (formCrearEmpresa.submitted || rutVal.touched)" class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'rutVal')">Debe ingresar un RUT.</span>
                                            <span *ngIf="formCrearEmpresa.hasError('rutvalidator', 'rutVal')">El RUT ingresado es incorrecto.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-8 col-12">
            <label> Raz&oacute;n Social Empresa: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombe de la raz&oacute;n social"
                   [(ngModel)]="empresaActual.razonSocial" #razonSocialValEmpresa="ngModel"
                   id="razonSocialValEmpresa" name="razonSocialValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || razonSocialValEmpresa.touched) && razonSocialValEmpresa.invalid }">
            <span
              *ngIf="razonSocialValEmpresa.invalid && (formCrearEmpresa.submitted || razonSocialValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'razonSocialValEmpresa')">Debe ingresar Razon Social.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-12 col-12">
            <label> Nombre de Fantasía: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombre de fantasia de la empresa"
                   [(ngModel)]="empresaActual.nombreFantasia" #nombreFantasiaValCrearEmpresa="ngModel"
                   id="nombreFantasiaValCrearEmpresa" name="nombreFantasiaValCrearEmpresa"
                   autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || nombreFantasiaValCrearEmpresa.touched) && nombreFantasiaValCrearEmpresa.invalid }">
            <span
              *ngIf="nombreFantasiaValCrearEmpresa.invalid && (formCrearEmpresa.submitted || nombreFantasiaValCrearEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'nombreFantasiaValCrearEmpresa')">Debe ingresar Nombre de Fantasia.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-8 col-12">
            <label> Casa Matriz: </label>
            <input type="text" class="form-control" placeholder="Ingrese direcci&oacute;n de la casa Matriz"
                   [(ngModel)]="empresaActual.direccion" #casaMatrizValEmpresa="ngModel"
                   id="casaMatrizValEmpresa" name="casaMatrizValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched) && casaMatrizValEmpresa.invalid }">
            <span
              *ngIf="casaMatrizValEmpresa.invalid && (formCrearEmpresa.submitted || casaMatrizValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'casaMatrizValEmpresa')">Debe ingresar Casa Matriz.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-4 col-12">
            <label> Teléfono: </label>
            <input type="text" class="form-control" placeholder="Ingrese n&uacute;mero de tel&eacute;fono"
                   [(ngModel)]="empresaActual.telefono" #telefonoValEmpresa="ngModel" id="telefonoValEmpresa"
                   name="telefonoValEmpresa" autocomplete="off" required onlyNumber
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || telefonoValEmpresa.touched) && telefonoValEmpresa.invalid }">
            <span
              *ngIf="telefonoValEmpresa.invalid && (formCrearEmpresa.submitted || telefonoValEmpresa.touched)"
              class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'telefonoValEmpresa')">Debe ingresar el n&uacute;mero de tel&eacute;fono.</span>
                                            </span>
          </div>
          <div class="form-group col-xl-12 col-12">
            <label> Giro: </label>
            <input type="text" class="form-control" placeholder="Ingrese nombre del Giro de la empresa"
                   [(ngModel)]="empresaActual.giro" #giroValEmpresa="ngModel" id="giroValEmpresa"
                   name="giroValEmpresa" autocomplete="off" required
                   [ngClass]="{ 'is-invalid': (formCrearEmpresa.submitted || giroValEmpresa.touched) && giroValEmpresa.invalid }">
            <span *ngIf="giroValEmpresa.invalid && (formCrearEmpresa.submitted || giroValEmpresa.touched)"
                  class="obligatorio-texto">
											<span *ngIf="formCrearEmpresa.hasError('required', 'giroValEmpresa')">Debe ingresar nombre del Giro de la empresa.</span>
                                            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Aceptar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
                name="buttonCancelarEliminar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalConfirmDeleteEmpresa let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Eliminar Empresa</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Está seguro de eliminar?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="eliminarEmpresa()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
              name="buttonCancelarEliminar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

