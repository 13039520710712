<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3>Mantenedor de Roles</h3>
          </div>
          <div class="col-lg-4 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item active"><a>Roles</a></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-10 col-lg-10 col-12 mx-auto padding-5">
            <div class="row">
              <div class="col-md-8 col-12 ml-3">
                <label>Selecciones un sistema para ver los roles:</label>
                <ng-select id="sistemas" name="sistemas" [items]="lstSistemas"
                           bindLabel="nombre" bindValue="appClientId" [(ngModel)]="sistemaSeleccionado"
                           (change)="selectSistema($event)">
                </ng-select>
              </div>
              <div class="col-md-2 col-12" style="margin-top: 35px;">
                <button class="btn btn-primary" *ngIf="flgShowView" (click)="modalFormRol(refModalRoles)">
                  Crear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8 col-lg-10 col-12 mx-auto padding-5">
        <div class="row row-fluid mx-auto">
          <ngx-datatable
            class="bootstrap table table-hover table-striped"
            [rows]="listadoRoles"
            [columns]="columns"
            [groupExpansionDefault]="false" [rowHeight]="'auto'"
            [sortType]="'multi'" [limit]="10" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [messages]="messages"
            *ngIf="flgShowView">
          </ngx-datatable>
          <ng-template #optionTemplate let-row="row" let-value="value" let-rowIndex="rowIndex"
                       ngx-datatable-cell-template>
            <div>
              <a href="javascript:;" (click)="showModalEditRol(refModalRoles, row)">
                <img src="assets/img/editar.png" data-toggle="tooltip" title="Editar"
                     data-placement="bottom">
              </a>
              <a href="javascript:;" (click)="showModalConfirmDeleteRol(refModalConfirmDeleteRoles, row)">
                <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Editar"
                     data-placement="bottom">
                <span class="oi oi-pencil"></span>
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #refModalRoles let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form [formGroup]="registerForm">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="!flagModalRoles">Crear Rol</h5>
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="flagModalRoles">Editar Rol</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div>
          <div class="row">
            <div class="form-group col-xl-12 col-12">
              <label> Nombre Rol: </label>
              <input type="text" class="form-control" placeholder="Ingrese"
                     formControlName="nombre"
                     id="nombreValModal" name="nombreValModal">
              <div *ngIf="submitted && f.nombre.errors">
                <div *ngIf="f.nombre.errors.required">Nombre es requerido</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="saveRol()">Guardar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
                name="buttonCancelar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalConfirmDeleteRoles let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Eliminar Rol</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Está seguro de eliminar?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="eliminarRol()">Aceptar</button>
      <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
              name="buttonCancelar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>
