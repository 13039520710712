import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {switchMap, tap} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

import {UsuarioEmpresasModel} from "../../models/usuario-empresas.model";

import {ClientesService} from "../../services/clientes.service";
import {UsuariosService} from "../../services/usuarios.service";
import {UsuariosModel} from "../../models/usuarios.model";

import {CognitoExceptionsModule} from "../../shared/cognitoExceptions.module";


@Component({
  selector: 'app-crud-usuario',
  templateUrl: './crud-usuario.component.html',
  styleUrls: ['./crud-usuario.component.css']
})
export class CrudUsuarioComponent implements OnInit {

  registerForm: FormGroup;
  listaEmpresas: any[];
  columns: any[];
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  itemsTipoCliente = [{value: 0, text: 'Holding'}, {value: 1, text: 'Empresa'}];
  itemsClientes: any[];
  listadoClientes: any[];
  selected = [];
  empresasSelected: any[];
  isEdit: boolean = false;
  pathParams: any;
  submitted = false;
  usuariosModel: UsuariosModel = new UsuariosModel();

  constructor(private formBuilder: FormBuilder,
              private clientesService: ClientesService,
              private usuariosService: UsuariosService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private cognitoExceptions: CognitoExceptionsModule,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.columns = [
      {name: 'Nombre', prop: 'nombre'},
      {name: 'Correo', prop: 'email'},
    ];

    this.registerForm = this.formBuilder.group({
      rut: ['', [Validators.required,]],
      nombre: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      tipoCliente: ['', [Validators.required]],
      holding: ['', [Validators.required]]
    });
    console.log('CrudUsuarioComponent: ngOnInit');
    this.route.params.subscribe(params => this.pathParams = params);
    if (this.pathParams.rut !== undefined) {
      if (isNaN(this.pathParams.rut)) {
        this.router.navigateByUrl('/crud-usuarios');
      } else {
        this.isEdit = true;
      }
    } else {
      this.isEdit = false;
    }

    this.spinner.show();
    this.listarClientes()
      .subscribe((result) => {
        this.spinner.hide();
        this.listadoClientes = result;
        if (this.isEdit)
          this.obtenerUsuarios();
      }, error => {
        console.log(error);
        this.spinner.hide();
      })
  }

  get f() {
    return this.registerForm.controls;
  }

  onSelect($event) {
    this.selected = $event.selected;
    console.log(this.selected);
  }

  obtenerUsuarios() {
    this.spinner.show();
    var rutHold = null;
    var empresas = [];
    this.usuariosService.obtenerUsuario(this.pathParams.rut).pipe(
      tap((result: UsuarioEmpresasModel) => {
        console.log(result);
        this.registerForm.controls.rut.setValue(result.rut + '-' + result.dv);
        this.registerForm.controls.nombre.setValue(result.nombre);
        this.registerForm.controls.email.setValue(result.email);
        this.registerForm.controls.tipoCliente.setValue(result.holding.tipoCliente);
        this.registerForm.controls.tipoCliente.setValue(result.holding.tipoCliente);
        this.filtrarClientes({value: result.holding.tipoCliente});
        this.registerForm.controls.holding.setValue(result.holding.rut);
        rutHold = result.holding.rut;
        empresas = result.empresas;
      }),
      switchMap(() => this.clientesService.listarEmpresasHolding(rutHold))
    )
      .subscribe((result) => {
        console.log(result);
        this.listaEmpresas = result;
        empresas.map((item) => {
          var existe = this.listaEmpresas.find((value) => item.rut === value.rut);
          if (existe)
            this.selected.push(existe);
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.log(error);
      })
  }

  listarClientes() {
    return this.clientesService.listarClientes();
    /*.subscribe((result) => {
      this.listadoClientes = result;
    }, error => {
      console.log(error);
    })*/
  }

  buscarEmpresas($event) {
    if ($event !== undefined) {
      this.spinner.show();
      console.log(this.registerForm.controls.holding);
      this.clientesService.listarEmpresasHolding($event.rut)
        .subscribe((result) => {
          console.log(result);
          this.listaEmpresas = result;
          this.spinner.hide();
        }, error => {
          console.log(error);
          this.spinner.hide();
        })
    }

  }

  filtrarClientes($event) {
    this.registerForm.controls.holding.reset();
    this.itemsClientes = [];
    this.listaEmpresas = [];
    this.selected = [];
    if ($event !== undefined) {
      this.itemsClientes = this.listadoClientes.filter((item) => item.tipoCliente === $event.value);
    }
  }

  crearUsuarios() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.usuariosModel = {
      // username: this.registerForm.controls.username.value,
      rut: this.registerForm.controls.rut.value.split('-')[0],
      dv: this.registerForm.controls.rut.value.split('-')[1],
      nombre: this.registerForm.controls.nombre.value,
      email: this.registerForm.controls.email.value,
      rutHolding: this.registerForm.controls.holding.value,
      empresas: this.selected
    };
    this.spinner.show();

    if (!this.isEdit) {
      this.usuariosService.crearUsuarios(this.usuariosModel)
        .subscribe((result) => {
          console.log(result);
          this.spinner.hide();
          this.registerForm.reset();
          this.submitted = false;
          this.listaEmpresas = [];
          this.selected = [];
          this.toastr.success(result);
          this.router.navigate(['/crud-usuarios']);
        }, error => {
          this.spinner.hide();
          this.toastr.warning(this.cognitoExceptions.getCognitoError(error.error));
          console.log(error);
        })
    } else {
      console.log('Editar');
      this.usuariosService.editarUsuarios(this.usuariosModel)
        .subscribe((result) => {
          console.log(result);
          this.spinner.hide();
          this.registerForm.reset();
          this.submitted = false;
          this.listaEmpresas = [];
          this.selected = [];
          this.toastr.success(result);
          this.router.navigate(['/crud-usuarios']);
        }, error => {
          this.spinner.hide();
          this.toastr.warning(this.cognitoExceptions.getCognitoError(error.error));
          console.log(error);
        })
    }


  }
}
