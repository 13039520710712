import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";


import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from "ngx-toastr";


import {UsuariosModel} from '../../models/usuarios.model';
import {SistemasModel} from '../../models/sistemas.model';

import {LoggedInCallback} from '../../services/cognito.service';
import {UsuariosService} from '../../services/usuarios.service';
import {UserpoolService} from '../../services/userpool.service';
import {SistemasService} from '../../services/sistemas.service';
import {environment} from "../../../environments/environment";
import {LocalStorageService} from "angular-web-storage";

@Component({
  selector: 'app-asociar-usuarios-sistemas',
  templateUrl: './asociar-usuarios-sistemas.component.html',
  styleUrls: ['./asociar-usuarios-sistemas.component.css']
})
export class AsociarUsuariosSistemasComponent implements OnInit, LoggedInCallback {

  listadoUsuarios: UsuariosModel[] = [];
  selected = [];
  pathParams: any;
  nombreGrupo: string;
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total',
    selectedMessage: 'Seleccionados'
  };

  constructor(private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private usuariosServices: UsuariosService,
              private userpoolServices: UserpoolService,
              private sistemasServices: SistemasService,
              private localstorageService: LocalStorageService) {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.validarPermisos();
    } else {
      this.route.params.subscribe(params => this.pathParams = params);
      if (!isNaN(this.pathParams.appClientId)) {
        this.router.navigateByUrl('/crud-sistemas');
      } else {
        this.obtenerSistemaDetalle();
        this.listarUsuarios();
      }
    }

  }

  ngOnInit() {
    this.usuariosServices.isAuthenticated(this, null);
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolServices.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }

  }

  obtenerSistemaDetalle() {
    this.spinner.show();
    this.sistemasServices.obtenerSistema(this.pathParams.appClientId)
      .subscribe((result: SistemasModel) => {
        this.nombreGrupo = result.nombre;
        this.spinner.hide();
        this.obtenerUsuariosAsignados(result.nombre);
      }, error => {
        console.log(error);
        this.spinner.hide();
      })
  }

  /**
   * Lista los usuarios habilitados.
   */
  listarUsuarios() {
    this.usuariosServices.listarUsuarios().subscribe((result: UsuariosModel[]) => {
      this.listadoUsuarios = result;
    }, error => {
      console.log(error);
    })
  }

  obtenerUsuariosAsignados(nombreGrupo) {
    this.selected = [];
    this.spinner.show();
    const array = [];

    this.usuariosServices.obtenerUsuariosSistemas(this.pathParams.appClientId)
      .subscribe((result) => {
        console.log(result);
        result.map((item) => {
          const existe = this.listadoUsuarios.find((value) => value.rut === item.rut);
          array.push(existe);
        });
        this.selected = array;
        this.spinner.hide();
      }, error => {
        console.log(error);
      });
  }


  onSelect($event: any) {
    console.log($event);
    this.selected = $event.selected;
  }

  saveAsociacion() {
    this.spinner.show();
    const modelo = {
      appClientId: this.pathParams.appClientId,
      nombreGrupo: this.nombreGrupo,
      usuarios: this.selected
    };
    this.userpoolServices.guardarUsuariosGrupo(modelo)
      .subscribe((result) => {
        this.toastr.success(result);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.log(error);
      })
  }

}
