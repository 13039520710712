import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';


import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

import {ClientesService} from "../../services/clientes.service";
import {LoggedInCallback} from "../../services/cognito.service";
import {Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {LocalStorageService} from "angular-web-storage";
import {UsuariosService} from "../../services/usuarios.service";
import {UserpoolService} from "../../services/userpool.service";

@Component({
  selector: 'app-crud-clientes',
  templateUrl: './crud-clientes.component.html',
  styleUrls: ['./crud-clientes.component.css']
})
export class CrudClientesComponent implements OnInit, LoggedInCallback {

  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<any>;
  modalRef: NgbModalRef;
  typeItemCreate: any;
  listadoClientes: any[];
  listadoClientesTemp: any[];
  columns = [];
  clienteEliminar: any;
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  filtersModel: any = {
    tipoCliente: '',
    razonSocial: ''
  };

  lstTipoClientes = [
    {
      name: 'Holding',
      value: '0'
    }, {
      name: 'Empresa',
      value: '1'
    }
  ];


  constructor(private modalService: NgbModal,
              private router: Router,
              private clientesService: ClientesService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private usuariosService: UsuariosService,
              private userpoolService: UserpoolService,
              private localstorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      this.validarPermisos();
    }
    else {
      this.columns = [
        {name: 'Tipo Cliente', prop: 'tipoClienteDescripcion'},
        {name: 'Razón Social', prop: 'razonSocial'},
        {name: 'Domicilio Legal', prop: 'domicilio'},
        {name: 'Opciones', cellTemplate: this.optionTemplate}
      ];
      this.listarClientes();
    }
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }

  listarClientes() {
    this.spinner.show();
    this.clientesService.listarClientes()
      .subscribe((result) => {
        console.log(result);
        this.listadoClientes = result;
        this.listadoClientesTemp = result;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      })
  }

  modalTipoCliente(refModalTipoCliente) {
    this.modalRef = this.modalService.open(refModalTipoCliente);
  }

  hideModal() {
    this.modalRef.close();
  }

  nextCreate() {
    if (this.typeItemCreate === 'holding') {
      this.modalRef.close();
      this.router.navigate(['/crud-clientes-holding']);
    } else if (this.typeItemCreate === 'empresa') {
      this.modalRef.close();
      this.router.navigate(['/crud-clientes-empresa']);
    }
  }

  showModalConfirmDeleteCliente(refModalConfirmDeleteCliente, row) {
    this.clienteEliminar = row;
    this.modalRef = this.modalService.open(refModalConfirmDeleteCliente);
  }

  editCliente(row) {
    if (row.tipoCliente === 0) {
      this.router.navigate(['/crud-clientes-holding/' + row.rut]);
    } else if (row.tipoCliente === 1) {
      this.router.navigate(['/crud-clientes-empresa/' + row.rut]);
    }
  }

  eliminarCliente() {
    this.spinner.show();
    this.clienteEliminar.estado = 1;
    this.clientesService.editarCliente(this.clienteEliminar)
      .subscribe((result) => {
        this.spinner.hide();
        this.modalRef.close();
        this.clienteEliminar = null;
        this.listarClientes();
        this.toastr.success('Cliente eliminado con éxito');
      }, error => {
        this.spinner.hide();
        this.toastr.error('Ocurrió un error al intentar eliminar cliente.');
        console.log(error);
      })

  }

  filterList($event, name) {
    switch (name) {
      case 'TIPOCLIENTE': {
        if ($event !== undefined) {
          this.filtersModel.tipoCliente = $event.value;
          ;
          break;
        }
        else {
          this.filtersModel.tipoCliente = '';
        }
      }
      case 'RAZONSOCIAL': {
        if ($event !== undefined) {
          this.filtersModel.razonSocial = $event.target.value.toString().toLowerCase();
          break;
        } else {
          this.filtersModel.razonSocial = '';
        }
      }
    }

    if (this.filtersModel.razonSocial !== null ||
      this.filtersModel.tipoCliente !== null) {

      const temp = this.listadoClientesTemp.filter(d => {
        return (d.razonSocial.toLowerCase().indexOf(this.filtersModel.razonSocial) !== -1)
          && (d.tipoCliente.toString().indexOf(this.filtersModel.tipoCliente) !== -1);
      });

      this.listadoClientes = temp;
      if (this.listadoClientes.length == 0) {
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    }
    else {
      this.listadoClientes = this.listadoClientesTemp;
    }
  }
}
