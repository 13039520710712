import {EmpresaModel} from "./empresa.model";

export class UsuariosModel {

  // public username: string;
  public rut: number;
  public dv: string;
  public nombre: string;
  public email: string;
  public rutHolding: number;
  public empresas: EmpresaModel[];

  constructor(input?: any) {
    // this.username = input && input.username || null;
    this.rut = input && input.rut || null;
    this.dv = input && input.dv || null;
    this.nombre = input && input.nombre || null;
    this.email = input && input.email || null;
    this.rutHolding = input && input.rutHolding || null;
    this.empresas = input && input.empresas || [];

  }
}
