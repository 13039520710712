import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserpoolService {

  constructor(private http: HttpClient) {
  }

  listarAtributosUserpool(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.API_DOMAINS.USERPOOL}/atributos`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  obtenerUsuariosGrupo(nombreGrupo: string): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.API_DOMAINS.USERPOOL}/obtenerUsuariosGrupo?nombreGrupo=${nombreGrupo}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  guardarUsuariosGrupo(values: any): Observable<any> {
    const body = JSON.stringify(values);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USERPOOL}/guardarUsuariosGrupo`, body, {headers: headers});
  }

  validateToken(token: any): Observable<any> {
    const body = JSON.stringify(token);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USERPOOL}/authCognito`, body, {headers: headers});
  }

}
