import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserpoolService = /** @class */ (function () {
    function UserpoolService(http) {
        this.http = http;
    }
    UserpoolService.prototype.listarAtributosUserpool = function () {
        return this.http
            .get(environment.API_DOMAINS.USERPOOL + "/atributos")
            .pipe(map(function (result) {
            return result;
        }));
    };
    UserpoolService.prototype.obtenerUsuariosGrupo = function (nombreGrupo) {
        return this.http
            .get(environment.API_DOMAINS.USERPOOL + "/obtenerUsuariosGrupo?nombreGrupo=" + nombreGrupo)
            .pipe(map(function (result) {
            return result;
        }));
    };
    UserpoolService.prototype.guardarUsuariosGrupo = function (values) {
        var body = JSON.stringify(values);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USERPOOL + "/guardarUsuariosGrupo", body, { headers: headers });
    };
    UserpoolService.prototype.validateToken = function (token) {
        var body = JSON.stringify(token);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USERPOOL + "/authCognito", body, { headers: headers });
    };
    UserpoolService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserpoolService_Factory() { return new UserpoolService(i0.ɵɵinject(i1.HttpClient)); }, token: UserpoolService, providedIn: "root" });
    return UserpoolService;
}());
export { UserpoolService };
