/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./listado-sistemas.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./listado-sistemas.component";
import * as i4 from "../../services/sistemas.service";
import * as i5 from "@angular/router";
import * as i6 from "angular-web-storage";
import * as i7 from "ngx-spinner";
var styles_ListadoSistemasComponent = [i0.styles];
var RenderType_ListadoSistemasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListadoSistemasComponent, data: {} });
export { RenderType_ListadoSistemasComponent as RenderType_ListadoSistemasComponent };
function View_ListadoSistemasComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-lg-4 col-sm-6 col-12 text-center card card-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Gestor Capital Humano."], ["class", "mt-2"], ["src", "assets/img/logo-otic-2018.png"], ["style", "height:50px;width:auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mt-3 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "btn btn-primary"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ingresar"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.alias; _ck(_v, 4, 0, currVal_0); }); }
function View_ListadoSistemasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row p-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListadoSistemasComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ListadoSistemasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-xl-12 col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo OTIC Sofofa"], ["class", "float-left margin-top-10"], ["src", "assets/img/otic-sofofa-2018.png"], ["style", "height:50px;width:auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "row margin-top-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "col-xl-8 col-md-8 mx-auto col-12 card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-xl-8 mx-auto col-12 mx-auto text-center pt-3 pb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bienvenido"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Por favor seleccione una aplicaci\u00F3n:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListadoSistemasComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listadoSistemas; _ck(_v, 14, 0, currVal_0); }, null); }
export function View_ListadoSistemasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-listado-sistemas", [], null, null, null, View_ListadoSistemasComponent_0, RenderType_ListadoSistemasComponent)), i1.ɵdid(1, 114688, null, 0, i3.ListadoSistemasComponent, [i4.SistemasService, i5.Router, i6.LocalStorageService, i7.NgxSpinnerService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListadoSistemasComponentNgFactory = i1.ɵccf("app-listado-sistemas", i3.ListadoSistemasComponent, View_ListadoSistemasComponent_Host_0, {}, {}, []);
export { ListadoSistemasComponentNgFactory as ListadoSistemasComponentNgFactory };
