<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a [routerLink]="['/']" class="navbar-brand">
    <img src="assets/img/logo-otic-2018.png" alt="Sistema de Gestión de Capacitación OTIC SOFOFA" class="img-nav">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/']">Home</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/crud-sistemas']">Sistemas</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/crud-usuarios']">Usuarios</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active" *ngIf="false">
        <a class="nav-link" [routerLink]="['/crud-providers']">Provider</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/crud-roles']">Roles</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active" *ngIf="false">
        <a class="nav-link" [routerLink]="['/crud-permisos']">Permisos</a>
      </li>
      <li class="nav-item max110px" routerLinkActive="active" [hidden]="!validateRoles(['Administrador'])">
        <a class="nav-link" [routerLink]="['/crud-clientes']">Clientes</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav">
        <li class="nav-item">
          <div class="float-right ml-auto row">
            <div class="nav-item mt-3" href="#" role="button" aria-haspopup="true" aria-expanded="false">
              <img src="assets/img/profile.jpg" style="height:30px;width:auto;" class="rounded-circle">
              <span style="margin-top:-20px;">{{userData.nombre}}</span>
              <span *ngIf="!userData.realUser">
                            <a href="javascript:;" (click)="doLogout()">
                                <img src="assets/img/eliminar.png" data-toggle="tooltip" data-title="Cerrar Sesión"
                                     data-placement="bottom" style="height:30px;width:auto;">
                            </a>
                        </span>
            </div>
          </div>
        </li>
      </ul>
    </form>
  </div>
</nav>
