export class RolesModel {

  public idRol: number;
  public nombre: string;
  public appClientId: string;
  public eliminado: boolean;

  constructor(input?: any) {
    this.idRol = input && input.idRol || null;
    this.nombre = input && input.nombre || null;
    this.appClientId = input && input.appClientId || null;
    this.eliminado = input && input.eliminado || false;
  }
}
