var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { UsuarioLoginModel } from '../../models/usuario-login.model';
import { SistemasService } from '../../services/sistemas.service';
import { UsuariosService } from '../../services/usuarios.service';
import { LocalStorageService } from "angular-web-storage";
import { LoggedInCallback } from '../../services/cognito.service';
import { SwUpdate } from '@angular/service-worker';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, route, toastr, spinner, sanitizer, sistemasService, usuariosService, localStorageService, swUpdate) {
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.spinner = spinner;
        this.sanitizer = sanitizer;
        this.sistemasService = sistemasService;
        this.usuariosService = usuariosService;
        this.localStorageService = localStorageService;
        this.swUpdate = swUpdate;
        this.bloqueo = false;
        this.parametros = {
            supportedIdentity: [],
            redirectUri: null,
            responseType: null,
            scope: ''
        };
        this.showPass = false;
        this.usuarioLogin = new UsuarioLoginModel();
    }
    LoginComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        var _this = this;
        console.log('CrudEncuestaComponent: verificacion sesion');
        if (!isLoggedIn) {
            //this.domainPrefix = environment.DOMAIN_PREFIX;
            if (this.urlParams.has('client_id') && this.urlParams.has('redirect_uri') && this.urlParams.has('response_type')) {
                this.usuarioLogin.client_id = this.urlParams.get('client_id');
                this.parametros.redirectUri = this.urlParams.get('redirect_uri');
                this.parametros.responseType = this.urlParams.get('response_type');
                this.sistemasService.obtenerSistema(this.usuarioLogin.client_id).subscribe(function (next) {
                    _this.usuarioLogin.auth_flow = _this.parametros.responseType == 'token' ? 'implicit' : 'code';
                    if (((_this.parametros.responseType == 'token' && _this.usuarioLogin.auth_flow.includes('implicit'))
                    /*|| (this.parametros.responseType == 'code' && this.usuarioLogin.auth_flow.includes('code'))*/ )
                        && next.callbackUrl && next.callbackUrl.includes(_this.parametros.redirectUri)) {
                        if (next.supportedIdentityProviders && next.supportedIdentityProviders.length > 0) {
                            _this.parametros.supportedIdentity = next.supportedIdentityProviders;
                        }
                        if (next.allowedOAuthScopes && next.allowedOAuthScopes.length > 0) {
                            _this.parametros.scope = next.allowedOAuthScopes.join(' ');
                        }
                        // this.spinner.hide();
                    }
                    else {
                        console.log('La URL de callback no esta configurada para el cliente o no la entrega no coincide con las entregadas.');
                        _this.redirectError('Parámetros entregados no son válidos.');
                    }
                    _this.spinner.hide();
                }, function (error) {
                    _this.spinner.hide();
                    _this.redirectError(error);
                });
            }
            else {
                this.spinner.hide();
                this.redirectError('Parámetros entregados no son válidos.');
            }
        }
        else {
            this.spinner.hide();
            window.location.href = this.urlParams.get('redirect_uri') + "#access_token=" + this.localStorageService.get(this.urlParams.get('client_id') + ".idToken") + "&id_token=" + this.localStorageService.get(this.urlParams.get('client_id') + ".accessToken") + "&rt=" + this.localStorageService.get(this.urlParams.get('client_id') + ".refreshToken");
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        this.spinner.show();
        this.urlParams = new URLSearchParams(this.router.url
            .replace('/login?', '')
            .replace('/oauth2/authorize?', ''));
        if (this.urlParams.has('client_id') && this.urlParams.has('redirect_uri') && this.urlParams.has('response_type')) {
            this.usuariosService.isAuthenticated(this, this.urlParams.get('client_id'));
            if (this.swUpdate.isEnabled) {
                this.swUpdate.available.subscribe(function () { return window.location.reload(); });
            }
        }
        else {
            this.spinner.hide();
            this.redirectError('Parámetros entregados no son válidos.');
        }
    };
    /**
     * Redirecciona a la pagina de error cuando corresponde.
     *
     * @param msj
     */
    LoginComponent.prototype.redirectError = function (msj) {
        this.spinner.hide();
        if (this.parametros.redirectUri) {
            // window.location.href = `${this.parametros.redirectUri}#error=${msj}&client_id=${this.usuarioLogin.client_id}`;
        }
        else {
            this.router.navigate(['/error']);
            this.toastr.error(msj, '');
        }
    };
    /**
     * Funcion obtiene el link respectivo para login con un provider.
     *
     * @param provider
     */
    LoginComponent.prototype.obtenerLinkProvider = function (provider) {
        return "https://usuarios-catena.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=" + provider
            + ("&redirect_uri=" + this.parametros.redirectUri + "&response_type=" + this.parametros.responseType + "&client_id=" + this.usuarioLogin.client_id)
            + ("&scope=" + this.parametros.scope);
    };
    LoginComponent.prototype.onLoginSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hoy, username;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        hoy = new Date();
                        if (!this.usuarioLogin.username.includes('-')) {
                            username = this.usuarioLogin.username;
                            this.usuarioLogin.username = username.substr(0, username.length - 1) + "-" + username.substr(username.length - 1);
                        }
                        return [4 /*yield*/, this.usuariosService.loginUsuario(this.usuarioLogin)
                                .subscribe(function (usuario) {
                                console.log('usuario: ', usuario);
                                console.log('userData: ', usuario.userData);
                                // if ( usuario.bloqueo !== null) {
                                //   if ( usuario.bloqueo.length > 0 ) {
                                //     this.spinner.hide();
                                //     this.mensajeBloqueo = usuario.bloqueo[0].mensaje;
                                //     this.imagenBloqueo = this.sanitizer.bypassSecurityTrustResourceUrl(usuario.bloqueo[0].img);
                                //     this.bloqueo = true;
                                //   } else {
                                //     this.spinner.hide();
                                //     this.toastr.error('Error bloqueo!');
                                //   }
                                // } else {
                                //   this.spinner.hide();
                                //   const user_data = JSON.stringify(usuario.userData);
                                //   this.setCookies(usuario.idToken, usuario.accessToken, this.usuarioLogin.username, this.usuarioLogin.client_id, usuario.userData, usuario.refreshToken);
                                //   window.location.href = `${this.parametros.redirectUri}#access_token=${usuario.accessToken}&id_token=${usuario.idToken}&ud=${btoa(user_data)}&rt=${usuario.refreshToken}`;
                                // }
                                _this.spinner.hide();
                                var user_data = JSON.stringify(usuario.userData);
                                _this.setCookies(usuario.idToken, usuario.accessToken, _this.usuarioLogin.username, _this.usuarioLogin.client_id, usuario.userData, usuario.refreshToken);
                                window.location.href = _this.parametros.redirectUri + "#access_token=" + usuario.accessToken + "&id_token=" + usuario.idToken + "&ud=" + btoa(user_data) + "&rt=" + usuario.refreshToken;
                            }, function (error) {
                                _this.spinner.hide();
                                console.log('LoginComponent: error al iniciar sesion ');
                                console.log(error);
                                if (error.error === 'PreAuthentication failed with error does not have permissions.') {
                                    _this.toastr.error('No tiene permisos para ingresar a este sistema.');
                                }
                                else if (error.status === 409) {
                                    _this.router.navigate(['/activar-password-reset']);
                                }
                                else {
                                    _this.toastr.error('RUT o contraseña incorrecta');
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.setCookies = function (idToken, accessToken, username, appClientId, infoUser, refreshToken) {
        var keyPrefix = "CognitoIdentityServiceProvider." + appClientId;
        var idTokenKey = keyPrefix + "." + username + ".idToken";
        var accessTokenKey = keyPrefix + "." + username + ".accessToken";
        var refreshTokenKey = keyPrefix + "." + username + ".refreshToken";
        var clockDriftKey = keyPrefix + "." + username + ".clockDrift";
        var lastUserKey = keyPrefix + ".LastAuthUser";
        var userDataKey = appClientId + ".userData";
        this.localStorageService.set(appClientId + ".idToken", idToken);
        this.localStorageService.set(appClientId + ".accessToken", accessToken);
        this.localStorageService.set(userDataKey, {
            rut: infoUser.rut + '-' + infoUser.dv,
            nombre: infoUser.nombre,
            empresas: infoUser.empresas,
            rutEmpresa: null,
            roles: infoUser.roles,
            email: infoUser.email,
            rutHolding: infoUser.rutHolding,
            now: +new Date,
            realUser: null
        }, 0, 's');
        // this.localStorageService.setItem(refreshTokenKey, this.signInUserSession.getRefreshToken().getToken());
        // this.localStorageService.set(clockDriftKey, `${this.signInUserSession.getClockDrift()}`);
        localStorage.setItem(idTokenKey, idToken);
        localStorage.setItem(accessTokenKey, accessToken);
        localStorage.setItem(refreshTokenKey, refreshToken);
        localStorage.setItem(lastUserKey, username);
    };
    LoginComponent.prototype.sendReset = function () {
        this.router.navigate(['/reset-clave'], {
            queryParams: {
                redirect_uri: this.urlParams.get('redirect_uri'),
                response_type: 'token',
                client_id: this.urlParams.get('client_id')
            }
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
