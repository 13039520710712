import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ClientesService } from "../../services/clientes.service";
import { UsuariosService } from "../../services/usuarios.service";
import { UsuariosModel } from "../../models/usuarios.model";
import { CognitoExceptionsModule } from "../../shared/cognitoExceptions.module";
var CrudUsuarioComponent = /** @class */ (function () {
    function CrudUsuarioComponent(formBuilder, clientesService, usuariosService, router, route, spinner, cognitoExceptions, toastr) {
        this.formBuilder = formBuilder;
        this.clientesService = clientesService;
        this.usuariosService = usuariosService;
        this.router = router;
        this.route = route;
        this.spinner = spinner;
        this.cognitoExceptions = cognitoExceptions;
        this.toastr = toastr;
        this.messages = {
            emptyMessage: "No hay registros disponibles",
            totalMessage: 'Total'
        };
        this.itemsTipoCliente = [{ value: 0, text: 'Holding' }, { value: 1, text: 'Empresa' }];
        this.selected = [];
        this.isEdit = false;
        this.submitted = false;
        this.usuariosModel = new UsuariosModel();
    }
    CrudUsuarioComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columns = [
            { name: 'Nombre', prop: 'nombre' },
            { name: 'Correo', prop: 'email' },
        ];
        this.registerForm = this.formBuilder.group({
            rut: ['', [Validators.required,]],
            nombre: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            tipoCliente: ['', [Validators.required]],
            holding: ['', [Validators.required]]
        });
        console.log('CrudUsuarioComponent: ngOnInit');
        this.route.params.subscribe(function (params) { return _this.pathParams = params; });
        if (this.pathParams.rut !== undefined) {
            if (isNaN(this.pathParams.rut)) {
                this.router.navigateByUrl('/crud-usuarios');
            }
            else {
                this.isEdit = true;
            }
        }
        else {
            this.isEdit = false;
        }
        this.spinner.show();
        this.listarClientes()
            .subscribe(function (result) {
            _this.spinner.hide();
            _this.listadoClientes = result;
            if (_this.isEdit)
                _this.obtenerUsuarios();
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
        });
    };
    Object.defineProperty(CrudUsuarioComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CrudUsuarioComponent.prototype.onSelect = function ($event) {
        this.selected = $event.selected;
        console.log(this.selected);
    };
    CrudUsuarioComponent.prototype.obtenerUsuarios = function () {
        var _this = this;
        this.spinner.show();
        var rutHold = null;
        var empresas = [];
        this.usuariosService.obtenerUsuario(this.pathParams.rut).pipe(tap(function (result) {
            console.log(result);
            _this.registerForm.controls.rut.setValue(result.rut + '-' + result.dv);
            _this.registerForm.controls.nombre.setValue(result.nombre);
            _this.registerForm.controls.email.setValue(result.email);
            _this.registerForm.controls.tipoCliente.setValue(result.holding.tipoCliente);
            _this.registerForm.controls.tipoCliente.setValue(result.holding.tipoCliente);
            _this.filtrarClientes({ value: result.holding.tipoCliente });
            _this.registerForm.controls.holding.setValue(result.holding.rut);
            rutHold = result.holding.rut;
            empresas = result.empresas;
        }), switchMap(function () { return _this.clientesService.listarEmpresasHolding(rutHold); }))
            .subscribe(function (result) {
            console.log(result);
            _this.listaEmpresas = result;
            empresas.map(function (item) {
                var existe = _this.listaEmpresas.find(function (value) { return item.rut === value.rut; });
                if (existe)
                    _this.selected.push(existe);
            });
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
            console.log(error);
        });
    };
    CrudUsuarioComponent.prototype.listarClientes = function () {
        return this.clientesService.listarClientes();
        /*.subscribe((result) => {
          this.listadoClientes = result;
        }, error => {
          console.log(error);
        })*/
    };
    CrudUsuarioComponent.prototype.buscarEmpresas = function ($event) {
        var _this = this;
        if ($event !== undefined) {
            this.spinner.show();
            console.log(this.registerForm.controls.holding);
            this.clientesService.listarEmpresasHolding($event.rut)
                .subscribe(function (result) {
                console.log(result);
                _this.listaEmpresas = result;
                _this.spinner.hide();
            }, function (error) {
                console.log(error);
                _this.spinner.hide();
            });
        }
    };
    CrudUsuarioComponent.prototype.filtrarClientes = function ($event) {
        this.registerForm.controls.holding.reset();
        this.itemsClientes = [];
        this.listaEmpresas = [];
        this.selected = [];
        if ($event !== undefined) {
            this.itemsClientes = this.listadoClientes.filter(function (item) { return item.tipoCliente === $event.value; });
        }
    };
    CrudUsuarioComponent.prototype.crearUsuarios = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.usuariosModel = {
            // username: this.registerForm.controls.username.value,
            rut: this.registerForm.controls.rut.value.split('-')[0],
            dv: this.registerForm.controls.rut.value.split('-')[1],
            nombre: this.registerForm.controls.nombre.value,
            email: this.registerForm.controls.email.value,
            rutHolding: this.registerForm.controls.holding.value,
            empresas: this.selected
        };
        this.spinner.show();
        if (!this.isEdit) {
            this.usuariosService.crearUsuarios(this.usuariosModel)
                .subscribe(function (result) {
                console.log(result);
                _this.spinner.hide();
                _this.registerForm.reset();
                _this.submitted = false;
                _this.listaEmpresas = [];
                _this.selected = [];
                _this.toastr.success(result);
                _this.router.navigate(['/crud-usuarios']);
            }, function (error) {
                _this.spinner.hide();
                _this.toastr.warning(_this.cognitoExceptions.getCognitoError(error.error));
                console.log(error);
            });
        }
        else {
            console.log('Editar');
            this.usuariosService.editarUsuarios(this.usuariosModel)
                .subscribe(function (result) {
                console.log(result);
                _this.spinner.hide();
                _this.registerForm.reset();
                _this.submitted = false;
                _this.listaEmpresas = [];
                _this.selected = [];
                _this.toastr.success(result);
                _this.router.navigate(['/crud-usuarios']);
            }, function (error) {
                _this.spinner.hide();
                _this.toastr.warning(_this.cognitoExceptions.getCognitoError(error.error));
                console.log(error);
            });
        }
    };
    return CrudUsuarioComponent;
}());
export { CrudUsuarioComponent };
