//Angular
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

//Utils
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from "ngx-toastr";
import * as cloneDeep from 'lodash/cloneDeep';

//Services
import {SistemasService} from '../../services/sistemas.service';
import {RolesService} from '../../services/roles.service';
import {UsuariosService} from "../../services/usuarios.service";
import {UserpoolService} from "../../services/userpool.service";
import {LocalStorageService} from "angular-web-storage";
import {LoggedInCallback} from '../../services/cognito.service';

//models
import {SistemasModel} from '../../models/sistemas.model';
import {RolesModel} from '../../models/roles.model';

import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-crud-roles',
  templateUrl: './crud-roles.component.html',
  styleUrls: ['./crud-roles.component.css']
})
export class CrudRolesComponent implements OnInit, LoggedInCallback {
  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<any>;

  lstSistemas: SistemasModel[] = [];
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  columns = [];
  listadoRoles: RolesModel[] = [];
  flgShowView = false;
  flagModalRoles = false;
  modalRef: NgbModalRef;
  registerForm: FormGroup;
  submitted = false;
  rolModel: RolesModel = new RolesModel();
  rolModelEliminar: RolesModel = new RolesModel();
  sistemaSeleccionado: any;

  constructor(private sistemaService: SistemasService,
              private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private rolesService: RolesService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private usuariosService: UsuariosService,
              private userpoolService: UserpoolService,
              private localstorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      this.validarPermisos();
    }
    else {
      this.columns = [
        {name: 'ID', prop: 'idRol'},
        {name: 'Nombre Rol', prop: 'nombre'},
        {name: 'Opciones', cellTemplate: this.optionTemplate},

      ];
      this.registerForm = this.formBuilder.group({
        nombre: ['', Validators.required]
      });
      this.listarSistemas();
    }
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired' ) {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  listarSistemas() {
    this.spinner.show();
    this.sistemaService.listarSistemas()
      .subscribe((result: SistemasModel[]) => {
        this.lstSistemas = result;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  listarRoles(appClientId: string) {
    this.spinner.show();
    this.rolesService.listarRoles(appClientId)
      .subscribe((result: RolesModel[]) => {
        this.listadoRoles = result;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      })

  }

  selectSistema($event) {
    this.flgShowView = true;
    this.listarRoles(this.sistemaSeleccionado);
  }

  modalFormRol(refModal) {
    this.flagModalRoles = false;
    this.submitted = false;
    this.rolModel = new RolesModel();
    this.registerForm.reset();
    this.modalRef = this.modalService.open(refModal);
  }

  hideModal() {
    this.modalRef.close();
  }

  saveRol() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.show();
    this.rolModel.nombre = this.registerForm.controls.nombre.value;
    if (!this.flagModalRoles) {
      this.rolModel.appClientId = this.sistemaSeleccionado;
      this.rolesService.crearRol(this.rolModel)
        .subscribe((result) => {
          this.modalRef.close();
          this.listarRoles(this.sistemaSeleccionado);
          this.toastr.success(result);
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            console.log(error);
            this.toastr.warning(error.error);
          }

        })
    } else {
      this.rolesService.editarRol(this.rolModel)
        .subscribe((result) => {
          console.log(result);
          this.modalRef.close();
          this.listarRoles(this.sistemaSeleccionado);
          this.toastr.success(result);
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            console.log(error);
            this.toastr.warning(error.error);
          }
        })
    }

  }

  showModalEditRol(refModalRoles, row: RolesModel) {
    this.flagModalRoles = true;
    this.rolModel = new RolesModel();
    this.rolModel = cloneDeep(row);
    this.modalRef = this.modalService.open(refModalRoles);
    this.registerForm.controls.nombre.setValue(row.nombre);
  }

  showModalConfirmDeleteRol(refModalConfirmDeleteRoles, row) {
    this.rolModelEliminar = cloneDeep(row);
    this.modalRef = this.modalService.open(refModalConfirmDeleteRoles);
  }

  eliminarRol() {
    this.spinner.show();
    this.rolesService.eliminarRol(this.rolModelEliminar)
      .subscribe((result) => {
        console.log(result);
        this.modalRef.close();
        this.listarRoles(this.sistemaSeleccionado);
        this.spinner.hide();
        this.rolModelEliminar = new RolesModel();
        this.toastr.success(result);
      }, error => {
        this.spinner.hide();
        console.log(error);
      })

  }
}
