import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

import {EmpresaModel} from "../../models/empresa.model";

//Services
import {ClientesService} from "../../services/clientes.service";

@Component({
  selector: 'app-crud-clientes-empresa',
  templateUrl: './crud-clientes-empresa.component.html',
  styleUrls: ['./crud-clientes-empresa.component.css']
})
export class CrudClientesEmpresaComponent implements OnInit {
  flagEditar: boolean = false;
  empresaActual: EmpresaModel = new EmpresaModel();
  rutEmpresa: any;
  pathParams: any;

  constructor(private clientesService: ClientesService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    console.log('CrudClientesEmpresaComponent: ngOnInit');
    this.route.params.subscribe(params => this.pathParams = params);
    if (this.pathParams.rutEmpresa !== undefined) {
      if (isNaN(this.pathParams.rutEmpresa)) {
        this.router.navigateByUrl('/crud-clientes');
      } else {
        this.flagEditar = true;
        this.obtenerEmpresa();
      }
    } else {
      this.flagEditar = false;
    }
  }

  obtenerEmpresa() {
    this.spinner.show();
    this.clientesService.obtenerEmpresa(this.pathParams.rutEmpresa)
      .subscribe((result) => {
        this.rutEmpresa = result.rut + '-' + result.dv;
        this.empresaActual = result;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      })
  }

  guardaEmpresa(formCrearEmpresa) {
    if (!formCrearEmpresa.valid) {
      return;
    }

    this.spinner.show();
    const rutCompleto = this.rutEmpresa.split('-');
    this.empresaActual.rut = parseInt(rutCompleto[0], 0);
    this.empresaActual.dv = rutCompleto[1];
    this.empresaActual.tipoCliente = 1;
    if (!this.flagEditar) {
      this.clientesService.crearCliente(this.empresaActual)
        .subscribe((result) => {
          this.spinner.hide();
          this.toastr.success('Empresa creada con éxito');
        }, error => {
          this.spinner.hide();
          console.log(error);
          this.toastr.warning(error.error);
        })
    } else if (this.flagEditar) {
      this.clientesService.editarCliente(this.empresaActual)
        .subscribe((result) => {
          this.spinner.hide();
          this.toastr.success('Empresa actualizada con éxito');
        }, error => {
          this.spinner.hide();
          console.log(error);
          this.toastr.warning(error.error);
        })
    }


  }
}
