// Angular
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
// Util
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
// Models
import { UsuariosModel } from "../../models/usuarios.model";
//Service
import { UsuariosService } from "../../services/usuarios.service";
import { UserpoolService } from "../../services/userpool.service";
import { LocalStorageService } from "angular-web-storage";
//Modules
import { CognitoExceptionsModule } from "../../shared/cognitoExceptions.module";
import { environment } from "../../../environments/environment";
var CrudUsuariosComponent = /** @class */ (function () {
    function CrudUsuariosComponent(modalService, usuariosService, toastr, cognitoExceptions, formBuilder, spinner, router, userpoolService, localstorageService) {
        this.modalService = modalService;
        this.usuariosService = usuariosService;
        this.toastr = toastr;
        this.cognitoExceptions = cognitoExceptions;
        this.formBuilder = formBuilder;
        this.spinner = spinner;
        this.router = router;
        this.userpoolService = userpoolService;
        this.localstorageService = localstorageService;
        this.messages = {
            emptyMessage: "No hay registros disponibles",
            totalMessage: 'Total'
        };
        this.listaUsuarios = [];
        this.listaUsuariosTemp = [];
        this.usuariosModel = new UsuariosModel();
        this.usuariosModelEliminar = new UsuariosModel();
        this.columns = [];
        this.submitted = false;
        this.flagModalUsuario = false;
        this.filtersModel = {
            username: '',
            nombre: '',
            correo: ''
        };
    }
    CrudUsuariosComponent.prototype.ngOnInit = function () {
        this.usuariosService.isAuthenticated(this, null);
    };
    CrudUsuariosComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        isLoggedIn = true;
        if (!isLoggedIn) {
            this.validarPermisos();
        }
        else {
            this.columns = [
                { name: 'Rut', cellTemplate: this.templateRut },
                { name: 'Nombre', prop: 'nombre' },
                { name: 'Correo', prop: 'email' },
                { name: 'Opciones', cellTemplate: this.optionTemplate }
            ];
            this.listarUsuarios();
            this.registerForm = this.formBuilder.group({
                rut: ['', [Validators.required,]],
                nombre: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required]]
            });
        }
    };
    CrudUsuariosComponent.prototype.validarPermisos = function () {
        var _this = this;
        console.log(this.localstorageService.get('accessToken'));
        var access_token = this.localstorageService.get('accessToken');
        var id_token = this.localstorageService.get('idToken');
        if (access_token !== null && id_token !== null) {
            this.userpoolService.validateToken({ access_token: access_token, id_token: id_token })
                .subscribe(function (result) {
                console.log(result);
            }, function (error1) {
                if (error1.error === 'token denied') {
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
                else if (error1.error === 'Token is expired') {
                    _this.toastr.info('La sessión ha caducado.');
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
                else {
                    _this.router.navigate(['/login'], {
                        queryParams: {
                            redirect_uri: environment.URL_AUTH,
                            response_type: 'token',
                            client_id: environment.CLIENT_ID
                        }
                    });
                }
            });
        }
        else {
            this.router.navigate(['/login'], {
                queryParams: {
                    redirect_uri: environment.URL_AUTH,
                    response_type: 'token',
                    client_id: environment.CLIENT_ID
                }
            });
        }
    };
    Object.defineProperty(CrudUsuariosComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CrudUsuariosComponent.prototype.listarUsuarios = function () {
        var _this = this;
        this.spinner.show();
        this.usuariosService.listarUsuarios()
            .subscribe(function (result) {
            _this.listaUsuarios = result;
            _this.listaUsuariosTemp = result;
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
            console.log(error);
        });
    };
    CrudUsuariosComponent.prototype.hideModal = function () {
        this.modalRef.close();
    };
    /**
     * Muestra el modal para ingresar los datos del nuevo usuario.
     * @param refModal
     */
    CrudUsuariosComponent.prototype.showModalCrearUsuario = function (refModal) {
        this.submitted = false;
        this.flagModalUsuario = false;
        this.registerForm.reset();
        this.usuariosModel = new UsuariosModel();
        this.registerForm.controls.password.enable();
        //this.registerForm.controls.username.enable();
        this.modalRef = this.modalService.open(refModal);
    };
    CrudUsuariosComponent.prototype.showModalEditUsuario = function (refModalSistema, row) {
        this.router.navigate(['/crud-usuario/' + row.rut]);
    };
    CrudUsuariosComponent.prototype.showModalConfirmDeleteUsuario = function (refModalConfirmDeleteUsuario, row) {
        this.modalRef = this.modalService.open(refModalConfirmDeleteUsuario);
        this.usuariosModelEliminar = row;
    };
    CrudUsuariosComponent.prototype.eliminarUsuario = function () {
        var _this = this;
        this.spinner.show();
        this.usuariosService.eliminarSistema(this.usuariosModelEliminar)
            .subscribe(function (result) {
            _this.spinner.hide();
            _this.modalRef.close();
            _this.toastr.success(result);
            _this.listarUsuarios();
        }, function (error) {
            _this.spinner.hide();
            _this.toastr.warning('Ha ocurrido un error.');
        });
    };
    CrudUsuariosComponent.prototype.rolesUsuarioAsignar = function (row) {
        this.router.navigate(["/asignar-roles/" + row.rut]);
    };
    CrudUsuariosComponent.prototype.filterList = function ($event, name) {
        var _this = this;
        switch (name) {
            case 'USERNAME': {
                if ($event !== undefined) {
                    this.filtersModel.username = $event.target.value.toString();
                    break;
                }
                else {
                    this.filtersModel.username = '';
                }
            }
            case 'NOMBRE': {
                if ($event !== undefined) {
                    this.filtersModel.nombre = $event.target.value.toString().toLowerCase();
                    break;
                }
                else {
                    this.filtersModel.nombre = '';
                }
            }
            case 'EMAIL': {
                if ($event !== undefined) {
                    this.filtersModel.correo = $event.target.value.toString().toLowerCase();
                    break;
                }
                else {
                    this.filtersModel.correo = '';
                }
            }
        }
        if (this.filtersModel.nombre !== null ||
            this.filtersModel.username !== null ||
            this.filtersModel.email !== null) {
            var temp = this.listaUsuariosTemp.filter(function (d) {
                return (d.nombre.toLowerCase().indexOf(_this.filtersModel.nombre) !== -1)
                    && d.rut.toString().indexOf(_this.filtersModel.username) !== -1
                    && d.email.toLowerCase().indexOf(_this.filtersModel.correo) !== -1;
            });
            this.listaUsuarios = temp;
            if (this.listaUsuarios.length == 0) {
                this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
            }
        }
        else {
            this.listaUsuarios = this.listaUsuariosTemp;
        }
    };
    return CrudUsuariosComponent;
}());
export { CrudUsuariosComponent };
