<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3>Sistemas</h3>
          </div>
          <div class="col-lg-4 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item active"><a>Sistemas</a></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-10 col-lg-10 col-12 mx-auto padding-5">
            <div class="row">
              <div class="col-md-11 col-12 mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Filtros</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xl-4 col-12">
                    <label> ID: </label>
                    <input type="text" class="form-control" (keyup)="filterList($event,'ID')" placeholder="Ingrese"
                           id="nombre" name="nombre">
                  </div><div class="form-group col-xl-8 col-12">
                    <label> Nombre Sistema: </label>
                    <input type="text" class="form-control" (keyup)="filterList($event, 'NOMBRE')" placeholder="Ingrese"
                           id="nombre" name="nombre">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12" style="margin-top: 35px;">
                <a href="javascript:;" class="btn btn-primary float-right" data-toggle="modal"
                   data-target="#refModalPrograma" (click)="showModalCrearSistema(refModalSistema)">
                  Crear Sistema </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-10 col-12 mx-auto">
        <div class="row row-fluid mx-auto">
          <ngx-datatable
            class="bootstrap table table-hover table-striped"
            [rows]="listadoSistemas"
            [columns]="columns"
            [groupExpansionDefault]="false" [rowHeight]="'auto'"
            [sortType]="'multi'" [limit]="10" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [messages]="messages">
          </ngx-datatable>
          <ng-template #optionTemplate let-row="row" let-value="value" let-rowIndex="rowIndex"
                       ngx-datatable-cell-template>
            <div>
              <a href="javascript:;" (click)="showModalEditSistema(refModalSistema, row)">
                <img src="assets/img/editar.png" data-toggle="tooltip" title="Editar"
                     data-placement="bottom">
              </a>
              <a href="javascript:;" (click)="showModalConfirmDeleteSistema(refModalConfirmDeleteSistema, row)">
                <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Eliminar"
                     data-placement="bottom">
              </a> <a href="javascript:;" (click)="sistemaUsuariosAsociar(row)">
              <img src="assets/img/asociar.svg" style="height: 34px; width: 44px" data-toggle="tooltip"
                   title="Asociar usuarios"
                   data-placement="bottom">
            </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #refModalSistema let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form [formGroup]="registerForm">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabelaaaa" *ngIf="!flagModalSistema">Crear Sistema</h5>
        <h5 class="modal-title" id="exampleModalLabelaa" *ngIf="flagModalSistema">Editar Sistema</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div>
          <div class="row">
            <div class="form-group col-xl-12 col-12">
              <label> Nombre Sistema: </label>
              <input type="text" class="form-control" placeholder="Ingrese" formControlName="nombre"
                     id="nombre" name="nombre">
              <div *ngIf="submitted && f.nombre.errors" class="obligatorio-texto">
                <div *ngIf="f.nombre.errors.required">Nombre es requerido</div>
              </div>
              <!-- <div *ngIf="(formProgramaModal.submitted || nombreValModal.touched) && nombreValModal.invalid" class="invalid-feedback">
                 <div *ngIf="nombreValModal.errors.required">Debe ingresar nombre de programa.</div>
               </div>-->
            </div>
            <div class="form-group col-xl-12 col-12">
              <label> Alias del sistema: </label>
              <input type="text" class="form-control" placeholder="Ingrese" formControlName="alias"
                     id="alias" name="alias">
              <div *ngIf="submitted && f.alias.errors" class="obligatorio-texto">
                <div *ngIf="f.alias.errors.required">Alias es requerido</div>
              </div>
              <!-- <div *ngIf="(formProgramaModal.submitted || nombreValModal.touched) && nombreValModal.invalid" class="invalid-feedback">
                 <div *ngIf="nombreValModal.errors.required">Debe ingresar nombre de programa.</div>
               </div>-->
            </div>
            <div class="form-group col-xl-12 col-12">
              <label><span class="obligatorio">*</span>URL Callback: </label>
              <input type="text" class="form-control" placeholder="Ingrese" formControlName="callbackUrl"
                     id="callbackUrl" name="callbackUrl"
                     required>
              <div *ngIf="submitted && f.callbackUrl.errors" class="obligatorio-texto">
                <div *ngIf="f.callbackUrl.errors.required">Url callback es requerido</div>
                <div *ngIf="f.callbackUrl.errors.invalidHttps">La url debe ser HTTPS</div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-12">
              <label><span class="obligatorio">*</span>URL Logout: </label>
              <input type="text" class="form-control" placeholder="Ingrese" formControlName="logoutUrl"
                     id="logoutUrl" name="logoutUrl"
                     required>
              <div *ngIf="submitted && f.logoutUrl.errors" class="obligatorio-texto">
                <div *ngIf="f.logoutUrl.errors.required">Url logoutUrl es requerido</div>
                <div *ngIf="f.logoutUrl.errors.invalidHttps">La url debe ser HTTPS</div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-12">
              <label formArrayName="providers" *ngFor="let order of f.providers.controls; let i = index">
                <input
                  type="checkbox"
                  value="{{providersDisponibles[i].name}}"
                  [formControlName]="i"
                  (click)="selectedProvider($event)"
                > {{providersDisponibles[i].name}}
              </label>
              <div *ngIf="submitted && f.providers.errors" class="obligatorio-texto">
                <div *ngIf="f.providers.errors.required">Debe seleccionar al menos 1</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="saveSistema()">Guardar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
                name="buttonCancelar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalConfirmDeleteSistema let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Eliminar Sistema</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Está seguro de eliminar?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-dark" (click)="eliminarSistema()">Aceptar</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
              name="buttonCancelar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #refModalUsuariosAsociar let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabela">Asociar Usuarios</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-dark" (click)="saveAsociacion()">Guardar</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
                name="buttonCancelar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>
