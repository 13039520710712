/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crud-permisos.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../../shared/global.shared";
import * as i4 from "angular-web-storage";
import * as i5 from "../header/header.component";
import * as i6 from "../../services/usuarios.service";
import * as i7 from "../../services/userpool.service";
import * as i8 from "@angular/router";
import * as i9 from "ngx-toastr";
import * as i10 from "./crud-permisos.component";
var styles_CrudPermisosComponent = [i0.styles];
var RenderType_CrudPermisosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CrudPermisosComponent, data: {} });
export { RenderType_CrudPermisosComponent as RenderType_CrudPermisosComponent };
export function View_CrudPermisosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵprd(512, null, i3.GlobalService, i3.GlobalService, [i4.LocalStorageService]), i1.ɵdid(2, 114688, null, 0, i5.HeaderComponent, [i6.UsuariosService, i7.UserpoolService, i8.Router, i9.ToastrService, i4.LocalStorageService, i3.GlobalService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" crud-permisos works!\n"]))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_CrudPermisosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-crud-permisos", [], null, null, null, View_CrudPermisosComponent_0, RenderType_CrudPermisosComponent)), i1.ɵdid(1, 114688, null, 0, i10.CrudPermisosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CrudPermisosComponentNgFactory = i1.ɵccf("app-crud-permisos", i10.CrudPermisosComponent, View_CrudPermisosComponent_Host_0, {}, {}, []);
export { CrudPermisosComponentNgFactory as CrudPermisosComponentNgFactory };
