<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-6 col-12">
            <h3>Asignar Roles a {{nombreUsuario}}</h3>
            <p class="mt--5">Permite asignar los roles por sistema.</p>
          </div>
          <div class="col-md-6 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/crud-usuarios']">Usuarios</a></li>
                <li class="breadcrumb-item active"><a>Asignar Roles</a></li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label>Sistema</label>
            <ng-select id="sistemas" name="sistemas" [items]="lstSistemas"
                       bindLabel="nombre" bindValue="appClientId" [(ngModel)]="sistemaSeleccionado"
                       (change)="selectSistema($event)">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-12 mx-auto padding-5">
        <div class="row row-fluid mx-auto">

          <ngx-datatable
            *ngIf="sistemaSeleccionado"
            class="bootstrap table table-hover table-striped"
            [rows]="listadoRoles"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="5"
            [selected]="selected"
            [messages]="messages"
            [selectionType]="'checkbox'"
            (select)='onSelect($event)'>
            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                  [resizeable]="false">
              <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                           let-selectFn="selectFn">
                <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                           let-onCheckboxChangeFn="onCheckboxChangeFn">
                <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Nombre Rol" prop="nombre">
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <div class="col-xl-12 padding-5">
        <div class="row">
          <div class="col-xl-12">
            <button class="btn btn-default float-right padding-5" [routerLink]="['/crud-usuarios']">Volver</button>
            <button class="btn btn-primary float-right padding-5" (click)="saveAsignacion()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
