import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

// Util
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import * as cloneDeep from 'lodash/cloneDeep';


//Services
import {LoggedInCallback} from '../../services/cognito.service';
import {SistemasService} from '../../services/sistemas.service';
import {RolesService} from '../../services/roles.service';
import {UserpoolService} from "../../services/userpool.service";
import {LocalStorageService} from "angular-web-storage";
import {UsuariosService} from '../../services/usuarios.service';


//Model
import {SistemasModel} from '../../models/sistemas.model';
import {RolesModel} from "../../models/roles.model";
import {environment} from "../../../environments/environment";
import {UsuariosModel} from "../../models/usuarios.model";

@Component({
  selector: 'app-asignar-roles',
  templateUrl: './asignar-roles.component.html',
  styleUrls: ['./asignar-roles.component.css']
})
export class AsignarRolesComponent implements OnInit, LoggedInCallback {

  lstSistemas: SistemasModel[] = [];
  sistemaSeleccionado: any;
  listadoRoles: RolesModel[] = [];
  listadoRolesActuales: RolesModel[] = [];
  selected = [];
  pathParams: any;
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total',
    selectedMessage: 'Seleccionados'
  };
  nombreUsuario: string;


  constructor(private sistemasServices: SistemasService,
              private rolesService: RolesService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              private userpoolService: UserpoolService,
              private localstorageService: LocalStorageService,
              private usuariosService: UsuariosService) {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      this.validarPermisos();
    }
    else {
      this.route.params.subscribe(params => this.pathParams = params);
      if (isNaN(this.pathParams.rut)) {
        this.router.navigateByUrl('/crud-usuarios');
      } else {
        this.obtenerDatosUsuario();
        this.listarSistemas();
      }
    }
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }

  }

  obtenerDatosUsuario() {
    this.usuariosService.obtenerUsuario(this.pathParams.rut)
      .subscribe((result) => {
        this.nombreUsuario = result.nombre;
      }, error => {
        console.log(error);
      })
  }


  /**
   * Listas los sistemas asociados al usuario
   */
  listarSistemas() {
    this.spinner.show();
    this.sistemasServices.obtenerSistemasPorUsuario(this.pathParams.rut)
      .subscribe((result: SistemasModel[]) => {
        if (result.length > 0)
          this.lstSistemas = result;
        else
          this.toastr.info('El usuario seleccionado no tiene sistemas asociados.');
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  /**
   * Selecciona el sistema
   * @param $event
   */
  selectSistema($event) {
    this.listarRoles(this.sistemaSeleccionado);
  }

  /**
   * Lista los roles asociados al sistema
   * @param appClientId
   */
  listarRoles(appClientId: string) {
    this.spinner.show();
    this.rolesService.listarRoles(appClientId)
      .subscribe((result: RolesModel[]) => {
        this.listadoRoles = result;
        this.spinner.hide();
        this.obtenerRolesUsuario(appClientId);
      }, error => {
        console.log(error);
        this.spinner.hide();
      })

  }

  /**
   * Obtiene los roles asociados al usuarios.
   * @param appClientId
   */
  obtenerRolesUsuario(appClientId) {
    this.selected = [];
    this.spinner.show();
    const array = [];
    this.rolesService.obtenerRolesPorUsuario(this.pathParams.rut, appClientId)
      .subscribe((result: RolesModel[]) => {
        this.listadoRolesActuales = cloneDeep(result);
        result.map((item) => {
          const existe = this.listadoRoles.find((value) => value.idRol === item.idRol);
          if (existe)
            array.push(existe);
        });
        this.selected = array;
        this.spinner.hide();
      }, error => {
        this.listadoRolesActuales = [];
        this.spinner.hide();
        console.log(error);
      })
  }

  /**
   * Selecciona los roles.
   * @param $event
   */
  onSelect($event) {
    this.selected = $event.selected;
  }

  /**
   * Guarda los roles.
   */
  saveAsignacion() {
    this.spinner.show();
    var model = {
      rut: this.pathParams.rut,
      rolesAnteriores: this.listadoRolesActuales,
      rolesNuevos: this.selected
    };
    this.rolesService.guardarRolesPorUsuario(model)
      .subscribe((result) => {
        this.spinner.hide();
        console.log(result);
        this.toastr.success(result);
      }, error => {
        this.spinner.hide();
        this.toastr.warning('Ocurrió un error al guardar roles.');
        console.log(error);
      })


  }
}
