import {HoldingModel} from "./holding.model";
import {EmpresaModel} from "./empresa.model";

export class UsuarioEmpresasModel {
  public rut: number;
  public dv: string;
  public nombre: string;
  public email: string;
  public password: string;
  public holding: HoldingModel;
  public empresas: EmpresaModel[];

  constructor(input?: any) {
    this.rut = input && input.rut || null;
    this.dv = input && input.dv || null;
    this.nombre = input && input.nombre || null;
    this.email = input && input.email || null;
    this.password = input && input.password || null;
    this.holding = input && input.holding || null;
    this.empresas = input && input.empresas || [];
  }


}
