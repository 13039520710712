import { Component, OnInit } from '@angular/core';
import {UsuarioRecuperaModel} from '../../models/usuario-recupera.model';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {UsuariosService} from '../../services/usuarios.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  usuarioReset: UsuarioRecuperaModel;
  urlParams: any;
  reseted:boolean = false;
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private usuariosService: UsuariosService,
    private router: Router
  ) {
    this.usuarioReset = new UsuarioRecuperaModel();
  }

  get hiddenEmail():string{
    const [email,domain] = this.usuarioReset.email.split('@');
    return `${email.slice(0,3)}${'*'.repeat(email.slice(3).length)}@${domain.slice(0,1)}${domain.slice(1).replace(/[^.]/g,'*')}`;
  }

  ngOnInit() {
    this.urlParams = new URLSearchParams(this.router.url
      .replace('/reset-clave?', ''));
  }

  onResetSubmit() {
    this.spinner.show();
    if (!this.usuarioReset.username.includes('-')) {
      const username = this.usuarioReset.username;
      this.usuarioReset.username = `${username.substr(0, username.length - 1)}-${username.substr(username.length - 1).toUpperCase()}`;
    }

    this.usuarioReset.email = this.usuarioReset.email.toLowerCase();
    
    this.usuariosService.resetPass(this.usuarioReset)
    .subscribe(() => {
      this.spinner.hide();
      this.toastr.success('Se ha enviado la recuperación de contraseña');
      this.reseted = true;
      },
      error => {
        console.log('err', error);
        console.log('ResetComponent: error al reiniciarContraseña ');
        this.spinner.hide();
        this.toastr.error(error.error);
      }
      );

  }
}
