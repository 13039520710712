import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from "ngx-toastr";
import {LocalStorageService} from "angular-web-storage";

import {SistemasModel} from "../../models/sistemas.model";


import {SistemasService} from "../../services/sistemas.service";
import {ProvidersService} from "../../services/providers.service";
import {LoggedInCallback} from '../../services/cognito.service';
import {UsuariosService} from '../../services/usuarios.service';
import {UserpoolService} from "../../services/userpool.service";
import {ValidationService} from "../../services/validation.service";

import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-crud-sistemas',
  templateUrl: './crud-sistemas.component.html',
  styleUrls: ['./crud-sistemas.component.css']
})
export class CrudSistemasComponent implements OnInit, LoggedInCallback {
  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<any>;

  listadoSistemas: SistemasModel[];
  listadoSistemasTemp: SistemasModel[];
  objetoSistemas: SistemasModel = new SistemasModel();
  objSistemaDelete: SistemasModel = new SistemasModel();
  messages = {
    emptyMessage: `No hay registros disponibles`,
    totalMessage: 'Total'
  };
  columns = [];
  flagModalSistema = false;
  modalRef: NgbModalRef;
  providersDisponibles = [];
  registerForm: FormGroup;
  submitted = false;

  filtersModel: any = {
    appClienteID: '',
    nombre: ''
  };


  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private sistemasService: SistemasService,
              private providersService: ProvidersService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private router: Router,
              private usuariosService: UsuariosService,
              private userpoolService: UserpoolService,
              private localstorageService: LocalStorageService) {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      this.validarPermisos();
    } else {
      this.columns = [
        {name: 'ID', prop: 'appClientId'},
        {name: 'Nombre Sistema', prop: 'nombre'},
        {name: 'Alias', prop: 'alias'},
        {name: 'CallbackURL', prop: 'callbackUrl'},
        {name: 'LogoutURL', prop: 'logoutUrl'},
        {name: 'Opciones', cellTemplate: this.optionTemplate}
      ];
      this.listSistemas();
      this.listProviders().subscribe((result) => {
        result.map((item) => {
          this.providersDisponibles.push({selected: false, name: item});
        });
        if (!this.providersDisponibles.includes('COGNITO')) {
          this.providersDisponibles.push({selected: false, name: 'COGNITO'});
        }
        const controls = this.providersDisponibles.map(c => new FormControl(false));

        this.registerForm = this.formBuilder.group({
          nombre: ['', Validators.required],
          alias: ['', Validators.required],
          callbackUrl: ['', [Validators.required, ValidationService.httpsValidator]],
          logoutUrl: ['', [Validators.required, ValidationService.httpsValidator]],
          providers: new FormArray(controls, ValidationService.checkValidator)
        });
      }, error => {

      });


    }
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  get f() {
    return this.registerForm.controls;
  }

  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired') {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }

  /**
   * Lista todos los sistemas que se encuentran creados.
   */
  listSistemas() {
    this.spinner.show();
    this.sistemasService.listarSistemas()
      .subscribe((result: SistemasModel[]) => {
        this.listadoSistemas = result;
        this.listadoSistemasTemp = result;
        this.spinner.hide();
      })
  }

  /**
   * Obtiene los providers disponibles
   */
  listProviders() {
    return this.providersService.listarProviders();
    /*  .subscribe((result: any[]) => {
        result.map((item) => {
          this.providersDisponibles.push({selected: false, name: item})
        });
        if (!this.providersDisponibles.includes('COGNITO')) {
          this.providersDisponibles.push({selected: false, name: 'COGNITO'});
        }
      }, error1 => {
        console.log(error1);
      })*/
  }


  /**
   * Muestra el modal para ingresar los datos del nuevo sistema.
   * @param refModal
   */
  showModalCrearSistema(refModal) {
    this.submitted = false;
    this.registerForm.reset();
    this.providersDisponibles.map((item) => {
      item.selected = false;
    });
    this.flagModalSistema = false;
    this.objetoSistemas = new SistemasModel();
    this.modalRef = this.modalService.open(refModal);
  }

  /**
   * Oculta el modal
   */
  hideModal() {
    this.modalRef.close();
  }

  /**
   * Guarda un nuevo sistema
   */
  saveSistema() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.objetoSistemas.nombre = this.registerForm.controls.nombre.value;
    this.objetoSistemas.alias = this.registerForm.controls.alias.value;
    this.objetoSistemas.callbackUrl = this.registerForm.controls.callbackUrl.value;
    this.objetoSistemas.logoutUrl = this.registerForm.controls.logoutUrl.value;

    this.spinner.show();
    if (!this.flagModalSistema) {
      this.sistemasService.crearSistema(this.objetoSistemas)
        .subscribe((result: any) => {
          this.spinner.hide();
          this.modalRef.close();
          this.listSistemas();
          this.toastr.success(result);
        }, error1 => {
          this.spinner.hide();
        });
    } else {
      // tslint:disable-next-line:max-line-length
      this.objetoSistemas.callbackUrl = typeof this.objetoSistemas.callbackUrl === 'string' ? [this.objetoSistemas.callbackUrl] : this.objetoSistemas.callbackUrl;
      // tslint:disable-next-line:max-line-length
      this.objetoSistemas.logoutUrl = typeof this.objetoSistemas.logoutUrl === 'string' ? [this.objetoSistemas.logoutUrl] : this.objetoSistemas.logoutUrl;
      this.sistemasService.editarSistema(this.objetoSistemas)
        .subscribe((result: any) => {
          this.spinner.hide();
          this.modalRef.close();
          this.listSistemas();
          this.toastr.success(result);
        }, error1 => {
          this.spinner.hide();
        });
    }

  }

  eliminarSistema() {
    this.spinner.show();
    this.sistemasService.eliminarSistema(this.objSistemaDelete)
      .subscribe(() => {
        this.modalRef.close();
        this.spinner.hide();
        this.listSistemas();
        this.toastr.success('Sistema eliminado con éxito.');
      }, error => {
        this.spinner.hide();
      });
  }

  showModalConfirmDeleteSistema(refModalConfirmDeleteSistema, row) {
    this.objSistemaDelete = row;
    this.modalRef = this.modalService.open(refModalConfirmDeleteSistema);

  }

  showModalEditSistema(refModalEditSistema, row: SistemasModel) {
    console.log('row', row);
    this.flagModalSistema = true;
    this.submitted = false;
    this.registerForm.controls.nombre.setValue(row.nombre);
    this.registerForm.controls.alias.setValue(row.alias);
    this.registerForm.controls.callbackUrl.setValue(row.callbackUrl[0]);
    this.registerForm.controls.logoutUrl.setValue(row.logoutUrl[0]);
    this.objetoSistemas = row;
    this.objetoSistemas['nombreAnterior'] = row.nombre;
    this.objetoSistemas['aliasAnterior'] = row.alias;
    this.validarProvoderSeleccionados();
    this.modalRef = this.modalService.open(refModalEditSistema);
  }

  /**
   * Selecciona el providers con los que se desea autenti car
   * @param $event
   */
  selectedProvider($event) {
    if (!this.objetoSistemas.supportedIdentityProviders.includes($event.target.value) && $event.target.checked) {
      this.objetoSistemas.supportedIdentityProviders.push($event.target.value)
    } else {
      const indexItem = this.objetoSistemas.supportedIdentityProviders.indexOf($event.target.value);
      this.objetoSistemas.supportedIdentityProviders.splice(indexItem, 1);
    }
  }

  validarProvoderSeleccionados() {
    this.providersDisponibles.map((item, index) => {
      this.registerForm.controls.providers['controls'][index].setValue(this.objetoSistemas.supportedIdentityProviders.includes(item.name));
      item.selected = this.objetoSistemas.supportedIdentityProviders.includes(item.name);
    });
  }

  sistemaUsuariosAsociar(row: SistemasModel) {
    this.router.navigate([`/asociar-usuarios-sistema/${row.appClientId}`])
  }

  filterList($event, name) {

    switch (name) {
      case 'ID': {
        if ($event !== undefined) {
          this.filtersModel.appClienteID = $event.target.value.toString();
          break;
        }
        else {
          this.filtersModel.appClienteID = '';
        }
      }
      case 'NOMBRE': {
        if ($event !== undefined) {
          this.filtersModel.nombre = $event.target.value.toString().toLowerCase();
          break;
        } else {
          this.filtersModel.nombre = '';
        }
      }
    }

    if (this.filtersModel.nombre !== null ||
      this.filtersModel.appClienteID !== null) {

      const temp = this.listadoSistemasTemp.filter(d => {
        return (d.nombre.toLowerCase().indexOf(this.filtersModel.nombre) !== -1)
          &&
          d.appClientId.toLowerCase().indexOf(this.filtersModel.appClienteID) !== -1;
      });

      this.listadoSistemas = temp;
      if (this.listadoSistemas.length == 0) {
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    }
    else {
      this.listadoSistemas = this.listadoSistemasTemp;
    }

  }
}
