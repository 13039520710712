export class EmpresaModel {

  public rut: number;
  public holdRut: number;
  public dv: string;
  public razonSocial: string;
  public nombreFantasia: string;
  public direccion: string;
  public telefono: string;
  public estado: number;
  public giro: string;
  public tipoCliente: number;


  constructor(input?: any) {
    this.rut = input && input.rut || '';
    this.holdRut = input && input.holdRut || '';
    this.dv = input && input.dv || '';
    this.razonSocial = input && input.razonSocial || '';
    this.nombreFantasia = input && input.nombreFantasia || '';
    this.direccion = input && input.direccion || '';
    this.telefono = input && input.telefono || '';
    this.estado = input && input.estado || '';
    this.giro = input && input.giro || '';
    this.tipoCliente = input && input.tipoCliente || '';
  }
}
