import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

import {UsuariosService} from "../../services/usuarios.service";
import {CognitoExceptionsModule} from "../../shared/cognitoExceptions.module";

import {CambioClaveModel} from "../../models/cambio-clave.model";
@Component({
  selector: 'app-activar-usuario',
  templateUrl: './activar-usuario.component.html',
  styleUrls: ['./activar-usuario.component.css']
})
export class ActivarUsuarioComponent implements OnInit {

  datosCambioClave: CambioClaveModel = new CambioClaveModel();
  rutValido: boolean = true;
  rutTmp: any;
  dvTmp: any;
  rutMostrar;

  constructor(private usuariosService: UsuariosService,
              private spinner: NgxSpinnerService,
              private cognitoExceptions: CognitoExceptionsModule,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
  }

  armarRutValidacion(rut){
    let retorno;
    if(rut.length > 7){
        retorno = 'xxxxxxxx'
    } else {
        retorno ='x';
        for(let x = 0; x<rut.length -1; x++){
            retorno = retorno + 'x';
        }
    }
    
    return retorno;
}


  _validarRut(rutCompleto: string): boolean {
    if(rutCompleto != ''){
    this.rutValido = true;
    rutCompleto = rutCompleto.replace('‐', '-');
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto )) {
      this.rutValido = false;
      const rut = rutCompleto.split('-');
      const cabecera = rut[0];
      this.rutMostrar = this.armarRutValidacion(cabecera)
      return false;
    }
    const tmp 	= rutCompleto.split('-');
    let digv	= tmp[1];
    const rut 	= tmp[0];
    if ( digv == 'K' ) {digv = 'k'; }
    var verif = (this._checkDV(rut) == digv );
    if(!verif){
      this.rutValido = false;
      return false;
    } else {
      this.rutValido = true;
      return true;
    }
  } else {
    return true;
  }
  }

  _checkDV(rutPartNumber: any): any {
    let M = 0;
    let S = 1;
    for (; rutPartNumber; rutPartNumber = Math.floor(rutPartNumber / 10)) {
      S = (S + rutPartNumber % 10 * ( 9 - M ++ % 6 )) % 11;
    }
    return S ? S - 1 : 'k';
  }

  /**
   * Metodo invoca el login de usuario con el codigo enviado al correo y la nueva clave ingresada
   * por el usuario.
   */
  onFormSubmit(): void {
    this.spinner.show();
    if (!this.datosCambioClave.username.includes('-')) {
      const username = this.datosCambioClave.username;
      this.datosCambioClave.username = `${username.substr(0, username.length - 1)}-${username.substr(username.length - 1)}`;
    }
    this.usuariosService.activarUsuario(this.datosCambioClave)
      .subscribe((result) => {
        this.spinner.hide();
        this.toastr.success('Usuario activado con éxito');
        this.router.navigate(['/listado-sistemas/' + this.datosCambioClave.username.toUpperCase()]);
      }, error => {
        this.spinner.hide();
        console.log(error);
        //this.toastr.warning(this.cognitoExceptions.getCognitoError(error.error));
        this.toastr.warning('La clave temporal no corresponde al Rut ingresado');
      });
  }

}
