<app-header></app-header>
<div class="container-fluid margin-bottom-30 mt-2">
  <section>
    <div class="fondo-blanco">
      <div class="col-xl-10 mx-auto col-12" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row">
          <div class="col-md-8 col-12">
            <h3>Usuarios</h3>
          </div>
          <div class="col-lg-4 col-12 text-right">
            <div class="text-right float-right" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item active"><a>Usuarios</a></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-10 mx-auto col-12 padding-5">
            <div class="row">
              <div class="col-md-11 col-12 mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Filtros</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xl-4 col-12">
                    <label> Nombre Usuario: </label>
                    <input type="text" class="form-control" (keyup)="filterList($event,'USERNAME')"
                           placeholder="Ingrese"
                           id="nombre" name="nombre">
                  </div>
                  <div class="form-group col-xl-8 col-12">
                    <label> Nombre: </label>
                    <input type="text" class="form-control" (keyup)="filterList($event, 'NOMBRE')" placeholder="Ingrese"
                           id="nombre" name="nombre">
                  </div>
                  <div class="form-group col-xl-8 col-12">
                    <label> Email: </label>
                    <input type="text" class="form-control" (keyup)="filterList($event, 'EMAIL')" placeholder="Ingrese"
                           id="nombre" name="nombre">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12" style="margin-top: 35px;">
                <button class="btn btn-primary float-right  mx-auto" [routerLink]="['/crud-usuario']">
                  Crear Usuario
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-10 col-12 mx-auto">
        <div class="row row-fluid mx-auto">
          <ngx-datatable
            class="bootstrap table table-hover table-striped"
            [rows]="listaUsuarios"
            [columns]="columns"
            [groupExpansionDefault]="false" [rowHeight]="'auto'"
            [sortType]="'multi'" [limit]="10" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [messages]="messages">
          </ngx-datatable>
          <ng-template #rut let-row="row" let-value="value" let-rowIndex="rowIndex"
                       ngx-datatable-cell-template>
            {{row.rut}}-{{row.dv}}
          </ng-template>
          <ng-template #optionTemplate let-row="row" let-value="value" let-rowIndex="rowIndex"
                       ngx-datatable-cell-template>
            <div>
              <a href="javascript:;" (click)="showModalEditUsuario(refModalUsuario, row)">
                <img src="assets/img/editar.png" data-toggle="tooltip" title="Editar"
                     data-placement="bottom">
              </a>
              <a href="javascript:;" (click)="showModalConfirmDeleteUsuario(refModalConfirmDeleteUsuario, row)">
                <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Editar"
                     data-placement="bottom">
              </a>
              <a href="javascript:;" (click)="rolesUsuarioAsignar(row)">
                <img src="assets/img/asociar.svg" style="height: 34px; width: 44px" data-toggle="tooltip"
                     title="Asociar Roles"
                     data-placement="bottom">
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #refModalUsuario let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <form [formGroup]="registerForm" (ngSubmit)="crearUsuarios()">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="!flagModalUsuario">Crear Usuario</h5>
        <h5 class="modal-title" id="exampleModalLabela" *ngIf="flagModalUsuario">Editar Usuario</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body col-xl-12 col-12">
        <div>
          <div class="row">
            <div class="form-group col-xl-12 col-12">
              <label> Rut: </label>
              <input type="text" formControlName="rut" class="form-control" placeholder="Ingrese" id="rutValModal"
                     name="rutValModal" rutvalidator
                     required>
              <div *ngIf="submitted && f.rut.errors">
                <div *ngIf="f.rut.errors.required">Rut es requerido</div>
                <div *ngIf="!f.rut.errors.rutvalidator.valid">El RUT ingresado es incorrecto.</div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-12">
              <label> Nombre: </label>
              <input type="text" formControlName="nombre" class="form-control" placeholder="Ingrese" id="nombreValModal"
                     name="nombreValModal"
                     required>
              <div *ngIf="submitted && f.nombre.errors">
                <div *ngIf="f.nombre.errors.required">Nombre es requerido</div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-12">
              <label><span class="obligatorio">*</span>Email: </label>
              <input type="text" formControlName="email" class="form-control" placeholder="Ingrese" id="email"
                     name="email"
                     required>
              <div *ngIf="submitted && f.email.errors" class="obligatorio-texto">
                <div *ngIf="f.email.errors.required">Email es requerido</div>
                <div *ngIf="f.email.errors.email">Debe contener una dirección de email válida.</div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-12" *ngIf="!flagModalUsuario">
              <label><span class="obligatorio">*</span>Constraseña: </label>
              <input type="password" formControlName="password" class="form-control" placeholder="Ingrese"
                     id="logoutUrl" name="logoutUrl"
                     required>
              <div *ngIf="submitted && f.password.errors">
                <div *ngIf="f.password.errors.required">Contraseña es requerida</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Guardar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelar"
                name="buttonCancelar" (click)="hideModal()">Cancelar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalConfirmDeleteUsuario let-c="close()" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabela">Eliminar Usuario</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      ¿Está seguro de eliminar?
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="eliminarUsuario()">Aceptar</button>
      <button type="button" class="btn btn-default" data-dismiss="modal" #buttonCancelar id="buttonCancelarEliminar"
              name="buttonCancelarEliminar" (click)="hideModal()">Cancelar
      </button>
    </div>
  </div>
</ng-template>
