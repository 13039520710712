import {Injectable} from '@angular/core';


import {Observable} from "rxjs";
import {map} from 'rxjs/operators';


import {HoldingModel} from "../models/holding.model";
import {EmpresaModel} from "../models/empresa.model";


import {environment} from "../../environments/environment";
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(private http: HttpClient) {
  }

  listarClientes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.API_DOMAINS.CLIENTES}/cliente`)
      .pipe(
        map((item: any[]) => {
          return item.map((result: any) => {
            return result;
          })
        })
      )
  }

  crearCliente(model: any): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.CLIENTES}/cliente`, body, {headers: headers});
  }

  editarCliente(model: any): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.CLIENTES}/cliente`, body, {headers: headers});
  }

  crearEmpresa(model: EmpresaModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.CLIENTES}/cliente/empresa`, body, {headers: headers});
  }

  editarEmpresa(model: EmpresaModel): Observable<any> {
    const body = JSON.stringify(model);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.CLIENTES}/cliente/empresa`, body, {headers: headers});
  }

  listarEmpresasHolding(input: any): Observable<EmpresaModel[]> {
    return this.http.get<EmpresaModel[]>(`${environment.API_DOMAINS.CLIENTES}/cliente/empresa/lista/${input}`)
      .pipe(
        map((item: any[]) => {
          return item.map((result: any) => {
            return new EmpresaModel(result);
          })
        })
      )
  }

  obtenerHolding(input: any): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS.CLIENTES}/cliente/holding/${input}`)
      .pipe(
        map((result: HoldingModel) => {
          return result;
        })
      );
  }
  obtenerEmpresa(input: any): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS.CLIENTES}/cliente/empresa/${input}`)
      .pipe(
        map((result: EmpresaModel) => {
          return result;
        })
      );
  }
}
