<section>
    <div class="container-fluid">
        <div class="row margin-top-50">
            <div class="col-xl-4 col-md-8 mx-auto col-12 card">
                <div class="row margin-top-10 text-center mx-auto mb-2">
                    <img style="height: 4rem; width: auto;" src="assets/img/logo-otic-2018.png">
                </div>

                <div class="row">
                    <div class="col-xl-8 mx-auto col-12">
                        <p>Ingrese su RUT y la contraseña temporal que le enviamos a su email para completar el registro:</p>
                    </div>
                </div>

                <form #formLogin="ngForm" (ngSubmit)="onFormSubmit();" novalidate>
                    <div class="row">
                        <div class="col-xl-8 mx-auto col-12">
                            <div class="form-group">
                                <label><span class="obligatorio">*</span>RUT Usuario:</label>
                                <input type="text" mask="0*-A" [dropSpecialCharacters]="false" (keyup)="_validarRut(datosCambioClave.username)" id="rut" name="rut" class="form-control" placeholder="Ingrese RUT" #username="ngModel" rutvalidator [(ngModel)]="datosCambioClave.username"
                                    required>
                                <span *ngIf="username.invalid && (username.dirty || username.touched)" class="form-error">
                                  <span *ngIf="formLogin.hasError('required', 'username')">Debe ingresar un RUT.</span>
                                <span *ngIf="formLogin.hasError('rutvalidator', 'username')">El RUT ingresado es incorrecto.</span>
                                </span>
                                <span style="color:red" class="form-error" *ngIf="!rutValido">Debe ser un Rut válido (Formato {{rutMostrar}}-x)</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8 mx-auto col-12">
                            <div class="form-group">
                                <label><span class="obligatorio">*</span>Contraseña Temporal:</label>
                                <input type="password" id="codigo" name="codigo" class="form-control" placeholder="******" #codigo="ngModel" [(ngModel)]="datosCambioClave.password" required>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8 mx-auto col-12">
                            <p>Su nueva contraseña debe tener 8 caracteres de extensión además de poseer letras mayúsculas, minúsculas y al menos un número.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8 mx-auto col-12">
                            <div class="form-group">
                                <label><span class="obligatorio">*</span>Nueva Contraseña:</label>
                                <input type="password" id="clave" name="clave" class="form-control" placeholder="******" #clave="ngModel" [(ngModel)]="datosCambioClave.newpassword" [pattern]="'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$'" required>
                                <span *ngIf="clave.invalid && (clave.dirty || clave.touched)" class="form-error">
                  <span style="color:red" *ngIf="formLogin.hasError('pattern', 'clave')">La clave no cumple los requisitos m&iacute;nimos.</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8 mx-auto col-12">
                            <div class="form-group">
                                <label><span class="obligatorio">*</span>Confirmar nueva Contraseña:</label>
                                <input type="password" id="confimaClave" name="confimaClave" class="form-control" placeholder="******" #confimaClave="ngModel" [(ngModel)]="datosCambioClave.newpasswordconfirm" required>
                                <span *ngIf="clave.value != '' && confimaClave.value != '' &&  clave.value != confimaClave.value" class="form-error">
                  <span style="color:red">La claves no coinciden.</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row-fluid margin-top-10">
                        <div class="col-xl-12 mx-auto col-12 text-center">
                            <input type="submit" value="Completar registro" class="btn text-center rounded" [disabled]="!formLogin.form.valid || clave.value == '' || confimaClave.value == '' || clave.value != confimaClave.value || !rutValido">
                        </div>
                    </div>

                    <div class="row-fluid margin-top-30 margin-bottom-20">
                        <div class="col-xl-12 mx-auto col-12 text-center">
                            <a href="index.html" class=" text-center">
                                <!-- Solicitar Nueva Contraseña -->
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>