import {Injectable, Directive} from '@angular/core';
import {LocalStorageService} from "angular-web-storage";
import {environment} from "../../environments/environment";

@Injectable()
export class GlobalService {
  private currency: string;
  userData: any = {
    rut: null,
    nombre: null,
    empresa: null,
    rutEmpresa: null,
    roles: [],
    email: null,
    rutHolding: null,
    now: null,
    realUser: null
  };

  constructor(private localstorageService: LocalStorageService) {
  }

  validateRoles(strings: string[]) {
    return this.localstorageService.get(`${environment.CLIENT_ID}.userData`).roles.some(function (v) {
      return strings.indexOf(v.nombre) >= 0;
    });
  }
}
