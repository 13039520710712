<section>
  <div class="container-fluid">
    <div class="row margin-top-50">
      <div class="col-xl-4 col-md-8 mx-auto col-12 card">
        <div class="row margin-top-10 text-center mx-auto mb-2">
          <img style="height: 4rem; width: auto;" src="assets/img/logo-otic-2018.png">
        </div>
        <div class="row margin-top-10 text-center mx-auto mb-2">
          <span class="text-blue-monse-20">Gestor de Capital Humano</span>
        </div>

        <div class="row">
          <div class="col-xl-8 mx-auto col-12">
            <p class="text-16-nunito">Ingrese su RUT y la contraseña temporal que le enviamos a su email para completar el registro:</p>
          </div>
        </div>

        <form #formLogin="ngForm" (ngSubmit)="onFormSubmit();" novalidate>
          <div class="row">
            <div class="col-xl-8 mx-auto col-12">
              <div class="form-group">
                <span class="text-16-nunito">RUT:</span>
                <input type="text" mask="0*-A" [dropSpecialCharacters]="false"  id="rut" name="rut" class="form-control" placeholder="Ej. 11222333-K" #username="ngModel" rutvalidator [(ngModel)]="datosCambioClave.username" required>
                <span class="text-14-nunito">Ingresar sin puntos y con guión.</span>
                <span *ngIf="username.invalid && (username.dirty || username.touched)" class="form-error">
                  <span class="text-14-nunito" *ngIf="formLogin.hasError('required', 'username')">Debe ingresar un RUT.</span>
                  <span class="text-14-nunito" *ngIf="formLogin.hasError('rutvalidator', 'username')">El RUT ingresado es incorrecto.</span>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 mx-auto col-12">
              <div class="form-group">
                <span class="text-16-nunito">Contraseña Temporal:</span>
                <div class="input-group">
                  <input type="{{ verPassTemporal ? 'text' : 'password' }}" id="codigo" name="codigo" class="form-control" placeholder="********" #codigo="ngModel" [(ngModel)]="datosCambioClave.password" required>
                  <span class="input-group-text" (click)="verPassTemporal = !verPassTemporal">
                    <img src="assets/img/ojo.svg" alt="" *ngIf="!verPassTemporal">
                    <img src="assets/img/ojo-no.svg" alt="" *ngIf="verPassTemporal">
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 mx-auto col-12">
              <p class="text-16-nunito">Su nueva contraseña debe tener 8 caracteres de extensión, además de poseer letras mayúsculas, minúsculas y al menos un número.</p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 mx-auto col-12">
              <div class="form-group">
                <span class="text-16-nunito">Nueva Contraseña:</span>
                <div class="input-group">
                  <input type="{{ verPassNueva ? 'text' : 'password' }}" id="clave" name="clave" class="form-control" placeholder="********" #clave="ngModel" [(ngModel)]="datosCambioClave.newpassword" [pattern]="'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$'" required>
                  <span class="input-group-text" (click)="verPassNueva = !verPassNueva">
                    <img src="assets/img/ojo.svg" alt="" *ngIf="!verPassNueva">
                    <img src="assets/img/ojo-no.svg" alt="" *ngIf="verPassNueva">
                  </span>
                </div>
                <span *ngIf="clave.invalid && (clave.dirty || clave.touched)" class="form-error">
                  <span class="text-14-nunito" *ngIf="formLogin.hasError('pattern', 'clave')">La clave no cumple los requisitos m&iacute;nimos.</span>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 mx-auto col-12">
              <div class="form-group">
                <span class="text-16-nunito">Confirmar nueva Contraseña:</span>
                <div class="input-group">
                  <input type="{{ verPassNuevaConf ? 'text' : 'password' }}" id="confimaClave" name="confimaClave" class="form-control" placeholder="********" #confimaClave="ngModel" [(ngModel)]="datosCambioClave.newpasswordconfirm" required>
                  <span class="input-group-text" (click)="verPassNuevaConf = !verPassNuevaConf">
                    <img src="assets/img/ojo.svg" alt="" *ngIf="!verPassNuevaConf">
                    <img src="assets/img/ojo-no.svg" alt="" *ngIf="verPassNuevaConf">
                  </span>
                </div>
                <span *ngIf="clave.value != '' && confimaClave.value != '' &&  clave.value != confimaClave.value" class="form-error">
                  <span class="text-14-nunito">La claves no coinciden.</span>
                </span>
              </div>
            </div>
          </div>

          <div class="row-fluid margin-top-10">
            <div class="col-xl-12 mx-auto col-12 text-center">
              <input type="submit" value="Aceptar" class="btn text-center rounded btn-acept" [disabled]="!formLogin.form.valid || clave.value == '' || confimaClave.value == '' || clave.value != confimaClave.value">
            </div>
          </div>

          <div class="row-fluid margin-top-30 margin-bottom-20">
            <div class="col-xl-12 mx-auto col-12 text-center">
              <a href="index.html" class=" text-center">
                <!-- Solicitar Nueva Contraseña -->
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
