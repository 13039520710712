// Angular
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {Router} from "@angular/router";
//Util
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {forkJoin} from "rxjs";
import {mergeMap, switchMap, tap} from "rxjs/operators";
//Services
import {ProvidersService} from "../../services/providers.service";
import {UsuariosService} from "../../services/usuarios.service";
import {UserpoolService} from "../../services/userpool.service";
import {LocalStorageService} from "angular-web-storage";
import {LoggedInCallback} from '../../services/cognito.service';
// Models
import {ProviderModel} from "../../models/provider.model";
// Components
import {CrudAttributesProviderListComponent} from "../crud-attributes-provider-list/crud-attributes-provider-list.component";

import {environment} from "../../../environments/environment";
import {Validators} from "@angular/forms";


@Component({
  selector: 'app-crud-providers',
  templateUrl: './crud-providers.component.html',
  styleUrls: ['./crud-providers.component.css']
})
export class CrudProvidersComponent implements OnInit, LoggedInCallback {
  @ViewChild(CrudAttributesProviderListComponent, { static: true }) crudAttributesProviderListComponent: CrudAttributesProviderListComponent;

  googleModel: ProviderModel = new ProviderModel();
  facebookModel: ProviderModel = new ProviderModel();

  constructor(
    private providersService: ProvidersService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private usuariosService: UsuariosService,
    private userpoolService: UserpoolService,
    private localstorageService: LocalStorageService
  ) {
  }

  ngOnInit() {
    this.usuariosService.isAuthenticated(this, null);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      this.validarPermisos();
    }
  }


  validarPermisos() {
    console.log(this.localstorageService.get('accessToken'));
    const access_token = this.localstorageService.get('accessToken');
    const id_token = this.localstorageService.get('idToken');
    if (access_token !== null && id_token !== null) {
      this.userpoolService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
        }, error1 => {
          if (error1.error === 'token denied') {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          } else if (error1.error === 'Token is expired' ) {
            this.toastr.info('La sessión ha caducado.');
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
              }
            });
          }
        });
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect_uri: environment.URL_AUTH,
          response_type: 'token',
          client_id: environment.CLIENT_ID
        }
      });
    }
  }

  guardarProvider(nombreProvider) {
    const subscribe = [];
    this.spinner.show();
    if (nombreProvider === 'Google') {
      this.googleModel.providerName = nombreProvider;
      this.googleModel.providerType = nombreProvider;
      subscribe.push(this.providersService.guardarProvider(this.googleModel));
    } else if (nombreProvider === 'Facebook') {
      this.facebookModel.providerName = nombreProvider;
      this.facebookModel.providerType = nombreProvider;
      subscribe.push(this.providersService.guardarProvider(this.facebookModel));
    }

    forkJoin(subscribe)
      .pipe(
        tap((data: any[]) => this.toastr.success(data[0])),
        switchMap(() => this.providersService.obtenerProvider(nombreProvider))
      ).subscribe((result: ProviderModel) => {
      if (nombreProvider === 'Google') {
        this.googleModel = result;
      } else if (nombreProvider === 'Facebook') {
        this.facebookModel = result;
      }
      this.spinner.hide();
    }, error1 => {
      this.spinner.hide();
      console.log(error1);
      this.toastr.warning(error1.error);
    });
  }

  beforeChange($event: NgbPanelChangeEvent) {
    if ($event.nextState) {
      this.spinner.show();
      this.providersService.obtenerProvider($event.panelId)
        .subscribe((result: ProviderModel) => {
          this.crudAttributesProviderListComponent.listarAtributosProviders($event.panelId);
          if ($event.panelId === 'Google') {
            this.googleModel = result;
            this.crudAttributesProviderListComponent.array = this.googleModel.attributeMapping;
          } else if ($event.panelId === 'Facebook') {
            this.facebookModel = result;
            this.crudAttributesProviderListComponent.array = this.facebookModel.attributeMapping;
          } else {

          }
          this.spinner.hide();
        }, error1 => {
          console.log(error1.error);
          this.spinner.hide();
        });
    }
  }

  actualizarAtributos($event, providerName) {
    if (providerName === 'Google') {
      this.googleModel.attributeMapping = $event;
    } else if (providerName === 'Facebook') {
      this.facebookModel.attributeMapping = $event;
    }
  }
}
