import { HttpClient } from "@angular/common/http";
import { CognitoUtilService, LoggedInCallback } from './cognito.service';
import { UsuariosModel } from "../models/usuarios.model";
import { UsuarioEmpresasModel } from "../models/usuario-empresas.model";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { EmpresaModel } from "../models/empresa.model";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./cognito.service";
import * as i3 from "@angular/router";
var UsuariosService = /** @class */ (function () {
    function UsuariosService(http, cognitoUtil, router) {
        this.http = http;
        this.cognitoUtil = cognitoUtil;
        this.router = router;
    }
    UsuariosService.prototype.listarUsuarios = function () {
        return this.http.get(environment.API_DOMAINS.USUARIOS + "/usuario")
            .pipe(map(function (result) {
            return result.map(function (item) {
                return new UsuariosModel(item);
            });
        }));
    };
    UsuariosService.prototype.crearUsuarios = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USUARIOS + "/usuario", body, { headers: headers });
    };
    UsuariosService.prototype.editarUsuarios = function (model) {
        var body = JSON.stringify(model);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.put(environment.API_DOMAINS.USUARIOS + "/usuario", body, { headers: headers });
    };
    UsuariosService.prototype.eliminarSistema = function (input) {
        return this.http.delete(environment.API_DOMAINS.USUARIOS + "/usuario/" + input.rut + "-" + input.dv);
    };
    UsuariosService.prototype.obtenerUsuario = function (usename) {
        return this.http.get(environment.API_DOMAINS.USUARIOS + "/usuario/" + usename)
            .pipe(map(function (result) {
            var empresas = [];
            result.empresas.map(function (item) {
                empresas.push(new EmpresaModel(item));
            });
            console.log(empresas);
            result.empresas = empresas;
            return new UsuarioEmpresasModel(result);
        }));
    };
    UsuariosService.prototype.obtenerUsuariosSistemas = function (appClientId) {
        return this.http.get(environment.API_DOMAINS.USUARIOS + "/usuario/sistema/" + appClientId)
            .pipe(map(function (result) {
            return result.map(function (item) {
                return new UsuariosModel(item);
            });
        }));
    };
    UsuariosService.prototype.loginUsuario = function (userData) {
        var body = JSON.stringify(userData);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USUARIOS + "/login", body, { headers: headers });
    };
    UsuariosService.prototype.resetPass = function (userData) {
        var body = JSON.stringify(userData);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USRPAS + "/rest", body, { headers: headers });
    };
    UsuariosService.prototype.activarUsuario = function (userData) {
        var body = JSON.stringify(userData);
        console.log(body);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USUARIOS + "/activar", body, { headers: headers });
    };
    UsuariosService.prototype.activarChangePassUsuario = function (userData) {
        var body = JSON.stringify(userData);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.USRPAS + "/activarpassrecovery", body, { headers: headers });
    };
    UsuariosService.prototype.logoutUsuario = function (clientId) {
        this.cognitoUtil.agregarAppClient(clientId);
        var cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser !== null)
            cognitoUser.signOut();
    };
    UsuariosService.prototype.isAuthenticated = function (callback, clientId) {
        // const thisLocalStorage = this.localStorageService;
        if (callback == null)
            throw ('UserLoginService: Callback en isAuthenticated() no puede ser null');
        this.cognitoUtil.agregarAppClient(clientId);
        var cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log('UserLoginService: No se pudo recuperar la sesion: ' + err, err.stack);
                    callback.isLoggedIn(err, false);
                }
                else {
                    callback.isLoggedIn(err, session.isValid());
                    /*cognitoUser.refreshSession(session.getRefreshToken(), function (err, data) {
                      if (err) {
                        console.log('UserLoginService: Resultado de validacion de sesion es: ' + false);
                        callback.isLoggedIn(err, false);
                      } else {
                        thisLocalStorage.set('idToken', session.getIdToken().getJwtToken());
                        console.log('UserLoginService: Resultado de validacion de sesion es: ' + session.isValid());
                        callback.isLoggedIn(err, session.isValid());
                      }
                    });*/
                }
            });
        }
        else {
            console.log('UserLoginService: no se pudo recuperar el CurrentUser');
            callback.isLoggedIn('No se pudo recuperar el CurrentUser', false);
        }
    };
    UsuariosService.prototype.logout = function () {
        console.log('UsuarioService: Cerrando sesion');
        this.cognitoUtil.getCurrentUser().signOut();
        this.router.navigate(['/login'], {
            queryParams: {
                redirect_uri: environment.URL_AUTH,
                response_type: 'token',
                client_id: environment.CLIENT_ID
            }
        });
    };
    UsuariosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsuariosService_Factory() { return new UsuariosService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CognitoUtilService), i0.ɵɵinject(i3.Router)); }, token: UsuariosService, providedIn: "root" });
    return UsuariosService;
}());
export { UsuariosService };
