import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProvidersService = /** @class */ (function () {
    function ProvidersService(http) {
        this.http = http;
    }
    ProvidersService.prototype.obtenerProvider = function (providerName) {
        var headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return this.http.get(environment.API_DOMAINS.PROVIDERS + "/provider/" + providerName, { headers: headers });
    };
    ProvidersService.prototype.guardarProvider = function (input) {
        var body = JSON.stringify(input);
        var headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(environment.API_DOMAINS.PROVIDERS + "/provider", body, { headers: headers });
    };
    ProvidersService.prototype.listarProviders = function () {
        return this.http
            .get(environment.API_DOMAINS.PROVIDERS + "/provider")
            .pipe(map(function (result) {
            return result;
        }));
    };
    ProvidersService.prototype.listarProvidersAtributos = function (providerName) {
        return this.http
            .get(environment.API_DOMAINS.PROVIDERS + "/provider/atributos/" + providerName)
            .pipe(map(function (result) {
            return result;
        }));
    };
    ProvidersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProvidersService_Factory() { return new ProvidersService(i0.ɵɵinject(i1.HttpClient)); }, token: ProvidersService, providedIn: "root" });
    return ProvidersService;
}());
export { ProvidersService };
